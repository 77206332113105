import React, { useCallback, useState } from "react";
import { Button } from "react-admin";
import ExtendParkingTimeDialog from "./ExtendParkingTimeDialog";
import useParkingSettings from "../../../../hooks/useParkingSettings";

const ExtendParkingTime = () => {
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

  const { settings } = useParkingSettings();

  const close = useCallback(() => {
    setIsDialogVisible(false);
  }, []);

  if (!settings.tickets.management.allowedForManagers) {
    return <></>;
  }

  if (!settings.tickets.management.allowedActions.includes("extend")) {
    return <></>;
  }

  return (
    <div>
      <Button
        variant="outlined"
        label="app.parking.extend_time"
        onClick={() => {
          setIsDialogVisible(true);
        }}
      />
      <ExtendParkingTimeDialog
        close={close}
        isDialogVisible={isDialogVisible}
      />
    </div>
  );
};

export default ExtendParkingTime;
