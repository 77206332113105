import React, { useState } from "react";
import { Box, ButtonBase, InputProps } from "@mui/material";
import { FormTextInput } from "../../themes/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const PasswordInput = (props: InputProps) => {
  const [isSecure, setIsSecure] = useState<boolean>(true);

  const toggleSecure = () => {
    setIsSecure(!isSecure);
  };

  return (
    <Box style={{ position: "relative" }}>
      <FormTextInput
        id="password"
        name="password"
        type={isSecure ? "password" : "text"}
        {...props}
        fullWidth={true}
      />
      <ButtonBase
        onClick={toggleSecure}
        style={{ position: "absolute", top: "13px", right: "10px" }}
      >
        {isSecure ? (
          <VisibilityOffIcon color="disabled" />
        ) : (
          <VisibilityIcon color="disabled" />
        )}
      </ButtonBase>
    </Box>
  );
};

export default PasswordInput;
