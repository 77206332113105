import React from "react";
import { Box } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useTranslate } from "react-admin";
import ParkingPrepaidPageTemplate from "../components/ParkingPrepaidPageTemplate";
import { styled } from "@mui/material/styles";

const Container = styled(Box)(() => ({
  marginBottom: "40px",
  textAlign: "center",
}));

const ErrorPage = () => {
  const translate = useTranslate();

  const { orgId } = useParams();

  return (
    <ParkingPrepaidPageTemplate>
      <Container>
        <Box data-testid="errorMessage">
          {translate("app.parking.prepaid.page_error_message")}
        </Box>
        <Box>
          <Link to={`/parking/prepaid/${orgId}/steps/1`}>
            {translate("app.parking.prepaid.page_error_link")}
          </Link>
        </Box>
      </Container>
    </ParkingPrepaidPageTemplate>
  );
};

export default ErrorPage;
