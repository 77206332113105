export class ConfigManager {
  private static instance: ConfigManager;

  private API_URL: string | undefined;
  private ADMIN_API_URL: string | undefined;
  private GROUP_ID: string | undefined;
  private STRIPE_KEY: string | undefined;
  private PARKING_NAME: string | undefined;

  public static getInstance(): ConfigManager {
    if (!ConfigManager.instance) {
      ConfigManager.instance = new ConfigManager();
    }

    return ConfigManager.instance;
  }

  public getApiUrl(): string | undefined {
    return this.API_URL;
  }

  public getApiAdminUrl(): string | undefined {
    return this.ADMIN_API_URL;
  }

  public getOrgId(): string | null {
    return localStorage.getItem("orgId");
  }

  public setApiUrl(apiUrl: string) {
    this.API_URL = apiUrl;
  }

  public setApiAdminUrl(adminUrl: string) {
    this.ADMIN_API_URL = adminUrl;
  }

  public setGroupId(groupId: string | undefined) {
    this.GROUP_ID = groupId;
  }

  public setStripeKey(key: string) {
    this.STRIPE_KEY = key;
  }

  public getStripeKey() {
    return this.STRIPE_KEY;
  }

  public getParkingName() {
    return this.PARKING_NAME;
  }

  public setParkingName(name: string) {
    this.PARKING_NAME = name;
  }
}
