import { styled } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import { CustomButton, CustomLightButton } from "../../../themes/styles";

export const PageContainer = styled(Container)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  position: "relative",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    justifyContent: "start",
    paddingTop: "100px",
  },
}));

export const TitleSection = styled(Box)(({ theme }) => ({
  marginBottom: "20px",
  textAlign: "center",

  [theme.breakpoints.down("md")]: {
    maxWidth: "220px",
    marginBottom: "40px",
  },
}));

export const MobileButton = styled(CustomButton)(() => ({
  width: "100%",
  maxWidth: "368px",
  fontWeight: "bold",
}));

export const MobileLightButton = styled(CustomLightButton)(() => ({
  width: "100%",
  maxWidth: "368px",
  fontWeight: "bold",
  border: "2px solid black",
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  maxWidth: "630px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    padding: "20px 10px",
  },
}));

export const StepInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: "13px",
  width: "368px",
  margin: "50px 0",

  [theme.breakpoints.down("md")]: {
    margin: "10px 0",
    maxWidth: "368px",
  },
}));
