import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CustomButton } from "../../../themes/styles";
import { ParkingContext } from "../../../services/parking/parkingContext";
import { MobileLightButton, StepInnerContainer, TitleSection } from "./styles";
import { useTranslate } from "react-admin";
import ParkingStopwatch from "../components/ParkingStopwatch";
import { PaymentProcessStep } from "../../../types/enums/payment-process-step.enum";
import { ParkingPublicService } from "../../../api/ParkingPublicService";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import { IParkingTicket } from "../../../types/interfaces/parking/parking-ticket.interface";
import moment from "moment";
import { convertMillisecondsToTime } from "../../../utils/timeService";
import useOnPageLoad from "../../../hooks/useOnPageLoad";
import { ITicketPrice } from "../../../types/interfaces/parking/ticket-price.interface";
import useBeforeUnload from "../../../hooks/useBeforeUnload";

const StepTwo = () => {
  const navigate = useNavigate();

  useOnPageLoad();
  useBeforeUnload();

  const {
    plateNumber,
    setStopwatchHours,
    setStopwatchSeconds,
    setStopwatchMinutes,
    saveParkingHours,
    saveCurrentStep,
    saveParkingMinutes,
    setParkingProcess,
    organizationInfo,
  } = useContext(ParkingContext);

  const { orgId } = useParams();

  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showStopwatch, setShowStopwatch] = useState<boolean>(false);
  const [isFreeParkingAvailable, setIsFreeParkingAvailable] =
    useState<boolean>(true);

  const assignValuesToStopwatch = (time: number) => {
    const currentDate = moment();
    const validFromDate = moment(time);
    const differentInMilliseconds = currentDate.diff(validFromDate);
    const convertedTime = convertMillisecondsToTime(differentInMilliseconds);
    setStopwatchSeconds(() => convertedTime.seconds);
    setStopwatchMinutes(() => convertedTime.minutes);
    setStopwatchHours(() => convertedTime.hours);
  };

  const freeHours = useMemo(() => {
    if (!organizationInfo) return 3;
    switch (organizationInfo.name) {
      case "zaspa":
        return 1.5;
      default:
        return 3;
    }
  }, [organizationInfo]);

  const checkIfFreeParkingIsAvailable = async () => {
    try {
      const { data } = await ParkingPublicService.getParkingPriceForPlateNumber<
        IBasicResponse<ITicketPrice>
      >(
        {
          plateNumber,
          parkingTime: freeHours * 60,
        },
        orgId as string,
      );
      if (data.amount !== 0) {
        setIsFreeParkingAvailable(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setUpStopwatchAndCheckFreeParkingAvailability = async () => {
    try {
      setIsLoading(true);

      await checkIfFreeParkingIsAvailable();

      const activeTicket = await fetchActiveTicket();
      if (!activeTicket) {
        setIsLoading(false);
        return;
      }

      // If user has active and paid ticket
      if (activeTicket.validTo) {
        setShowStopwatch(true);
        setIsFreeParkingAvailable(false);
      }
      assignValuesToStopwatch(activeTicket.validFrom);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const fetchActiveTicket = async () => {
    try {
      const { data } =
        await ParkingPublicService.getParkingTicketsForPlateNumber<
          IBasicResponse<IParkingTicket[]>
        >(plateNumber, orgId as string);

      return data.find(
        (item) =>
          item.status === "active" && item.validFrom < new Date().getTime(),
      );
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    setUpStopwatchAndCheckFreeParkingAvailability();
  }, []);

  const handleFreeTimeParkingTicket = async () => {
    const hours = Math.floor(freeHours);
    const minutes = (freeHours - hours) * 60;

    saveParkingHours(hours);
    saveParkingMinutes(minutes);
    setParkingProcess(true);
    saveCurrentStep(PaymentProcessStep.STEP_FORM);
    navigate(`/parking/prepaid/${orgId}/steps/step-form`);
    return "";
  };

  const handleCustomTimeParkingTicket = async () => {
    saveCurrentStep(PaymentProcessStep.STEP_THIRD);
    setParkingProcess(false);
    navigate(`/parking/prepaid/${orgId}/steps/3`);
    return "";
  };

  return (
    <>
      <TitleSection>
        <Box data-testid="prepaidPageStepTwoTitle">
          {translate("app.parking.prepaid.second_step_title", {
            hours: freeHours,
          })}
        </Box>
      </TitleSection>
      <Box style={{ textAlign: "center" }}>
        <Box>{translate("app.parking.prepaid.your_plate_number")}:</Box>
        <Box style={{ fontWeight: "bold", fontSize: "26px" }}>
          {plateNumber}
        </Box>
      </Box>

      <Box style={{ textAlign: "center", marginTop: "30px" }}>
        {showStopwatch &&
          (isLoading ? (
            <CircularProgress size={40} />
          ) : (
            <>
              <Box>{translate("app.parking.prepaid.your_stopover_time")}:</Box>
              <Box>
                <ParkingStopwatch />
              </Box>
            </>
          ))}
      </Box>
      <StepInnerContainer>
        <Box>{translate("app.parking.prepaid.second_step_question")}</Box>
        <Box>
          {freeHours &&
            translate("app.parking.prepaid.second_step_prolongate_message", {
              hours: freeHours,
            })}
        </Box>
        <CustomButton
          style={{ fontWeight: "bold" }}
          aria-label="standardTimeButton"
          disabled={!isFreeParkingAvailable}
          onClick={() => handleFreeTimeParkingTicket()}
        >
          {freeHours &&
            translate("app.parking.prepaid.standard_time_title", {
              hours: freeHours,
            })}
        </CustomButton>

        <MobileLightButton
          aria-label="specificTimeButton"
          onClick={() => handleCustomTimeParkingTicket()}
        >
          {translate("app.parking.prepaid.more_time_title")}
        </MobileLightButton>
      </StepInnerContainer>
    </>
  );
};

export default StepTwo;
