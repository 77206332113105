import ReservationsList from "./ReservationsList";
import ReservationsShow from "./ReservationsShow";
import ReservationsEdit from "./ReservationsEdit";
import ReservationsCreate from "./ReservationsCreate";

const reservationsResources = {
  list: ReservationsList,
  show: ReservationsShow,
  edit: ReservationsEdit,
  create: ReservationsCreate,
};

export default reservationsResources;
