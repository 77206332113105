import React from "react";
import {
  Create,
  ListToolbarProps,
  required,
  SimpleForm,
  TranslatableInputs,
  useAuthenticated,
} from "react-admin";
import {
  StyledTextInput,
  StyledColorInput,
  StyledSaveButton,
} from "../../../themes/styles";
import { Toolbar } from "@mui/material";
import { toSnakeCase } from "../../../utils/textService";
import { TicketStatusType } from "../../../types/enums/ticket-status-type.enums";

type IStatusFormData = Record<string, any>;

const StatusesCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const StatusesCreate = () => {
  useAuthenticated();

  const transform = async (data: IStatusFormData) => {
    data.codeName = toSnakeCase(data.name.en);
    data.active = TicketStatusType.ACTIVE;

    return data;
  };

  return (
    <Create redirect="list" transform={transform}>
      <SimpleForm toolbar={<StatusesCreateFormToolbar />}>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="name"
            label="app.auth.title"
            validate={required()}
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>
        <StyledColorInput
          variant="outlined"
          source="color"
          label="app.helpdesk.color"
          validate={required()}
          sx={{ width: "200px" }}
        />
      </SimpleForm>
    </Create>
  );
};

export default StatusesCreate;
