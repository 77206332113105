import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MobileButton, MobileLightButton, TitleSection } from "./styles";
import { Box, FormControlLabel, Theme, useMediaQuery } from "@mui/material";
import localeMoment from "moment/min/moment-with-locales";
import { resolveBrowserLocale, useTranslate } from "react-admin";
import NumberButton from "../components/NumberButton";
import { styled } from "@mui/material/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ParkingContext } from "../../../services/parking/parkingContext";
import ErrorMessageContainer, {
  TextInputTypes,
} from "../../../components/errors/ErrorContainer";
import useOnPageLoad from "../../../hooks/useOnPageLoad";
import { PaymentProcessStep } from "../../../types/enums/payment-process-step.enum";
import useBeforeUnload from "../../../hooks/useBeforeUnload";
import { ParkingPublicService } from "../../../api/ParkingPublicService";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import { ParkingPrice } from "../../../types/interfaces/parking/parking-price.interface";
import Checkbox from "@mui/material/Checkbox";

const ButtonsContainer = styled(Box)(() => ({
  display: "flex",
  gap: "28px",
  width: "100%",
  justifyContent: "center",
}));

const ButtonContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "368px",
  margin: "40px 0",
  gap: "20px",
}));

const TextSection = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  justifyContent: "center",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "unset",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  marginTop: "40px",
  textAlign: "center",

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

const Text = styled(Box)(() => ({
  fontSize: "16px",
  fontWeight: "bold",
}));

const PageLink = styled(Link)(() => ({
  color: "#0A0A0A",
}));

const CheckboxContainer = styled(Box)(() => ({
  marginTop: "30px",
}));

const CheckboxLabel = styled(Box)(() => ({
  fontSize: "14px",
}));

const StepTimePicker = () => {
  const translate = useTranslate();
  const navigate = useNavigate();

  useOnPageLoad();
  useBeforeUnload();

  const { orgId } = useParams();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md"),
  );

  const {
    parkingMinutes,
    saveCurrentStep,
    parkingHours,
    saveParkingHours,
    saveParkingMinutes,
    activeTicketValidToDate,
    parkingTimeInMinutes,
    plateNumber,
    acceptance,
    saveAcceptance,
  } = useContext(ParkingContext);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [parkingPrice, setParkingPrice] = useState<number>(0);
  const [currency, setCurrency] = useState<string>("");

  useEffect(() => {
    fetchTicketPrice();
  }, [parkingTimeInMinutes]);

  const confirmTime = async () => {
    setErrorMessage("");
    if (parkingMinutes === 0 && parkingHours === 0) {
      setErrorMessage(translate("app.parking.errors.time_cannot_be_zero"));
      return;
    }

    saveCurrentStep(PaymentProcessStep.STEP_FORM);
    navigate(`/parking/prepaid/${orgId}/steps/step-form`);
  };

  const totalMillisecondsToAdd = useMemo(() => {
    return parkingHours * 60 * 60 * 1000 + parkingMinutes * 60 * 1000;
  }, [parkingHours, parkingMinutes]);

  const fetchTicketPrice = async () => {
    try {
      const { data } = await ParkingPublicService.getParkingPriceForPlateNumber<
        IBasicResponse<ParkingPrice>
      >(
        {
          plateNumber,
          parkingTime: parkingTimeInMinutes,
        },
        orgId as string,
      );
      setCurrency(data.currency);
      setParkingPrice(data.amount);
    } catch (e) {
      console.log(e);
    }
  };

  const paidTime = useMemo(() => {
    if (activeTicketValidToDate) {
      return new Date(activeTicketValidToDate).setMilliseconds(
        totalMillisecondsToAdd,
      );
    }

    return new Date().setMilliseconds(totalMillisecondsToAdd);
  }, [totalMillisecondsToAdd, activeTicketValidToDate]);

  const increaseMinutes = useCallback(() => {
    if (parkingMinutes === 50) return;
    saveParkingMinutes(parkingMinutes + 10);
  }, [saveParkingMinutes, parkingMinutes]);

  const decreaseMinutes = useCallback(() => {
    if (parkingMinutes === 0) return;
    saveParkingMinutes(parkingMinutes - 10);
  }, [saveParkingMinutes, parkingMinutes]);

  const increaseHours = useCallback(() => {
    saveParkingHours(parkingHours + 1);
  }, [parkingHours, saveParkingHours]);

  const decreaseHours = useCallback(() => {
    if (parkingHours === 0) return;
    saveParkingHours(parkingHours - 1);
  }, [parkingHours, saveParkingHours]);

  const formattedMinutes = useMemo(() => {
    return parkingMinutes < 10 ? `0${parkingMinutes}` : parkingMinutes;
  }, [parkingMinutes]);

  const formattedHours = useMemo(() => {
    return parkingHours < 10 ? `0${parkingHours}` : parkingHours;
  }, [parkingHours]);

  const stepBack = () => {
    saveCurrentStep(PaymentProcessStep.STEP_SECOND);
    navigate(-1);
    return;
  };

  const formattedPrice = useMemo(() => {
    return `${parkingPrice / 100} ${currency}`;
  }, [parkingPrice, currency]);

  return (
    <>
      <TitleSection data-testid="prepaidPageStepTimePickerTitle">
        {translate("app.parking.prepaid.third_step_title")}
      </TitleSection>
      <ButtonsContainer>
        <NumberButton
          increase={increaseHours}
          decrease={decreaseHours}
          value={formattedHours}
          title={translate("app.parking.prepaid.hours")}
        />
        <NumberButton
          increase={increaseMinutes}
          decrease={decreaseMinutes}
          value={formattedMinutes}
          title={translate("app.parking.prepaid.minutes")}
        />
      </ButtonsContainer>
      <TextContainer>
        <TextSection>
          <Box>{translate("app.parking.prepaid.pay_parking_until")}:</Box>
          <Text>
            {localeMoment(paidTime)
              .locale(resolveBrowserLocale())
              .format("LLL")}
          </Text>
        </TextSection>
        <TextSection>
          <Box>{translate("app.parking.prepaid.price")}:</Box>
          <Text>{formattedPrice}</Text>
        </TextSection>
      </TextContainer>

      <CheckboxContainer>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptance}
              size="small"
              onChange={(_, checked) => saveAcceptance(checked)}
            />
          }
          label={
            <CheckboxLabel>
              {translate("app.parking.prepaid.accept")}{" "}
              <PageLink
                to={`/parking/prepaid/${orgId}/page/cennik`}
                target="_blank"
              >
                {translate(
                  "app.parking.prepaid.accept_text_parking_price_capitalize",
                )}
              </PageLink>
              ,{" "}
              <PageLink
                to={`/parking/prepaid/${orgId}/page/regulamin`}
                target="_blank"
              >
                {translate(
                  "app.parking.prepaid.accept_text_parking_regulations_capitalize",
                )}
              </PageLink>{" "}
              {translate("app.parking.prepaid.accept_text_and")}{" "}
              <PageLink
                to={`/parking/prepaid/${orgId}/page/polityka-prywatnosci`}
                target="_blank"
              >
                {translate(
                  "app.parking.prepaid.accept_text_privacy_policy_capitalize",
                )}
              </PageLink>
            </CheckboxLabel>
          }
        />
      </CheckboxContainer>

      <Box>
        <ErrorMessageContainer variant={TextInputTypes.API_ERROR}>
          {errorMessage}
        </ErrorMessageContainer>
      </Box>

      <ButtonContainer>
        <MobileButton
          aria-label="submitTimeStep"
          onClick={() => confirmTime()}
          disabled={!acceptance}
        >
          {translate(
            isMobile
              ? "app.parking.prepaid.confirm"
              : "app.parking.prepaid.order_and_pay",
          )}
        </MobileButton>

        <MobileLightButton
          aria-label="submitTimeStep"
          onClick={() => stepBack()}
        >
          {translate("app.auth.back")}
        </MobileLightButton>
      </ButtonContainer>
    </>
  );
};

export default StepTimePicker;
