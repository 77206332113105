import React from "react";
import {
  Edit,
  required,
  SimpleForm,
  TranslatableInputs,
  useAuthenticated,
} from "react-admin";
import {
  buttons,
  StyledColorInput,
  StyledSelect,
  StyledTextInput,
} from "../../../themes/styles";
import { helpdeskActiveTypes } from "../../../utils/helpdeskTicketStatusTypes";

const StatusesEdit = () => {
  useAuthenticated();

  return (
    <Edit
      redirect="list"
      mutationMode="pessimistic"
      sx={{
        "& .MuiButton-text, .MuiButton-root": buttons.whiteButton,
        "& .RaEdit-main": {
          paddingTop: "40px",
        },
      }}
    >
      <SimpleForm sx={{ paddingTop: "20px" }}>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="name"
            label="app.auth.title"
            validate={required()}
            fullWidth
          />
        </TranslatableInputs>

        <StyledColorInput
          variant="outlined"
          source="color"
          label="app.helpdesk.color"
          validate={required()}
        />

        <StyledSelect
          source="active"
          label="app.auth.active"
          choices={helpdeskActiveTypes}
          validate={required()}
          variant="outlined"
          sx={{ width: "200px", marginTop: "15px" }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default StatusesEdit;
