import React from "react";
import { Box, Grid, Chip } from "@mui/material";
import { itemInfoComponent, StyledShow } from "../../../themes/styles";
import {
  TextField,
  useTranslate,
  BooleanField,
  useShowController,
  FunctionField,
  ImageField,
  useAuthenticated,
} from "react-admin";
import { IUser } from "../../../types/interfaces/user.interface";
import UsersShowActions from "./UsersShowActions";

const UsersShow = () => {
  useAuthenticated();
  const translate = useTranslate();
  const { record } = useShowController();

  return (
    <StyledShow actions={<UsersShowActions />}>
      <Box sx={itemInfoComponent}>
        <Grid container spacing={3} sx={{ ml: 0, mt: 0, mb: 2, p: 3 }}>
          <Grid className="item-info__container" item xs={5}>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.auth.email")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  source="email"
                  label="app.auth.email"
                  sortable={false}
                  fullWidth
                  sx={{ color: "black", fontWeight: "bold" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.auth.first_name")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  source="firstName"
                  label="app.auth.name"
                  sortable={false}
                  fullWidth
                  sx={{ color: "black", fontWeight: "bold" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.auth.surname")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  source="lastName"
                  label="app.auth.surname"
                  sortable={false}
                  fullWidth
                  sx={{ color: "black", fontWeight: "bold" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.auth.phoneNumber")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  source="phoneNumber"
                  label="app.auth.phoneNumber"
                  sortable={false}
                  fullWidth
                  sx={{ color: "black", fontWeight: "bold" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.auth.active")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <BooleanField
                  source="active"
                  label="app.auth.active"
                  sortable={false}
                  sx={{ color: record?.active ? "#00A044" : "#EC5B56" }}
                />
              </Grid>
            </Grid>
            {record?.avatarId && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.auth.image")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <FunctionField
                    render={(item: IUser) => {
                      const imageUrl = `https://yba-api-dev.2cw.dev/api/v1/storage/files/${item.avatarId}`;
                      return <ImageField src={imageUrl} />;
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.auth.removed")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <BooleanField
                  source="removed"
                  label="app.auth.removed"
                  sortable={false}
                  sx={{ color: record?.removed ? "#00A044" : "#EC5B56" }}
                />
              </Grid>
            </Grid>
            {record?.roles.length > 0 && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.auth.roles")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <FunctionField
                    render={(item: IUser) => {
                      return item.roles.map((role: string) => (
                        <Chip label={role} />
                      ));
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </StyledShow>
  );
};

export default UsersShow;
