import * as React from "react";
import { ReactElement } from "react";
import PropTypes from "prop-types";
import { StyledDrawer } from "../../themes/styles";
import {
  DrawerProps,
  Theme,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import { useLocaleState, useSidebarState } from "react-admin";

export const CustomSidebar = (props: SidebarProps) => {
  const { children, ...rest } = props;
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const [open, setOpen] = useSidebarState();
  useLocaleState(); // force redraw on locale change
  const trigger = useScrollTrigger();

  const toggleSidebar = () => setOpen(!open);

  return isXSmall ? (
    <StyledDrawer
      variant="temporary"
      open={open}
      onClose={toggleSidebar}
      classes={SidebarClasses}
      {...rest}
      sx={{
        "& .MuiButtonBase-root": {
          minHeight: {
            xs: "20px",
          },
        },
      }}
    >
      {children}
    </StyledDrawer>
  ) : (
    <StyledDrawer
      variant="permanent"
      open={open}
      onClose={toggleSidebar}
      classes={SidebarClasses}
      className={trigger ? SidebarClasses.appBarCollapsed : ""}
      {...rest}
      disableScrollLock={true}
      sx={{
        paddingBottom: "30px",
        "& .RaMenu-closed": {
          padding: "0 10px 30px",
        },
        "& .RaMenu-open": {
          width: "265px !important",
        },
      }}
    >
      <div className={SidebarClasses.fixed}>{children}</div>
    </StyledDrawer>
  );
};

export default CustomSidebar;

CustomSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export interface SidebarProps extends DrawerProps {
  children: ReactElement;
  closedSize?: number;

  size?: number;
}

const PREFIX = "RaSidebar";

export const SidebarClasses = {
  docked: `${PREFIX}-docked`,
  paper: `${PREFIX}-paper`,
  paperAnchorLeft: `${PREFIX}-paperAnchorLeft`,
  paperAnchorRight: `${PREFIX}-paperAnchorRight`,
  paperAnchorTop: `${PREFIX}-paperAnchorTop`,
  paperAnchorBottom: `${PREFIX}-paperAnchorBottom`,
  paperAnchorDockedLeft: `${PREFIX}-paperAnchorDockedLeft`,
  paperAnchorDockedTop: `${PREFIX}-paperAnchorDockedTop`,
  paperAnchorDockedRight: `${PREFIX}-paperAnchorDockedRight`,
  paperAnchorDockedBottom: `${PREFIX}-paperAnchorDockedBottom`,
  modal: `${PREFIX}-modal`,
  fixed: `${PREFIX}-fixed`,
  appBarCollapsed: `${PREFIX}-appBarCollapsed`,
};

export const DRAWER_WIDTH = 260;
export const CLOSED_DRAWER_WIDTH = 68;
