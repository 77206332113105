import * as React from "react";
import { FC } from "react";
import { styled } from "@mui/material/styles";
import { Children, memo } from "react";
import PropTypes from "prop-types";
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { ComponentPropType } from "ra-core";

import { SidebarToggleButton, UserMenu, HideOnScroll } from "react-admin";
import LoadingIndicator from "./LoadingIndicator";

export const BasicAppBar: FC<any> = memo((props) => {
  const {
    children,
    className,
    color = "secondary",
    userMenu = DefaultUserMenu,
    container: Container = HideOnScroll,
    ...rest
  } = props;

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );

  return (
    <Container className={className}>
      <StyledAppBar className={AppBarClasses.appBar} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? "regular" : "dense"}
          className={AppBarClasses.toolbar}
        >
          <SidebarToggleButton className={AppBarClasses.menuButton} />
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={AppBarClasses.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          <LoadingIndicator />
          {typeof userMenu === "boolean" ? (
            userMenu === true ? (
              <UserMenu />
            ) : null
          ) : (
            userMenu
          )}
        </Toolbar>
      </StyledAppBar>
    </Container>
  );
});

BasicAppBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "default",
    "inherit",
    "primary",
    "secondary",
    "transparent",
  ]),
  container: ComponentPropType,
  // @deprecated
  open: PropTypes.bool,
  userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

const DefaultUserMenu = <UserMenu />;

export interface AppBarProps extends Omit<MuiAppBarProps, "title"> {
  container?: React.ElementType<any>;
  // @deprecated
  open?: boolean;
  title?: string | undefined;
  userMenu?: React.ReactNode | boolean;
}

const PREFIX = "RaAppBar";

export const AppBarClasses = {
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonIconClosed: `${PREFIX}-menuButtonIconClosed`,
  menuButtonIconOpen: `${PREFIX}-menuButtonIconOpen`,
  title: `${PREFIX}-title`,
};

const StyledAppBar = styled(MuiAppBar, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${AppBarClasses.toolbar}`]: {
    padding: `0 ${theme.spacing(1.5)} 0 0`,
    [theme.breakpoints.down("md")]: {
      minHeight: theme.spacing(6),
    },
  },
  [`& .${AppBarClasses.menuButton}`]: {
    marginLeft: "0.2em",
    marginRight: "0.2em",
  },
  [`& .${AppBarClasses.title}`]: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
