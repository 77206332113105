import * as React from "react";

const DashboardIcon = ({ size = 18, color = "#606060", style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      style={style}
      viewBox="0 0 16.029 16"
    >
      <path
        id="icons8-combo-chart"
        d="M16.128,1.023,12.545,4.607,8.831,3.364l-3.689,4L1.453,5.955,1.014,7.107l4.474,1.7L9.2,4.79l3.685,1.223L17,1.89ZM8.4,9.625v7.4H9.631v-7.4Zm7.4,0v7.4h1.233v-7.4ZM5.932,10.858v6.165H7.165V10.858Zm4.932,0v6.165H12.1V10.858ZM1,12.091v4.932H2.233V12.091Zm2.466,0v4.932H4.7V12.091Zm9.864,0v4.932h1.233V12.091Z"
        transform="translate(-1 -1.023)"
        fill={color}
      />
    </svg>
  );
};

export default DashboardIcon;
