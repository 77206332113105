import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ParkingContext } from "../services/parking/parkingContext";

const useOnPageLoad = () => {
  const { step, orgId } = useParams();
  const navigate = useNavigate();
  const { currentStep } = useContext(ParkingContext);

  useEffect(() => {
    if (step !== currentStep) {
      navigate(`/parking/prepaid/${orgId}/steps/1`);
    }
  }, [currentStep, navigate, orgId, step]);
};

export default useOnPageLoad;
