import React from "react";
import {
  Edit,
  TabbedForm,
  Toolbar,
  FormTab,
  required,
  TranslatableInputs,
  FormDataConsumer,
  useNotify,
  useEditContext,
  useRedirect,
  useTranslate,
  useRefresh,
} from "react-admin";
import {
  StyledSaveButton,
  StyledSelect,
  StyledTextInput,
  StyledNumberInput,
  StyledImageInput,
  StyledDeleteButton,
} from "../../../themes/styles";
import moduleTypesChoices from "./module-types";
import { EModuleType } from "../../../types/enums/mobile/mobile-module-type.enum";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileRejection } from "react-dropzone";
import CustomImageField from "../../../components/forms/CustomImageField";
import { mobileModulesService } from "../../../api/MobileModulesService";
import { mobileActivityTypes } from "./mobileActivityTypes";
import useUploadModuleIcon from "../../../hooks/useUploadModuleIcon";

const ModulesEditToolbar = () => (
  <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
    <StyledSaveButton />
    <StyledDeleteButton />
  </Toolbar>
);

const ModulesEditView = () => {
  const notify = useNotify();
  const { record: module } = useEditContext();
  const refresh = useRefresh();
  const translate = useTranslate();
  const redirect = useRedirect();

  const {
    uploadedAttachmentId,
    deleteUploadedFile,
    uploadFiles,
    handleFileRejections,
  } = useUploadModuleIcon();

  const onSubmit = async (data: any) => {
    delete data.icon;
    data.iconId = uploadedAttachmentId;
    try {
      await mobileModulesService.updateMobileModule(data, module.id);
      notify(translate("app.mobile.module_updated_success"), {
        type: "success",
      });
      refresh();
      redirect("list", "mobile/admin/organization/modules");
    } catch (e) {
      notify(
        e?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    }
  };

  return (
    <TabbedForm toolbar={<ModulesEditToolbar />} onSubmit={onSubmit}>
      <FormTab label="app.mobile.module_general">
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="name"
            label="app.mobile.name"
            validate={required()}
          />
        </TranslatableInputs>
        <StyledSelect
          variant="outlined"
          source="type"
          label="app.mobile.type"
          choices={moduleTypesChoices}
          sx={{ mt: 3 }}
          validate={required()}
        />
        <StyledSelect
          source="active"
          label="app.mobile.active"
          choices={mobileActivityTypes}
          validate={required()}
          variant="outlined"
          sx={{ width: "200px", marginTop: "15px" }}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            switch (formData.type) {
              case EModuleType.Parking:
                return <></>;
              case EModuleType.Helpdesk:
                return <></>;
              case EModuleType.WebFrame:
                return (
                  <StyledTextInput
                    variant="outlined"
                    source="ref"
                    label="app.mobile.url"
                    type="url"
                    style={{ marginTop: 20 }}
                    validate={required()}
                    {...rest}
                  />
                );
            }
          }}
        </FormDataConsumer>
        <StyledNumberInput
          variant="outlined"
          source="position"
          label="app.mobile.position"
          min={0}
          sx={{ marginTop: "15px" }}
          validate={required()}
        />
        <StyledImageInput
          defaultValue={[]}
          source="icon"
          label="Icon"
          accept={["image/jpeg", "image/png", "image/gif", "image/bmp"]}
          removeIcon={DeleteIcon}
          validate={required()}
          onRemove={() => deleteUploadedFile()}
          options={{
            onDrop: (files: File[]) => uploadFiles(files),
            onDropRejected: (fileRejections: FileRejection[]) =>
              handleFileRejections(fileRejections),
          }}
        >
          <CustomImageField />
        </StyledImageInput>
      </FormTab>
    </TabbedForm>
  );
};

const ModulesEdit = () => {
  return (
    <Edit mutationMode="pessimistic" title="app.mobile.module" redirect={false}>
      <ModulesEditView />
    </Edit>
  );
};

export default ModulesEdit;
