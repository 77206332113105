import React, { useMemo } from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useListController } from "react-admin";

const SkeletonLoaderList = (props: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const { children } = props;
  const { perPage, data } = useListController();
  const amountOfRows = useMemo(() => {
    return data?.length / perPage < 1 ? data.length : perPage;
  }, [data, perPage]);

  if (!data) {
    return (
      <Box
        sx={{
          height: "max-content",
        }}
      >
        {[...Array(amountOfRows)].map((_, index) => (
          <Skeleton
            variant="rounded"
            sx={{ width: "100%", height: "35px", my: 2, mx: 1 }}
            key={index}
          />
        ))}
      </Box>
    );
  }

  return <>{children}</>;
};

export default SkeletonLoaderList;
