import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  DragEvent,
} from "react";
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Connection,
  Controls,
  Edge,
  EdgeChange,
  MarkerType,
  Node,
  NodeChange,
  Position,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { useLocaleState, useRecordContext } from "react-admin";
import { IWorkflow } from "../../../../types/interfaces/helpdesk/workflow/workflow.interface";
import { ITicketStatus } from "../../../../types/interfaces/helpdesk/ticket/ticket-status.interfaces";
import CustomEdge from "./CustomEdge";
import SidePanel from "./SidePanel";
import { httpClient } from "../../../../providers/dataProvider";
import { ConfigManager } from "../../../../constants/ConfigManager";
import { CircularProgress } from "@mui/material";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { ReactFlowInstance } from "@reactflow/core/dist/esm/types/instance";
import InitialNode from "./InitialNode";
import { IWorkflowNode } from "../../../../types/interfaces/helpdesk/workflow/workflow-node.interface";
import { IWorkflowStep } from "../../../../types/interfaces/helpdesk/workflow/workflow-step.interface";
import { v4 as uuidv4 } from "uuid";
import { IUpdateWorkflowStatus } from "../../../../types/interfaces/helpdesk/workflow/update-workflow-status.interface";
import {
  getInitialNode,
  getNodeType,
  transformEdges,
  transformNodes,
} from "../../../../utils/workflow/workflowService";

const edgeTypes = {
  "custom-edge": CustomEdge,
};

const nodeTypes = {
  "circle-node": InitialNode,
};

type Props = {
  synchronizeWorkflowDataAndForm: (
    statuses: IUpdateWorkflowStatus[],
    steps: IWorkflowStep[],
  ) => void;
};

const Flow = ({ synchronizeWorkflowDataAndForm }: Props) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const reactFlowWrapper = useRef(null);

  const [availableNodes, setAvailableNodes] = useState<Node[]>([]);

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>(null);
  const [locale] = useLocaleState();

  const record = useRecordContext<IWorkflow>();

  useEffect(() => {
    createInitialFlow();
  }, []);

  const createInitialFlow = async () => {
    const statuses = await fetchStatuses();
    createAvailableNodes(statuses);
    const createdNodes = createInitialNodes();
    createInitialEdges(createdNodes);
    setIsLoading(false);
  };

  const createInitialNodes = () => {
    const nodes: Node[] = [];

    const initialNode: Node = {
      id: "initialNode",
      data: {
        label: "Start",
      },
      position: {
        x: record?.statuses[0]?.position?.x
          ? record?.statuses[0]?.position?.x - 200
          : 10,
        y: record?.statuses[0]?.position?.y || 10,
      },
      type: "circle-node",
    };

    nodes.push(initialNode);

    if (record?.statuses && record.statuses.length > 0) {
      record.statuses.forEach((workflowNode: IWorkflowNode) => {
        const nodeType = getNodeType(workflowNode.isFinal);

        const nodeObject = {
          id: workflowNode.id,
          data: {
            label: `${
              workflowNode.name[locale as keyof typeof workflowNode.name]
            } (${workflowNode.codeName})`,
            isInitial: workflowNode.isInitial,
            isFinal: workflowNode.isFinal,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          position: { x: workflowNode.position.x, y: workflowNode.position.y },
          type: nodeType,
        };

        nodes.push(nodeObject);
      });
    }

    setNodes(nodes);
    syncChangesWithFormData(nodes, []);

    return nodes;
  };

  const isAvailableNode = (nodes: IWorkflowNode[], nodeId: string) => {
    return !nodes.some((item: IWorkflowNode) => item.id === nodeId);
  };

  const createInitialEdges = (createdNodes: Node[]) => {
    const edges: Edge[] = [];
    const initialNode = getInitialNode(record.statuses);

    const initialNodeEdge = {
      id: "initialNodeEdge",
      source: "initialNode",
      target: initialNode?.id || record?.statuses[0]?.id,
      data: {
        label: "Initial",
      },
      markerEnd: {
        type: MarkerType.Arrow,
      },
      type: "custom-edge",
    };

    edges.push(initialNodeEdge);

    if (record?.steps && record.steps.length > 0) {
      record.steps.forEach((step: IWorkflowStep) => {
        const newEdge = {
          id: step.id,
          source: step.source,
          target: step.destination,
          markerEnd: {
            type: MarkerType.Arrow,
          },
          data: {
            label: step.name[locale as keyof typeof step.name],
          },
          type: "custom-edge",
        };

        edges.push(newEdge);
      });
    }

    syncChangesWithFormData(createdNodes, edges);
    setEdges(edges);
  };

  const fetchStatuses = async () => {
    try {
      const { json } = await httpClient(
        `${ConfigManager.getInstance().getApiAdminUrl()}/helpdesk/admin/organization/statuses`,
        {
          method: "GET",
        },
      );

      return json.data;
    } catch (e) {
      console.log(e);
    }
  };

  const createAvailableNodes = (availableNodes: ITicketStatus[]) => {
    availableNodes.forEach((item: ITicketStatus) => {
      if (isAvailableNode(record.statuses, item.id)) {
        const nodeObject = {
          id: item.id,
          data: {
            label: `${item.name[locale as keyof typeof item.name]} (${
              item.codeName
            })`,
          },
          position: {
            x: 0,
            y: 0,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type: "default",
        };

        setAvailableNodes((nds) => nds.concat(nodeObject));
      }
    });
  };

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      if (changes[0].type === "remove" && changes[0].id === "initialNode") {
        return;
      }
      setNodes((nds) => {
        return applyNodeChanges(changes, nds);
      });
    },
    [setNodes],
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      if (changes[0].type === "remove" && changes[0].id === "initialNodeEdge") {
        return;
      }

      setEdges((eds: Edge[]) => {
        syncChangesWithFormData(nodes, applyEdgeChanges(changes, eds));
        return applyEdgeChanges(changes, eds);
      });
    },
    [setEdges, nodes],
  );

  const onConnect = useCallback(
    (connection: Connection) => {
      setEdges((eds: Edge[]) => {
        const newEdge = { ...connection, type: "custom-edge", id: uuidv4() };
        syncChangesWithFormData(nodes, [...eds, newEdge as Edge]);
        return addEdge(newEdge, eds);
      });
    },
    [setEdges, nodes],
  );

  const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const nodeData: Node = JSON.parse(
        event.dataTransfer.getData("application/reactflow"),
      );

      if (typeof nodeData.type === "undefined" || !nodeData) {
        return;
      }

      const position = reactFlowInstance?.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNode = {
        id: nodeData.id,
        type: nodeData.type,
        position,
        data: { label: nodeData.data.label },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      };

      setAvailableNodes((nds) => nds.filter((item) => item.id !== nodeData.id));
      setNodes((nds) => {
        syncChangesWithFormData(nds.concat(newNode), edges);
        return nds.concat(newNode);
      });
    },
    [reactFlowInstance, edges],
  );

  /**
   * Synchronizes changes made to nodes and edges with form data by calling the relevant helper functions.
   *
   * @param {Node[]} nodes - The array of nodes representing the updated workflow data.
   * @param {Edge[]} newEdges - The array of new edges to be added.
   *
   * @returns {void}
   */
  const syncChangesWithFormData = (nodes: Node[], newEdges: Edge[]): void => {
    synchronizeWorkflowDataAndForm(
      transformNodes(nodes, newEdges),
      transformEdges(newEdges),
    );
  };

  const onNodesDelete = (nodes: Node<any, string | undefined>[]) => {
    setAvailableNodes((nds) => {
      syncChangesWithFormData(nds.concat(nodes), edges);
      return nds.concat(nodes);
    });
  };

  const onNodeDragStop = () => {
    syncChangesWithFormData(nodes, edges);
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress size={100} />
      </div>
    );
  }

  return (
    <>
      <div
        className="reactflow-wrapper"
        ref={reactFlowWrapper}
        style={{ height: "400px" }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodesDelete={onNodesDelete}
          onConnect={onConnect}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodeDragStop={onNodeDragStop}
          fitView
          onInit={setReactFlowInstance as ReactFlowInstance<any, any>}
          attributionPosition="bottom-left"
          translateExtent={[
            [-900, -900],
            [900, 900],
          ]}
          nodeExtent={[
            [-700, -500],
            [700, 500],
          ]}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
      <SidePanel availableNodes={availableNodes} />
    </>
  );
};

export default Flow;
