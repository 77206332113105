import React, { createContext, useMemo, useState } from "react";
import { IProfile } from "../../types/interfaces/profile.interface";
import { IPaymentParams } from "../../types/interfaces/parking/payment-params.interface";
import { PaymentProcessStep } from "../../types/enums/payment-process-step.enum";
import { IOrganization } from "../../types/interfaces/organization.interface";

type User = Omit<IProfile, "roles" | "id">;

export const defaultUser: User = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const defaultParkingPaymentParams = {
  customerEphemeralKeySecret: "",
  paymentIntentClientSecret: "",
  customerId: "",
};

type ParkingContextType = {
  plateNumber: string;
  savePlateNumber: (plateNumber: string) => void;
  saveParkingHours: (hours: number) => void;
  saveParkingMinutes: (minutes: number) => void;
  parkingHours: number;
  parkingMinutes: number;
  setStopwatchSeconds: (seconds: (prevSeconds: number) => number) => void;
  setStopwatchMinutes: (minutes: (prevMinutes: number) => number) => void;
  setStopwatchHours: (hours: (prevHours: number) => number) => void;
  stopwatchHours: number;
  stopwatchMinutes: number;
  stopwatchSeconds: number;
  isActiveStopwatch: boolean;
  setIsActiveStopwatch: (isActiveStopwatch: boolean) => void;
  user: User;
  setUser: (user: User) => void;
  parkingTimeInMinutes: number;
  parkingPaymentParams: IPaymentParams;
  saveParkingPaymentParams: (params: IPaymentParams) => void;
  clearContext: () => void;
  saveCurrentStep: (step: PaymentProcessStep) => void;
  currentStep: PaymentProcessStep;
  setParkingProcess: (isFree: boolean) => void;
  isFreeParkingProcess: boolean;
  customerLogo: string;
  saveCustomerLogo: (logoUrl: string) => void;
  activeTicketValidToDate: number;
  saveActiveTicketValidToDate: (date: number) => void;
  acceptance: boolean;
  saveAcceptance: (acceptance: boolean) => void;
  organizationInfo: IOrganization | undefined;
  saveOrganizationInfo: (info: IOrganization) => void;
};

export const ParkingContext = createContext<ParkingContextType>({
  plateNumber: "",
  savePlateNumber: (plateNumber: string) => console.log(plateNumber),
  saveParkingHours: (hours: number) => console.log(hours),
  saveParkingMinutes: (minutes: number) => console.log(minutes),
  parkingHours: 1,
  parkingMinutes: 10,
  setStopwatchSeconds: (seconds: (prevSeconds: number) => number) =>
    console.log(seconds),
  setStopwatchMinutes: (minutes: (prevMinutes: number) => number) =>
    console.log(minutes),
  setStopwatchHours: (hours: (prevHours: number) => number) =>
    console.log(hours),
  stopwatchHours: 0,
  stopwatchMinutes: 0,
  stopwatchSeconds: 0,
  isActiveStopwatch: false,
  setIsActiveStopwatch: (isActiveStopwatch: boolean) =>
    console.log(isActiveStopwatch),
  user: defaultUser,
  setUser: (user: User) => console.log(user),
  parkingTimeInMinutes: 0,
  parkingPaymentParams: defaultParkingPaymentParams,
  saveParkingPaymentParams: (params: IPaymentParams) => console.log(params),
  clearContext: () => console.log("clear"),
  saveCurrentStep: (step: PaymentProcessStep) => console.log(step),
  currentStep: PaymentProcessStep.STEP_FIRST,
  setParkingProcess: (isFree: boolean) => console.log(isFree),
  isFreeParkingProcess: false,
  customerLogo: "",
  saveCustomerLogo: (logoUrl: string) => console.log(logoUrl),
  activeTicketValidToDate: 0,
  saveActiveTicketValidToDate: (date: number) => console.log(date),
  acceptance: false,
  saveAcceptance: (acceptance: boolean) => console.log(acceptance),
  organizationInfo: { id: "", name: "", displayName: "", logoId: "" },
  saveOrganizationInfo: (info: IOrganization) => console.log(info),
});

export const ParkingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [plateNumber, setPlateNumber] = useState<string>("");
  const [selectedHours, setSelectedHours] = useState<number>(0);
  const [selectedMinutes, setSelectedMinutes] = useState<number>(10);

  const [organizationInfo, setOrganizationInfo] = useState<
    IOrganization | undefined
  >();

  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [customerLogo, setCustomerLogo] = useState<string>("");

  const [isActiveStopwatch, setIsActiveStopwatch] = useState<boolean>(false);

  const [acceptance, setAcceptance] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<PaymentProcessStep>(
    PaymentProcessStep.STEP_FIRST,
  );

  const [activeTicketValidToDate, setActiveTicketValidToDate] =
    useState<number>(0);

  const [isFreeParkingProcess, setIsFreeParkingProcess] =
    useState<boolean>(false);

  const [parkingPaymentParams, setParkingPaymentParams] =
    useState<IPaymentParams>(defaultParkingPaymentParams);

  const [user, setUser] = useState<User>(defaultUser);

  const save = (plateNumber: string) => {
    setPlateNumber(plateNumber);
  };

  const saveCustomerLogo = (logoUrl: string) => {
    setCustomerLogo(logoUrl);
  };

  const parkingTimeInMinutes = useMemo(() => {
    return selectedHours * 60 + selectedMinutes;
  }, [selectedMinutes, selectedHours]);

  const clearContext = () => {
    setSelectedHours(0);
    setSelectedMinutes(10);
    setUser(defaultUser);
    setIsActiveStopwatch(false);
    setPlateNumber("");
    setSeconds(0);
    setMinutes(0);
    setHours(0);
    setParkingPaymentParams(defaultParkingPaymentParams);
  };

  return (
    <ParkingContext.Provider
      value={{
        plateNumber,
        savePlateNumber: save,
        saveParkingHours: setSelectedHours,
        saveParkingMinutes: setSelectedMinutes,
        parkingHours: selectedHours,
        parkingMinutes: selectedMinutes,
        setStopwatchSeconds: setSeconds,
        setStopwatchHours: setHours,
        setStopwatchMinutes: setMinutes,
        stopwatchHours: hours,
        stopwatchMinutes: minutes,
        stopwatchSeconds: seconds,
        isActiveStopwatch,
        setIsActiveStopwatch,
        user,
        setUser,
        parkingTimeInMinutes,
        parkingPaymentParams,
        saveParkingPaymentParams: setParkingPaymentParams,
        clearContext,
        currentStep,
        saveCurrentStep: setCurrentStep,
        setParkingProcess: setIsFreeParkingProcess,
        isFreeParkingProcess,
        customerLogo,
        saveCustomerLogo,
        activeTicketValidToDate,
        saveActiveTicketValidToDate: setActiveTicketValidToDate,
        acceptance,
        saveAcceptance: setAcceptance,
        organizationInfo,
        saveOrganizationInfo: setOrganizationInfo,
      }}
    >
      {children}
    </ParkingContext.Provider>
  );
};
