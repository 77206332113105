import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { MobileButton } from "./styles";
import { styled } from "@mui/material/styles";
import { useTranslate } from "react-admin";
import { ParkingContext } from "../../../services/parking/parkingContext";

const Container = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 130px)",
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    height: "60vh",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  height: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  [theme.breakpoints.down("md")]: {
    paddingTop: "30px",
    height: "100%",
  },
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",

  [theme.breakpoints.down("md")]: {
    paddingBottom: "20px",
  },
}));

const SmsStep = () => {
  const translate = useTranslate();

  const { clearContext } = useContext(ParkingContext);

  const { orgId } = useParams();

  const finishParkingPrepaidProcess = () => {
    clearContext();
    window.location.href = `${window.location.origin}/#/parking/prepaid/${orgId}/steps/1`;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = `${window.location.origin}/#/parking/prepaid/${orgId}/steps/1`;
    }, 10000);

    return () => clearTimeout(timeout);
  }, [orgId]);

  return (
    <Container>
      <InnerContainer>
        <TitleContainer>
          <Box style={{ marginBottom: "5px" }}>
            {translate("app.parking.prepaid.sms_step_title")}
          </Box>
          <Box data-testid="prepaidPageStepThirdTitle">
            {translate("app.parking.prepaid.sms_step_subtitle")}
          </Box>
        </TitleContainer>
        <ButtonContainer>
          <MobileButton onClick={() => finishParkingPrepaidProcess()}>
            {translate("app.parking.prepaid.finish")}
          </MobileButton>
        </ButtonContainer>
      </InnerContainer>
    </Container>
  );
};

export default SmsStep;
