import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Button,
  Form,
  maxValue,
  useNotify,
  useShowContext,
  useTranslate,
} from "react-admin";
import useParkingSettings from "../../../../hooks/useParkingSettings";
import DialogTitle from "@mui/material/DialogTitle";
import { buttons, StyledTextInput } from "../../../../themes/styles";
import IconSave from "@mui/icons-material/Save";
import IconCancel from "@mui/icons-material/Cancel";
import { adminParkingService } from "../../../../api/AdminParkingService";

type Props = {
  close: () => void;
  isDialogVisible: boolean;
};

const ExtendParkingTimeDialog: React.FC<Props> = React.memo((props) => {
  const { close, isDialogVisible } = props;

  const translate = useTranslate();
  const notify = useNotify();

  const { settings } = useParkingSettings();
  const { record: vehicle } = useShowContext();

  const extendTime = async (data: any) => {
    const newData = { extend: parseInt(data.extend) };
    try {
      await adminParkingService.modifyTicket(vehicle.plateNumber, newData);
      notify(translate("app.parking.extend_success"), {
        type: "success",
      });
      close();
    } catch (e) {
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    }
  };

  return (
    <Dialog
      open={isDialogVisible}
      transitionDuration={400}
      onClose={close}
      fullWidth
    >
      <DialogTitle>
        <div style={{ textAlign: "center" }}>
          {translate("app.parking.extend_time_for")} {vehicle.plateNumber}
        </div>
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={(data) => extendTime(data)}>
          <DialogContent>
            <StyledTextInput
              source="extend"
              variant="outlined"
              label="app.parking.in_minutes"
              type="number"
              fullWidth
              validate={[
                maxValue(
                  settings.tickets.management.limits.extend.maxMinutesOnce,
                ),
              ]}
            />
          </DialogContent>
          <DialogActions
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Button
              label="ra.action.save"
              type="submit"
              sx={buttons.darkButton}
            >
              <IconSave />
            </Button>
            <Button
              label="ra.action.cancel"
              onClick={close}
              sx={buttons.whiteButton}
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

export default ExtendParkingTimeDialog;
