import React from "react";
import { useLocaleState } from "react-admin";
import { ITicketAttribute } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import AttributeInput from "./AttributeInput";

type AttributesFieldArrayType = {
  inputsData: ITicketAttribute[];
};

const FormInputWrapper = styled(Box)(() => ({
  width: "100%",
  margin: "10px 0",
}));

const AttributesInputArray = ({ inputsData }: AttributesFieldArrayType) => {
  const [locale] = useLocaleState();

  return (
    <>
      {inputsData.map((item: ITicketAttribute, index: number) => {
        return (
          <FormInputWrapper key={`attribute${index}`}>
            <AttributeInput
              source={item.id}
              inputType={item.type}
              label={item.name[locale as keyof typeof item.name]}
              choices={item?.options}
              isRequired={!item.isOptional}
              isSavingNewValues={item?.isSavingNewValues}
            />
          </FormInputWrapper>
        );
      })}
    </>
  );
};

export default AttributesInputArray;
