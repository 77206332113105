import React, { useCallback } from "react";
import { AllowedAttributeType } from "../../../../types/enums/ticket-attribute-type.enums";
import {
  DateField,
  FunctionField,
  NumberField,
  RichTextField,
  TextField,
  useLocaleState,
} from "react-admin";
import { Chip } from "@mui/material";
import { ITicketAttribute } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";

const AttributeField = ({ record }: { record: ITicketAttribute }) => {
  const [locale] = useLocaleState();

  const getSelectFieldNameById = useCallback(
    (id: string) => {
      return record.options.find((item) => item.id === id)?.value[
        locale as keyof { pl: string; en: string }
      ];
    },
    [locale, record.options],
  );

  const getSelectStyleById = useCallback(
    (id: string) => {
      return record.options.find((item) => item.id === id)?.style?.color;
    },
    [record],
  );

  switch (record.type) {
    case AllowedAttributeType.SingleLineText:
      return <TextField source="value" record={record} />;
    case AllowedAttributeType.DateTime:
      return <DateField source="value" record={record} />;
    case AllowedAttributeType.Decimal:
    case AllowedAttributeType.Integer:
      return <NumberField source="value" record={record} />;
    case AllowedAttributeType.MultiLineText:
      return (
        <RichTextField
          source="value"
          record={record}
          sx={{ "& p": { margin: 0 } }}
        />
      );
    case AllowedAttributeType.SingleSelect:
      return (
        <FunctionField
          render={() => {
            return (
              <Chip
                color="primary"
                label={getSelectFieldNameById(record.value)}
                style={{ backgroundColor: getSelectStyleById(record.value) }}
              />
            );
          }}
        />
      );
    case AllowedAttributeType.Status:
      return (
        <FunctionField
          render={() => {
            return (
              <Chip
                color="primary"
                label={getSelectFieldNameById(record.value)}
                style={{ backgroundColor: getSelectStyleById(record.value) }}
              />
            );
          }}
        />
      );
    default:
      return null;
  }
};

export default AttributeField;
