import * as React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import { useEffect, useMemo, useState } from "react";
import { ConfigManager } from "../../constants/ConfigManager";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

interface StandardImgFieldProps {
  type?: string;
  attachmentId?: string;
}

const FileInputWrapper = styled(Box)(() => ({
  display: "inline-flex",
  marginBottom: "5px",
}));

const Image = styled("img")(() => ({ maxWidth: "100px", height: "100%" }));

const CustomAttachmentField = ({
  type,
  attachmentId,
}: StandardImgFieldProps) => {
  const record = useRecordContext();
  const [src, setSrc] = useState<string>("");
  const translate = useTranslate();

  useEffect(() => {
    if (record?.src) {
      setSrc(record.src);
    } else if (attachmentId !== undefined) {
      setSrc(
        `${ConfigManager.getInstance().getApiUrl()}/api/v1/storage/files/${attachmentId}`,
      );
    } else {
      setSrc("");
    }
  }, []);

  const isImage = useMemo(() => {
    const fileType = record?.rawFile ? record.rawFile.type : type;

    return fileType?.includes("image");
  }, [record, type]);

  const getTitle = () => {
    let defaultTitle = translate("app.helpdesk.attachment");

    if (type) {
      defaultTitle = `${defaultTitle} ${type.split("/")[1].toUpperCase()}`;
    }

    return record.title ?? defaultTitle;
  };

  if (src) {
    return (
      <FileInputWrapper>
        {isImage ? (
          <div
            style={{
              color: "#fff",
              width: "100%",
              textDecoration: "none",
              backgroundColor: "grey",
              padding: 0,
            }}
          >
            <Image crossOrigin="anonymous" src={src} alt="Image" />
          </div>
        ) : (
          <a
            href={src}
            style={{
              color: "#fff",
              width: "100%",
              textDecoration: "none",
              backgroundColor: "grey",
              padding: isImage ? 0 : 40,
            }}
            target="_blank"
          >
            <>{getTitle()}</>
          </a>
        )}
      </FileInputWrapper>
    );
  }

  return <></>;
};

export default CustomAttachmentField;
