import { ITicket } from "../../types/interfaces/helpdesk/ticket/ticket.interface";
import { ITicketAttribute } from "../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { ITicketAttributeOption } from "../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import { AllowedAttributeType } from "../../types/enums/ticket-attribute-type.enums";
import { ITicketGridRow } from "../../resources/helpdesk/tickets/TicketsList";
import { FileRejection } from "react-dropzone";

export const reformatToAcceptableByListComponent = (data: ITicket[]) => {
  const attributes = data.map((ticketItem: ITicket) => {
    return ticketItem.attributes.map((attrItem: ITicketAttribute) => {
      let value: string | ITicketAttributeOption | undefined = attrItem.value;
      if (
        attrItem.type === AllowedAttributeType.Status ||
        attrItem.type === AllowedAttributeType.SingleSelect
      ) {
        value = attrItem.options?.find(
          (optionItem) => optionItem.id === attrItem.value,
        );
      }

      return {
        createdAt: ticketItem.createdAt,
        reporter: ticketItem.user,
        name: attrItem.name.en.toLowerCase(),
        value,
        id: ticketItem.id,
      };
    });
  });

  // here we are processing array of attributes for each ticket
  return attributes.map((attributeArrayItem: any) => {
    const ticketRowInProperFormat: ITicketGridRow = {} as ITicketGridRow;
    attributeArrayItem.map((attributeItem: ITicketGridRow) => {
      ticketRowInProperFormat.id = attributeItem.id;
      ticketRowInProperFormat.reporter = attributeItem.reporter;
      ticketRowInProperFormat.createdAt = attributeItem.createdAt;
      ticketRowInProperFormat[
        attributeItem.name as keyof typeof ticketRowInProperFormat
      ] = attributeItem.value;
    });

    return ticketRowInProperFormat;
  });
};

export const uploadedAttachmentFormattedRejectionReason = (
  reasons: FileRejection[],
) => {
  return reasons
    .map((rejectionItem: FileRejection) => {
      const messages = rejectionItem.errors.map(
        (error: { code: string; message: string }) => error.message,
      );

      return messages.join(", ");
    })
    .join("; ");
};
