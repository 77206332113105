import { AxiosRequestConfig } from "axios";

import { apiRequest } from "./client";

import { UnauthenticatedTopic } from "../types/enums/unauthenticated-topic.enum";
import { ConfigManager } from "../constants/ConfigManager";

const unauthenticatedUser = async (data: {
  email: string;
  topic: UnauthenticatedTopic;
  groupId: string | undefined;
}) => {
  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/code/unauthenticated`,
      data,
    };
    return await apiRequest(config);
  } catch (e) {
    console.log(e, "Error during code generation");
    throw e.response?.data?.error;
  }
};

export const CodeService = {
  unauthenticatedUser,
};
