import React, { useEffect, useState } from "react";
import {
  Create,
  ListToolbarProps,
  SimpleForm,
  useAuthenticated,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, CircularProgress, Toolbar } from "@mui/material";
import { StyledFileInput, StyledSaveButton } from "../../../themes/styles";
import { ITicketAttribute } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { styled } from "@mui/material/styles";
import CustomAttachmentField from "../../../components/forms/CustomAttachmentField";
import { helpdeskTicketsService } from "../../../api/HelpdeskTicketsService";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import AttributesInputArray from "../../../components/resources/helpdesk/tickets/AttributesInputArray";
import { helpdeskAttributesService } from "../../../api/HelpdeskAttributesService";
import { EResource, getResourceUrl } from "../../../utils/resourcesHelper";
import { uploadedAttachmentFormattedRejectionReason } from "../../../utils/ticket/ticketsHelper";
import { FileRejection } from "react-dropzone";
import useHelpdeskSettings from "../../../hooks/useHelpdeskSettings";
import { getTranslatedFileInputPlaceholder } from "../../../utils/languageService";

const LoaderContainer = styled(Box)(() => ({
  textAlign: "center",
  width: "100%",
}));

const FormTitle = styled(Box)(() => ({
  padding: "20px 0",
  fontSize: "16px",
  fontWeight: "bold",
}));

const FormInnerContainer = styled(Box)(() => ({
  width: "80%",
  maxWidth: "500px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const TicketsCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const TicketsCreate = () => {
  useAuthenticated();

  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { settings } = useHelpdeskSettings();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<ITicketAttribute[]>([]);
  const [uploadedAttachmentIds, setUploadedAttachmentIds] = useState<
    { attachmentId: string; name: string }[]
  >([]);

  const ticketsUrl = getResourceUrl(EResource.TICKETS);

  const fetchTicketAttributes = async () => {
    setIsLoading(true);
    try {
      const { data } =
        await helpdeskAttributesService.getAttributes<
          IBasicResponse<ITicketAttribute[]>
        >();

      data.sort(
        (a: ITicketAttribute, b: ITicketAttribute) => a.position - b.position,
      );

      setAttributes(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketAttributes();
  }, []);

  const convertSubmittedDataToAcceptedFormat = (data: any) => {
    return Object.keys(data)
      .map((key) => ({ id: key, value: data[key] }))
      .filter(
        (item) => !["attachments", "value"].includes(item.id) && item.value,
      );
  };

  const convertAttachmentsDataToAcceptedFormat = () => {
    return uploadedAttachmentIds.map((item) => {
      return { attachmentId: item.attachmentId };
    });
  };

  const save = async (data: any) => {
    const newData: any = convertSubmittedDataToAcceptedFormat(data);
    delete newData.attachments;
    const ticketRequestData = {
      attributes: newData,
      attachments: convertAttachmentsDataToAcceptedFormat(),
    };

    try {
      await helpdeskTicketsService.createTicket(ticketRequestData);

      redirect("list", ticketsUrl);
    } catch (e) {
      notify(
        e?.translatedMessage || translate("app.notifications.error.undefined"),
        { type: "error" },
      );
    }
  };

  const uploadFiles = async (files: File[]) => {
    for (const fileItem of files) {
      const formData = new FormData();
      formData.append("attachment", fileItem);

      try {
        const { data } =
          await helpdeskTicketsService.uploadTemporaryAttachment<
            IBasicResponse<{ attachmentId: string }>
          >(formData);

        const uploadedAttachment = {
          attachmentId: data.attachmentId,
          name: fileItem.name,
        };
        setUploadedAttachmentIds((files) => files.concat([uploadedAttachment]));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleFileRejections = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      const message =
        uploadedAttachmentFormattedRejectionReason(fileRejections);

      notify(message, { type: "error" });
    }
  };

  const deleteUploadedFile = (file: any) => {
    setUploadedAttachmentIds((attachments) =>
      attachments.filter((item) => item.name !== file.title),
    );
  };

  return (
    <Create redirect="list">
      <SimpleForm
        toolbar={<TicketsCreateFormToolbar />}
        onSubmit={save}
        style={{ display: "flex", alignItems: "center" }}
      >
        <FormTitle>
          {translate("app.helpdesk.ticketsPage.createTicketTitle")}
        </FormTitle>
        <FormInnerContainer>
          {isLoading ? (
            <LoaderContainer>
              <CircularProgress size={100} />
            </LoaderContainer>
          ) : (
            <AttributesInputArray inputsData={attributes} />
          )}
        </FormInnerContainer>
        <StyledFileInput
          defaultValue={[]}
          source="attachments"
          label="Attachments"
          accept={settings.attachments.acceptTypes}
          multiple={true}
          maxSize={settings.attachments.limits.maxSize}
          placeholder={
            <p>
              {getTranslatedFileInputPlaceholder(
                settings.attachments.limits.maxSize / 1000000,
              )}
            </p>
          }
          removeIcon={DeleteIcon}
          onRemove={(file: File) => deleteUploadedFile(file)}
          options={{
            onDrop: (files: File[]) => uploadFiles(files),
            onDropRejected: (fileRejections: FileRejection[]) =>
              handleFileRejections(fileRejections),
          }}
        >
          <CustomAttachmentField />
        </StyledFileInput>
      </SimpleForm>
    </Create>
  );
};
export default TicketsCreate;
