import React from "react";
import { AllowedAttributeType } from "../../../../types/enums/ticket-attribute-type.enums";
import { ITicketAttributeOption } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import {
  StyledDateInput,
  StyledNumberInput,
  StyledRichTextInput,
  StyledTextInput,
} from "../../../../themes/styles";
import { required } from "react-admin";
import TicketAttributeSelectInput from "./TicketAttributeSelectInput";

export type IAttributeInputProps = {
  source: string;
  label: string;
  inputType: AllowedAttributeType;
  choices?: ITicketAttributeOption[];
  isRequired: boolean;
  isSavingNewValues?: boolean;
};

const AttributeInput = ({
  source,
  label,
  inputType,
  choices,
  isRequired,
  isSavingNewValues,
}: IAttributeInputProps) => {
  switch (inputType) {
    case AllowedAttributeType.SingleLineText:
      return (
        <StyledTextInput
          source={source}
          label={label}
          variant="outlined"
          style={{ width: "100%" }}
          defaultValue={""}
          validate={isRequired ? required() : []}
        />
      );
    case AllowedAttributeType.DateTime:
      return (
        <StyledDateInput
          source={source}
          label={label}
          variant="outlined"
          style={{ width: "100%" }}
          defaultValue={""}
          validate={isRequired ? required() : []}
        />
      );
    case AllowedAttributeType.Decimal:
    case AllowedAttributeType.Integer:
      return (
        <StyledNumberInput
          source={source}
          label={label}
          variant="outlined"
          style={{ width: "100%" }}
          defaultValue={""}
          validate={isRequired ? required() : []}
        />
      );
    case AllowedAttributeType.MultiLineText:
      return (
        <StyledRichTextInput
          source={source}
          label={label}
          fullWidth
          type="outlined"
          defaultValue={""}
          style={{ width: "100%" }}
          validate={isRequired ? required() : []}
        />
      );
    case AllowedAttributeType.SingleSelect:
      return (
        <>
          <TicketAttributeSelectInput
            source={source}
            label={label}
            choices={choices}
            isRequired={isRequired}
            isSavingNewValues={isSavingNewValues}
          />
        </>
      );
    default:
      return null;
  }
};

export default AttributeInput;
