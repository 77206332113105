import {
  useCreatePath,
  useGetResourceLabel,
  useResourceDefinitions,
} from "ra-core";
import {
  MenuClasses,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  useTranslate,
} from "react-admin";

import * as React from "react";
import DefaultIcon from "@mui/icons-material/ViewList";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import { useLocation } from "react-router";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Divider from "@mui/material/Divider";
import { Root, standardMenu } from "../themes/styles";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { useMediaQuery, Theme } from "@mui/material";
import StandardDashboardMenuItem from "./menu/DashboardMenuItem";
import { ComponentClass, FunctionComponent } from "react";

interface MenuItemData {
  hasCreate: boolean;
  hasEdit: boolean;
  hasList: boolean;
  icon?:
    | string
    | FunctionComponent<{ color: string; size?: number }>
    | ComponentClass<{ color: string; size?: number }>
    | undefined;
  name: string;
  options?: {
    label: string;
    isMenuParent: boolean;
    menuParent: string;
    sectionName: string;
    sectionKey: string;
  };
  recordRepresentation?: undefined | boolean;
}

interface IMenuItem {
  parent: MenuItemData;
  children: MenuItemData[];
}

export const StandardMenu = ({
  className,
  hasDashboard = true,
  ...rest
}: MenuProps) => {
  const [currentMenu, setCurrentMenu] = React.useState<string | null>(null);
  const [currentMenuItem, setMenuItem] = React.useState<null | string>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuItemChild, setMenuItemChild] = React.useState<string | null>(null);
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const location = useLocation();
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  // sidebar state
  const [open] = useSidebarState();

  const resourceList = Object.values(resources);
  const parentList = resourceList.filter((resource) =>
    Boolean(resource?.options?.isMenuParent),
  );
  const leafList = resourceList.filter(
    (resource) => resource.hasList && Boolean(resource?.options?.menuParent),
  );
  const orphanList = resourceList.filter(
    (resource) => resource.hasList && !resource?.options?.menuParent,
  );
  const activeResource = leafList.find((resource) =>
    location.pathname.startsWith(`/${resource.name}`),
  );
  const currentMenuName = activeResource?.options?.menuParent ?? null;
  const resourceGraph = parentList.map((resource) => {
    const parent = resource;
    const children = leafList.filter(
      (leafResource) => leafResource.options.menuParent === parent.name,
    );

    return { parent, children };
  });

  // handler for closing sidebar item, when sidebar is collapsed
  const handleClose = () => {
    setMenuItem(null);
    setAnchorEl(null);
  };

  // handler for opening sidebar item, when sidebar is collapsed
  const handleOpen = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setMenuItem(name);
    setAnchorEl(event.currentTarget);
  };

  const groupedResourcesBySections = resourceGraph.reduce(function (
    r,
    element,
  ) {
    r[element?.parent?.options?.sectionName] =
      r[element?.parent?.options?.sectionName] || [];
    r[element?.parent?.options?.sectionName].push(element);
    return r;
  }, Object.create(null));

  React.useEffect(() => {
    setCurrentMenu(currentMenuName);
  }, [currentMenuName]);

  const children = [
    hasDashboard ? (
      <StandardDashboardMenuItem
        primaryText="app.system.system_summary"
        menuOpen={open}
        sx={{ marginTop: !open ? "100px" : 0 }}
      />
    ) : null,
    Object.keys(groupedResourcesBySections).map((key: string) => {
      const menuElement = groupedResourcesBySections[key]
        .map((item: IMenuItem) => {
          return [
            // button for opening sidebar items children menu
            !open && (
              <Button
                aria-haspopup="true"
                disableTouchRipple={true}
                onMouseEnter={(event) => handleOpen(event, item.parent.name)}
                style={{
                  background:
                    currentMenuItem === item.parent.name ? "#F5F5F5" : "white",
                }}
                sx={standardMenu.menu.menuItemButton}
              >
                <ListItemIcon>
                  {item.parent.icon ? (
                    React.createElement(item.parent.icon, {
                      color: open
                        ? currentMenu === item.parent.name
                          ? "black"
                          : "#606060"
                        : "black",
                      size: 16,
                    })
                  ) : (
                    <DefaultIcon />
                  )}
                </ListItemIcon>
              </Button>
            ),
            // sidebar item children menu
            !open && (
              <Menu
                open={currentMenuItem === item.parent.name}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={standardMenu.menu.menuItemPopover}
              >
                {item.children.map((leaf: MenuItemData) => (
                  <MenuItemLink
                    key={leaf.name}
                    sx={{
                      marginLeft: open ? "16px" : "0px",
                      "& .MuiTouchRipple-root": { fontSize: "12px" },
                    }}
                    to={createPath({ resource: leaf.name, type: "list" })}
                    state={{ _scrollToTop: true }}
                    primaryText={getResourceLabel(leaf.name, 2)}
                  />
                ))}
              </Menu>
            ),
            open && (
              <MenuItem
                key={item.parent.name}
                disableRipple={true}
                disableTouchRipple={true}
                onClick={() => setCurrentMenu(item.parent.name)}
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  justifyContent: !open ? "center" : "unset",
                  marginBottom: !open ? "24px" : 0,
                  "& .MuiListItemIcon-root": {
                    width: "25px",
                    display: "flex",
                    justifyContent: "center",
                  },
                }}
              >
                <ListItemIcon>
                  {item.parent.icon ? (
                    React.createElement(item.parent.icon, {
                      color: open
                        ? currentMenu === item.parent.name
                          ? "black"
                          : "#606060"
                        : "black",
                    })
                  ) : (
                    <DefaultIcon />
                  )}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    sx={{
                      "& .MuiTypography-root": {
                        fontWeight:
                          currentMenu === item.parent.name ? "bold" : "normal",
                        fontSize: "14px",
                      },
                    }}
                  >
                    {getResourceLabel(item.parent.name, 2)}
                  </ListItemText>
                )}
                {open && (
                  <ListItemIcon>
                    {React.createElement(KeyboardArrowRightIcon, {
                      sx: {
                        transition: "transform 200ms ease-in-out",
                        transform: `rotate(${
                          currentMenu === item.parent.name ? 90 : 0
                        }deg)`,
                      },
                      htmlColor: "black",
                    })}
                  </ListItemIcon>
                )}
              </MenuItem>
            ),
            open ? (
              <Collapse
                in={currentMenu === item.parent.name}
                key={`${item.parent.name}-children`}
              >
                <List
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    paddingLeft: "5px",
                  }}
                >
                  {item.children.map((leaf: MenuItemData) => (
                    <MenuItemLink
                      key={leaf.name}
                      disableTouchRipple={true}
                      onClick={() => setMenuItemChild(leaf.name)}
                      sx={{
                        marginLeft: open ? "16px" : "0px",
                        fontSize: "14px",
                        color: "black",
                        "& .MuiTouchRipple-root": {
                          fontSize: "12px",
                        },
                        "&.RaMenuItemLink-active": {
                          fontWeight: menuItemChild === leaf.name ? "bold" : "",
                          background:
                            menuItemChild === leaf.name ? "#F5F5F5" : "",
                        },
                      }}
                      to={createPath({ resource: leaf.name, type: "list" })}
                      state={{ _scrollToTop: true }}
                      primaryText={getResourceLabel(leaf.name, 2)}
                    />
                  ))}
                </List>
              </Collapse>
            ) : (
              ""
            ),
          ].flat(Infinity);
        })
        .flat(Infinity);

      const sectionTitle = open ? (
        <h3
          data-testid="sectionTitle"
          style={{
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n" +
              "    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n" +
              "    sans-serif",
          }}
        >
          {translate(key)}
        </h3>
      ) : (
        <Divider light sx={standardMenu.menu.divider} />
      );
      menuElement.unshift(sectionTitle);
      return menuElement;
    }),
    ...orphanList.map((resource) => (
      <MenuItemLink
        key={resource.name}
        to={createPath({ resource: resource.name, type: "list" })}
        state={{ _scrollToTop: true }}
        primaryText={getResourceLabel(resource.name, 2)}
        leftIcon={
          resource.icon ? React.createElement(resource.icon) : <DefaultIcon />
        }
      />
    )),
  ];

  return (
    <Root
      className={clsx(
        {
          [MenuClasses.open]: open,
          [MenuClasses.closed]: !open,
        },
        className,
      )}
      {...rest}
      sx={standardMenu.menu}
      style={{
        width: open ? (isSmall ? "230px" : "240px") : "50px",
        alignItems: open ? undefined : "center",
        padding: open
          ? isSmall
            ? "20px 0 30px 15px"
            : "20px 30px 30px 24px"
          : "6px 8px",
        scrollbarWidth: "none",
      }}
    >
      {children}
    </Root>
  );
};
