import React from "react";
import { listTopFilter, StyledList } from "../../../themes/styles";
import {
  Datagrid,
  DateField,
  Filter,
  FilterProps,
  FunctionField,
  TextField,
  useAuthenticated,
} from "react-admin";
import CustomInputWrapper from "../../../components/common/CustomInputWrapper";
import SearchIcon from "@mui/icons-material/Search";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import CustomPagination from "../../../components/common/CustomPagination";
import { IVehicleDetail } from "../../../types/interfaces/admin-parking/vehicle-detail.interface";

const VehiclesListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <CustomInputWrapper
      source="search"
      alwaysOn
      resettable
      startIcon={<SearchIcon color="disabled" fontSize="large" />}
      placeholder="app.auth.search"
    />
  </Filter>
);

const VehiclesListView = () => {
  return (
    <SkeletonLoaderList>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField
          source="plateNumber"
          label="app.parking.plate_number"
          sortable={false}
        />
        <DateField
          source="lastTransactionAt"
          label="app.parking.lastTransactionAt"
        />
        <TextField
          source="transactionsCount"
          label="app.parking.transactionsCount"
          sortable={false}
        />
        <FunctionField
          label="app.parking.vehicles_amount"
          render={(record: IVehicleDetail) => record.registeredVehicles.length}
        />
      </Datagrid>
    </SkeletonLoaderList>
  );
};

const VehiclesListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const VehiclesList = () => {
  useAuthenticated();
  return (
    <StyledList
      className="styled-list"
      filters={<VehiclesListFilter />}
      pagination={<VehiclesListPagination />}
      actions={false}
      exporter={false}
    >
      <VehiclesListView />
    </StyledList>
  );
};

export default VehiclesList;
