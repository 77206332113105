import { AxiosRequestConfig } from "axios";
import { ConfigManager } from "../constants/ConfigManager";
import { apiRequest } from "./client";
import { EModuleType } from "../types/enums/mobile/mobile-module-type.enum";

interface IPostModule {
  name: {
    pl: string;
    en: string;
  };
  type: EModuleType;
  ref: string;
  iconId?: string;
  onboardingPages?: string[];
  position: number;
}

const createMobileModule = async <T>(data: IPostModule) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/mobile/admin/${orgId}/modules`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const updateMobileModule = async <T>(data: IPostModule, moduleId: string) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/mobile/admin/${orgId}/modules/${moduleId}`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const uploadMobileIcon = async <T>(icon: FormData) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/mobile/admin/${orgId}/modules-icons`,
      data: icon,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const mobileModulesService = {
  createMobileModule,
  updateMobileModule,
  uploadMobileIcon,
};
