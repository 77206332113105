import React, { useCallback, useContext, useEffect } from "react";
import { ParkingContext } from "../../../services/parking/parkingContext";
import { useParams } from "react-router-dom";
import PriceListPage from "./PriceListPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import StatutePage from "./StatutePage";
import { OrganizationService } from "../../../api/OrganizationService";
import StaticPageTemplate from "../components/StaticPageTemplate";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import { IOrganization } from "../../../types/interfaces/organization.interface";

const BasePage = () => {
  const { orgId, page } = useParams();

  const { saveCustomerLogo, saveOrganizationInfo } = useContext(ParkingContext);

  const pages = {
    cennik: <PriceListPage />,
    regulamin: <StatutePage />,
    "polityka-prywatnosci": <PrivacyPolicyPage />,
  };

  useEffect(() => {
    fetchCustomerLogo();
    fetchOrgInfo();
  }, []);

  const fetchCustomerLogo = async () => {
    try {
      const customerLogo = await OrganizationService.getOrganizationLogo<Blob>(
        orgId as string,
      );
      saveCustomerLogo(URL.createObjectURL(customerLogo));
    } catch (e) {
      console.log(e);
    }
  };

  const fetchOrgInfo = useCallback(async () => {
    try {
      const { data } = await OrganizationService.getOrganizationInfo<
        IBasicResponse<IOrganization>
      >(orgId as string);
      saveOrganizationInfo(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <StaticPageTemplate>{pages[page as keyof typeof pages]}</StaticPageTemplate>
  );
};

export default BasePage;
