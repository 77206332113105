import React from "react";
import { listTopFilter, StyledList } from "../../../../../themes/styles";
import {
  Datagrid,
  DateField,
  FunctionField,
  RichTextField,
  useTranslate,
} from "react-admin";
import CustomPagination from "../../../../common/CustomPagination";
import { ITicketComment } from "../../../../../types/interfaces/helpdesk/ticket/ticket-comment.interface";
import {
  EResource,
  getResourceUrl,
} from "../../../../../utils/resourcesHelper";

const CommentListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const CommentList = ({ ticketId }: { ticketId: string | undefined }) => {
  const translate = useTranslate();

  const commentsUrl = getResourceUrl(EResource.TICKETS_COMMENT, { ticketId });

  if (!ticketId) {
    return <></>;
  }

  return (
    <StyledList
      resource={commentsUrl}
      className="styled-list styled-list--inner"
      pagination={<CommentListPagination />}
      actions={false}
      empty={<b>{translate("app.helpdesk.empty_ticket_comments_list")}</b>}
    >
      <Datagrid bulkActionButtons={false}>
        <RichTextField
          source="content"
          label="app.helpdesk.content"
          sx={{ margin: 0 }}
        />
        <DateField
          source="createdAt"
          label="app.helpdesk.createdAt"
          showTime={true}
        />
        <FunctionField
          label="app.helpdesk.user"
          render={(record: ITicketComment) => {
            return (
              <span>{`${record.user.firstName} ${record.user.lastName[0]}.`}</span>
            );
          }}
        />
      </Datagrid>
    </StyledList>
  );
};

export default CommentList;
