import React, {
  ComponentType,
  ErrorInfo,
  HtmlHTMLAttributes,
  ReactNode,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import clsx from "clsx";
import { CoreLayoutProps } from "ra-core";
import { standardLayoutStyles, StyledLayout } from "../../themes/styles";
import {
  AppBar as DefaultAppBar,
  AppBarProps,
  Error,
  ErrorProps,
  Menu as DefaultMenu,
  MenuProps,
  SkipNavigationButton,
  useSidebarState,
} from "react-admin";
import CustomSidebar from "./Sidebar";

const CustomLayout = (props: LayoutProps) => {
  const {
    appBar: AppBar = DefaultAppBar,
    children,
    className,
    dashboard,
    error: errorComponent,
    menu: Menu = DefaultMenu,
    title,
    ...rest
  } = props;

  const [open] = useSidebarState();
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>();

  const handleError = (error: Error, info: ErrorInfo) => {
    setErrorInfo(info);
  };

  return (
    <StyledLayout
      className={clsx("layout", className)}
      sx={[
        standardLayoutStyles.layout,
        {
          "& .RaSidebar-fixed": {
            alignSelf: open ? "unset" : "center",
          },
        },
      ]}
      {...rest}
    >
      <SkipNavigationButton />
      <div className={LayoutClasses.appFrame} style={{ marginTop: "70px" }}>
        <AppBar open={open} title={title} />
        <main className={LayoutClasses.contentWithSidebar}>
          <CustomSidebar>
            <Menu hasDashboard={!!dashboard} />
          </CustomSidebar>
          <div id="main-content" className={LayoutClasses.content}>
            <ErrorBoundary
              onError={handleError}
              fallbackRender={({ error, resetErrorBoundary }) => (
                <Error
                  error={error}
                  errorComponent={errorComponent}
                  errorInfo={errorInfo}
                  resetErrorBoundary={resetErrorBoundary}
                  title={title}
                />
              )}
            >
              {children}
            </ErrorBoundary>
          </div>
        </main>
      </div>
    </StyledLayout>
  );
};

export default CustomLayout;

export interface LayoutProps
  extends CoreLayoutProps,
    Omit<HtmlHTMLAttributes<HTMLDivElement>, "title"> {
  appBar?: ComponentType<AppBarProps>;
  className?: string;
  error?: ComponentType<ErrorProps>;
  menu?: ComponentType<MenuProps>;
  sidebar?: ComponentType<{ children: ReactNode }>;
}

const PREFIX = "RaLayout";

export const LayoutClasses = {
  appFrame: `${PREFIX}-appFrame`,
  contentWithSidebar: `${PREFIX}-contentWithSidebar`,
  content: `${PREFIX}-content`,
};
