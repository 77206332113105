import { useEffect } from "react";

const useBeforeUnload = () => {
  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []); // The empty dependency array means this effect runs once on mount and cleans up on unmount
};

export default useBeforeUnload;
