import StatusesList from "./StatusesList";
import StatusesEdit from "./StatusesEdit";
import StatusesCreate from "./StatusesCreate";

const statusesResource = {
  list: StatusesList,
  edit: StatusesEdit,
  create: StatusesCreate,
};

export default statusesResource;
