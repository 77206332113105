import { AxiosRequestConfig } from "axios";
import { ConfigManager } from "../constants/ConfigManager";
import { apiRequest } from "./client";
import { IParkingPayment } from "../types/interfaces/parking/parking-payment.interface";

const getParkingPriceForPlateNumber = async <T>(
  data: {
    plateNumber: string;
    parkingTime: number;
  },
  orgId: string,
) => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/parking/public/${orgId}/price`,
      params: { ...data },
    };
    return await apiRequest<T>(config);
  } catch (e) {
    console.log(e, "getParkingPriceForPlateNumber");
    throw e.response?.data?.error;
  }
};

const getParkingTicketsForPlateNumber = async <T>(
  plateNumber: string,
  orgId: string,
) => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/parking/public/${orgId}/tickets`,
      params: {
        plateNumber,
      },
    };
    return await apiRequest<T>(config);
  } catch (e) {
    console.log(e, "getParkingTicketsForPlateNumber");
    throw e.response?.data?.error;
  }
};

const getParkingPaymentData = async <T>(
  data: IParkingPayment,
  orgId: string,
) => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/parking/public/${orgId}/payment`,
      params: { ...data },
    };
    return await apiRequest<T>(config);
  } catch (e) {
    console.log(e, "getParkingPaymentData");
    throw e.response?.data?.error;
  }
};

const createParkingTicketsForPlateNumber = async <T>(
  data: {
    plateNumber: string;
    validFrom?: number;
  },
  orgId: string,
) => {
  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/parking/public/${orgId}/tickets`,
      data: { ...data },
    };
    return await apiRequest<T>(config);
  } catch (e) {
    console.log(e, "createParkingTicketsForPlateNumber");
    throw e.response?.data?.error;
  }
};

export const ParkingPublicService = {
  getParkingPriceForPlateNumber,
  getParkingPaymentData,
  getParkingTicketsForPlateNumber,
  createParkingTicketsForPlateNumber,
};
