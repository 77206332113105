import React, { ReactNode } from "react";
import { styled } from "@mui/material/styles";

type Props = {
  children: ReactNode;
  variant?: TextInputTypes;
};

export enum TextInputTypes {
  INPUT = "input",
  API_ERROR = "apiError",
}

const getDefaultStyles = () => `
  color: #F05E53;
  marginTop: 5px;
  marginBottom: 10px;
`;

const input = () => "margin-left: 10px;";
const apiError = () => "text-align: center; font-size: 16px";

const variants = {
  input,
  apiError,
};

const ErrorMessage = styled("div")`
  ${() => getDefaultStyles()}
  ${({ variant }: { variant: TextInputTypes }) =>
    variants[variant as keyof typeof variants]}
`;

const ErrorMessageContainer = ({
  children,
  variant = TextInputTypes.INPUT,
}: Props) => {
  return <ErrorMessage variant={variant}>{children}</ErrorMessage>;
};

export default ErrorMessageContainer;
