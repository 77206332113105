import * as React from "react";
import { AppBarProps, Logout } from "react-admin";
import StandardUserMenu from "./StandardUserMenu";
import LogoutIcon from "./icons/LogoutIcon";
import { Theme, useMediaQuery } from "@mui/material";
import { useStyles } from "../themes/styles";
import { standardAppBarStyle } from "../themes/styles";
import { BasicAppBar } from "./BasicAppBar";

const StandardAppBar = (props: AppBarProps) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <BasicAppBar
      userMenu={<StandardUserMenu />}
      position="fixed"
      {...props}
      sx={standardAppBarStyle.appBar}
    >
      {isSmall && <span className={classes.spacer} />}
      {/*<LogoIcon height={100} width={140} />*/}
      <span className={classes.spacer} />

      {!isSmall && (
        <div className={classes.appBarMenu}>
          <div className={classes.appBarMenuItem}>{/*<LocaleSwitcher />*/}</div>
          <div className={classes.appBarMenuItem}>
            <Logout icon={<LogoutIcon />} sx={standardAppBarStyle.logout} />
          </div>
        </div>
      )}
    </BasicAppBar>
  );
};

export default StandardAppBar;
