import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@mui/material";
import {
  Button,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import { buttons, StyledFileInput } from "../../../../themes/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomAttachmentField from "../../../forms/CustomAttachmentField";
import { helpdeskTicketsService } from "../../../../api/HelpdeskTicketsService";
import { IBasicResponse } from "../../../../types/interfaces/response/basic-response.interface";
import IconSave from "@mui/icons-material/Save";
import IconCancel from "@mui/icons-material/Cancel";
import DialogActions from "@mui/material/DialogActions";
import { ITicket } from "../../../../types/interfaces/helpdesk/ticket/ticket.interface";
import { uploadedAttachmentFormattedRejectionReason } from "../../../../utils/ticket/ticketsHelper";
import { FileRejection } from "react-dropzone";
import useHelpdeskSettings from "../../../../hooks/useHelpdeskSettings";
import { getTranslatedFileInputPlaceholder } from "../../../../utils/languageService";

type Props = {
  isOpen: boolean;
  close: () => void;
};

const AddAttachmentDialog = ({ isOpen, close }: Props) => {
  const [uploadedAttachment, setUploadedAttachment] = useState<{
    attachmentId: string;
  } | null>(null);

  const ticket = useRecordContext<ITicket>();
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { settings } = useHelpdeskSettings();

  const handleSubmit = async () => {
    try {
      if (uploadedAttachment) {
        await helpdeskTicketsService.uploadAttachment(
          uploadedAttachment,
          ticket.id,
        );
        notify(translate("app.helpdesk.comment_added"), { type: "success" });
      }
      refresh();
      close();
    } catch (e) {
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        { type: "error" },
      );
    }
  };

  const uploadFiles = async (files: File[]) => {
    for (const fileItem of files) {
      const formData = new FormData();
      formData.append("attachment", fileItem);

      try {
        const { data } =
          await helpdeskTicketsService.uploadTemporaryAttachment<
            IBasicResponse<{ attachmentId: string }>
          >(formData);
        const attachment = {
          attachmentId: data.attachmentId,
        };
        setUploadedAttachment(attachment);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleFileRejections = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      const message =
        uploadedAttachmentFormattedRejectionReason(fileRejections);

      notify(message, { type: "error" });
    }
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      transitionDuration={200}
      onClose={() => close()}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {translate("app.helpdesk.add_attachment")}
      </DialogTitle>
      <Form key="add-attachment-form" onSubmit={() => handleSubmit()}>
        <DialogContent>
          <StyledFileInput
            source="newAttachments"
            label="Attachments"
            defaultValue={[]}
            accept={settings.attachments.acceptTypes}
            multiple={false}
            maxSize={settings.attachments.limits.maxSize}
            removeIcon={DeleteIcon}
            placeholder={
              <p>
                {getTranslatedFileInputPlaceholder(
                  settings.attachments.limits.maxSize / 1000000,
                )}
              </p>
            }
            options={{
              onDrop: (files: File[]) => uploadFiles(files),
              onDropRejected: (fileRejections: FileRejection[]) =>
                handleFileRejections(fileRejections),
            }}
          >
            <CustomAttachmentField />
          </StyledFileInput>
        </DialogContent>

        <DialogActions
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Button label="ra.action.save" type="submit" sx={buttons.darkButton}>
            <IconSave />
          </Button>
          <Button
            label="ra.action.cancel"
            onClick={() => close()}
            sx={buttons.whiteButton}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default AddAttachmentDialog;
