import React, { useContext, useMemo, useState } from "react";
import { Box, CircularProgress, FormControl } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Formik } from "formik";
import {
  CustomButton,
  CustomFormLabel,
  FormTextInput,
} from "../../../themes/styles";
import ErrorMessageContainer, {
  TextInputTypes,
} from "../../../components/errors/ErrorContainer";
import { plateNumberScheme } from "../../../utils/validationSchemes";
import { ParkingContext } from "../../../services/parking/parkingContext";
import { useTranslate } from "react-admin";
import { StepInnerContainer, TitleSection } from "./styles";
import { styled } from "@mui/material/styles";
import { ParkingPublicService } from "../../../api/ParkingPublicService";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import { IParkingTicket } from "../../../types/interfaces/parking/parking-ticket.interface";
import { PaymentProcessStep } from "../../../types/enums/payment-process-step.enum";

const Container = styled(Box)(() => ({
  margin: "30px 0",
  fontSize: "14px",
}));

const StepOne = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { savePlateNumber, saveCurrentStep, organizationInfo } =
    useContext(ParkingContext);

  const { orgId } = useParams();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const freeHours = useMemo(() => {
    if (!organizationInfo) return null;

    switch (organizationInfo.name) {
      case "zaspa":
        return 1.5;
      default:
        return 3;
    }
  }, [organizationInfo]);

  const fetchTicketListAndReturnActiveTicket = async (plateNumber: string) => {
    const { data } = await ParkingPublicService.getParkingTicketsForPlateNumber<
      IBasicResponse<IParkingTicket[]>
    >(plateNumber, orgId as string);

    if (data.length === 0) {
      return null;
    }

    const activeTicket = data.find((item) => item.status === "active");

    return activeTicket ?? null;
  };

  const handleStepOneSubmit = async ({
    plateNumber,
  }: {
    plateNumber: string;
  }) => {
    try {
      savePlateNumber(plateNumber);
      setErrorMessage("");
      setIsLoading(true);

      const activeTicket =
        await fetchTicketListAndReturnActiveTicket(plateNumber);

      // Ticker already exists but not paid yet
      if (activeTicket && !activeTicket?.validTo) {
        saveCurrentStep(PaymentProcessStep.STEP_SECOND);
        navigate(`/parking/prepaid/${orgId}/steps/2`);
        return;
      }

      /* const newTicketData: { plateNumber: string; validFrom?: number } = {
        plateNumber,
      };

      // Generate start time for new parking ticker
      if (activeTicket && activeTicket?.validTo) {
        newTicketData["validFrom"] = activeTicket?.validTo + 1;
        saveActiveTicketValidToDate(activeTicket.validTo);
      }

      await ParkingPublicService.createParkingTicketsForPlateNumber(
        newTicketData,
        orgId as string,
      ); */

      saveCurrentStep(PaymentProcessStep.STEP_SECOND);
      setIsLoading(false);
      navigate(`/parking/prepaid/${orgId}/steps/2`);
      return;
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(
        e?.translatedMessage || translate("app.notifications.error.undefined"),
      );
    }
  };

  return (
    <>
      <TitleSection>
        <Box>
          {organizationInfo?.displayName && (
            <h2>
              {translate("app.parking.prepaid.welcome_message", {
                name: organizationInfo.displayName,
              })}
            </h2>
          )}
        </Box>
        <Box data-testid="prepaidPageStepOneTitle">
          {freeHours &&
            translate("app.parking.prepaid.promo_message", {
              hours: freeHours,
            })}
        </Box>
      </TitleSection>
      <StepInnerContainer style={{ marginBottom: "20px" }}>
        <Formik
          initialValues={{ plateNumber: "" }}
          onSubmit={handleStepOneSubmit}
          enableReinitialize
          validationSchema={plateNumberScheme}
        >
          {({
            touched,
            handleSubmit,
            values,
            setFieldValue,
            errors,
            setFieldTouched,
          }) => (
            <Box>
              <FormControl style={{ width: "100%" }}>
                <CustomFormLabel>
                  {translate("app.parking.plate_number")}
                </CustomFormLabel>
                <Field>
                  {() => (
                    <FormTextInput
                      inputProps={{ "data-testid": "plateNumber" }}
                      value={values.plateNumber}
                      onBlur={() => setFieldTouched("plateNumber")}
                      onChange={(e) =>
                        setFieldValue(
                          "plateNumber",
                          e.target.value
                            .toUpperCase()
                            .replaceAll(/[^A-Z0-9]+/gi, ""),
                        )
                      }
                    />
                  )}
                </Field>
              </FormControl>

              {errors.plateNumber && touched.plateNumber && (
                <div data-testid="plateNumberError">
                  <ErrorMessageContainer>
                    {errors.plateNumber}
                  </ErrorMessageContainer>
                </div>
              )}

              {errorMessage && (
                <ErrorMessageContainer variant={TextInputTypes.API_ERROR}>
                  {errorMessage}
                </ErrorMessageContainer>
              )}

              <CustomButton
                style={{ width: "100%", fontSize: "16px", marginTop: "20px" }}
                aria-label="submitStepOneForm"
                disabled={Object.values(values).includes("") || isLoading}
                onClick={() => handleSubmit()}
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  translate("app.parking.prepaid.register")
                )}
              </CustomButton>
            </Box>
          )}
        </Formik>
        <Container>
          {translate("app.parking.prepaid.accept_text")}{" "}
          <Link
            to={`/parking/prepaid/${orgId}/page/regulamin`}
            style={{ color: "#0A0A0A" }}
          >
            {translate("app.parking.prepaid.accept_text_parking_regulations")}
          </Link>{" "}
          {translate("app.parking.prepaid.accept_text_and")}{" "}
          <Link
            to={`/parking/prepaid/${orgId}/page/polityka-prywatnosci`}
            style={{ color: "#0A0A0A" }}
          >
            {translate("app.parking.prepaid.accept_text_privacy_policy")}
          </Link>
          .
        </Container>
      </StepInnerContainer>
    </>
  );
};

export default StepOne;
