import { useEffect, useState } from "react";
import { adminParkingService } from "../api/AdminParkingService";
import { IBasicResponse } from "../types/interfaces/response/basic-response.interface";
import { IConfigResponse } from "../types/interfaces/config-response.interface";
import { fetchConfig } from "../utils/config";
import { SettingsManager } from "../constants/SettingsManager";

interface IEnabled {
  enabled: boolean;
}

interface IParkingTicketSettings extends IEnabled {
  management: {
    enabled: boolean;
    allowedActions: string[];
    allowedForManagers: boolean;
    limits: {
      extend: {
        maxMinutesOnce: number;
        maxMinutesPerVehicle: {
          perDay: number;
        };
        maxCountPerVehicle: {
          perDay: number;
          perWeek: number;
        };
      };
    };
  };
}

export type TParkingSettings = {
  occupancy: IEnabled;
  pricing: IEnabled;
  tickets: IParkingTicketSettings;
  transactions: IEnabled;
  vehicles: IEnabled;
  reservations: {
    enabled: boolean;
    limits: {
      maxDaysToReserveAhead: number;
      maxSlotsConcurrency: number;
      maxSlotsPerUserConcurrency: number;
      maxReservationDays: number;
    };
  };
};

const defaultSettings: TParkingSettings = {
  occupancy: {
    enabled: true,
  },
  pricing: {
    enabled: true,
  },
  tickets: {
    enabled: true,
    management: {
      enabled: true,
      allowedActions: ["extend"],
      allowedForManagers: true,
      limits: {
        extend: {
          maxMinutesOnce: 60,
          maxMinutesPerVehicle: {
            perDay: 60,
          },
          maxCountPerVehicle: {
            perDay: 1,
            perWeek: -1,
          },
        },
      },
    },
  },
  transactions: {
    enabled: true,
  },
  vehicles: {
    enabled: true,
  },
  reservations: {
    enabled: false,
    limits: {
      maxDaysToReserveAhead: 90,
      maxSlotsConcurrency: 10,
      maxSlotsPerUserConcurrency: 10,
      maxReservationDays: 10,
    },
  },
};

const useHelpdeskSettings = (orgId?: string) => {
  const [settings, setSettings] = useState<TParkingSettings>(defaultSettings);

  useEffect(() => {
    if (localStorage.getItem("orgId")) {
      assignSettings();
    }
  }, [orgId]);

  const fetchSettings = async () => {
    try {
      const { api_url }: IConfigResponse = await fetchConfig();
      const { data } =
        await adminParkingService.getParkingSettings<
          IBasicResponse<TParkingSettings>
        >(api_url);

      return data;
    } catch (e) {
      return defaultSettings;
    }
  };

  const assignSettings = async () => {
    let parkingSettings = SettingsManager.getInstance().getParkingSettings();

    if (!parkingSettings) {
      parkingSettings = await fetchSettings();
    }

    SettingsManager.getInstance().setParkingSettings(parkingSettings);
    setSettings(parkingSettings);
  };

  return { settings };
};

export default useHelpdeskSettings;
