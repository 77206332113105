import * as React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import { RefreshIconButton } from "react-admin";

const useStyles = makeStyles(
  {
    loader: {
      margin: 14,
    },
    loadedIcon: {
      color: "#606060",
    },
  },
  { name: "RaLoadingIndicator" },
);

const LoadingIndicator = (props: any) => {
  const classes = useStyles(props);

  return <RefreshIconButton className={classes.loadedIcon} />;
};

LoadingIndicator.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default LoadingIndicator;
