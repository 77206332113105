import React from "react";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { CustomImage } from "../../../../../resources/helpdesk/tickets/TicketsShow";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const GalleryWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
  flexWrap: "wrap",
}));

const TicketImagesGallery = ({ imagesList }: { imagesList: CustomImage[] }) => {
  return (
    <GalleryWrapper>
      <Gallery>
        {imagesList.map((imageItem: CustomImage, index: number) => {
          return (
            <Item
              content={
                <img
                  crossOrigin="anonymous"
                  style={{ cursor: "pointer" }}
                  src={imageItem.src}
                  height={imageItem.height}
                />
              }
              original={imageItem.original}
              thumbnail={imageItem.original}
              height={imageItem.height}
              width={imageItem.width}
              key={`image${index}`}
            >
              {({ ref, open }) => (
                <img
                  crossOrigin="anonymous"
                  style={{ cursor: "pointer" }}
                  ref={ref}
                  onClick={open}
                  src={imageItem.src}
                  height={imageItem.height}
                />
              )}
            </Item>
          );
        })}
      </Gallery>
    </GalleryWrapper>
  );
};

export default TicketImagesGallery;
