import { Roles } from "../types/enums/roles";
import { ConfigManager } from "../constants/ConfigManager";

export enum EResource {
  ATTRIBUTES = "attributes",
  TICKETS = "tickets",
  TICKETS_COMMENT = "ticketComments",
  TICKETS_HISTORY = "ticketHistory",
  UPDATE_TICKET_ATTRIBUTES = "updateTicketAttributes",
  TICKET_ATTACHMENTS = "ticketAttachments",
  TICKET_TEMPORARY_ATTACHMENT = "ticketTemporaryAttachment",
}

interface UrlParams {
  [key: string]: string | undefined;
}

export const getResourceUrl = (resource: EResource, params: UrlParams = {}) => {
  params.organization = ConfigManager.getInstance().getOrgId() as string;

  const permissions = getPermissions();

  const isAdmin = permissions.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN);

  const url = urls[resource];

  // populate specific resource with provided params
  const populatedUrl = { ...url };

  if (params) {
    for (const param in params) {
      populatedUrl.admin = populatedUrl.admin.replace(
        `{${param}}`,
        String(params[param]),
      );
      populatedUrl.user = populatedUrl.user.replace(
        `{${param}}`,
        String(params[param]),
      );
    }
  }

  return populatedUrl[isAdmin ? "admin" : "user"];
};

const getPermissions = () => {
  const roles = localStorage.getItem("roles");

  return roles ? JSON.parse(roles) : [];
};

export const urls = {
  attributes: {
    admin: "helpdesk/admin/{organization}/attributes",
    user: "helpdesk/attributes",
  },
  updateTicketAttributes: {
    admin:
      "helpdesk/admin/{organization}/tickets/{ticketId}/attributes/{attributeId}",
    user: "helpdesk/tickets/{ticketId}/attributes/{attributeId}",
  },
  tickets: {
    admin: "helpdesk/admin/{organization}/tickets",
    user: "helpdesk/tickets",
  },
  ticketComments: {
    admin: "helpdesk/admin/{organization}/tickets/{ticketId}/comments",
    user: "helpdesk/tickets/{ticketId}/comments",
  },
  ticketHistory: {
    admin: "helpdesk/admin/{organization}/tickets/{ticketId}/history",
    user: "helpdesk/tickets/{ticketId}/history",
  },
  ticketAttachments: {
    admin: "helpdesk/admin/{organization}/tickets/{ticketId}/attachments",
    user: "helpdesk/tickets/{ticketId}/attachments",
  },
  ticketTemporaryAttachment: {
    admin: "helpdesk/admin/{organization}/ticket-temporary-attachments",
    user: "helpdesk/ticket-temporary-attachments",
  },
};
