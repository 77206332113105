import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Field, Formik } from "formik";
import { CircularProgress, FormControl } from "@mui/material";
import {
  CustomButton,
  CustomFormLabel,
  CustomLightButton,
} from "../../../themes/styles";
import { Identifier, useNotify, useTranslate } from "react-admin";
import PasswordInput from "../../../components/forms/PasswordInput";
import { getPasswordBackground } from "../../../utils/passwordService";
import ErrorMessageContainer from "../../../components/errors/ErrorContainer";
import { resetScheme } from "../../../utils/validationSchemes";
import { AdminService } from "../../../api/AdminService";

const ResetPasswordDialog = ({
  open,
  close,
  userId,
}: {
  open: boolean;
  close: () => void;
  userId: Identifier;
}) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const resetPasswordAction = async (data: { password: string }) => {
    setIsLoading(true);
    try {
      await AdminService.resetUserPassword(data, userId);
      close();
    } catch (e) {
      console.log(e);
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog fullWidth open={open} transitionDuration={400}>
      <Formik
        initialValues={{ password: "" }}
        onSubmit={resetPasswordAction}
        validationSchema={resetScheme}
      >
        {({
          handleSubmit,
          errors,
          touched,
          setFieldTouched,
          values,
          handleChange,
        }) => (
          <DialogContent>
            <FormControl style={{ display: "flex" }}>
              <CustomFormLabel>
                {translate("app.auth.login.password")}
              </CustomFormLabel>
              <Field>
                {() => (
                  <PasswordInput
                    value={values.password}
                    onChange={handleChange("password")}
                    onBlur={() => setFieldTouched("password")}
                    style={{
                      backgroundColor: getPasswordBackground(
                        !errors.password,
                        touched?.password,
                      ),
                    }}
                  />
                )}
              </Field>
            </FormControl>

            {errors.password && touched.password && (
              <ErrorMessageContainer>{errors.password}</ErrorMessageContainer>
            )}

            <DialogActions sx={{ justifyContent: "space-between" }}>
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <CustomButton
                  disabled={Object.values(values).includes("")}
                  style={{
                    marginTop: "7px",
                  }}
                  onClick={() => handleSubmit()}
                >
                  {translate("app.auth.next")}
                </CustomButton>
              )}
              <CustomLightButton onClick={() => close()}>
                {translate("app.system.system_cancel")}
              </CustomLightButton>
            </DialogActions>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
};

export default ResetPasswordDialog;
