import React from "react";
import { Box } from "@mui/material";
import CreatorLogo from "../../../components/icons/CreatorLogo";
import { styled } from "@mui/material/styles";

const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingBottom: "40px",
  },
}));

const Title = styled(Box)(() => ({
  fontSize: "10px",
  marginBottom: "5px",
  textAlign: "center",
}));

const Footer = () => {
  return (
    <Container>
      <Title>Powered by</Title>
      <a href="https://yourbuildingapp.com" target="_blank">
        <CreatorLogo />
      </a>
    </Container>
  );
};

export default Footer;
