import { ConfigManager } from "../constants/ConfigManager";
import { AxiosRequestConfig } from "axios";
import { apiRequest } from "./client";
import { EActiveState } from "../types/enums/active-state.enum";

const getWorkflow = async <T>(workflowId: string | undefined) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/admin/${orgId}/workflows/${workflowId}`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const getWorkflowList = async <T>(params: { active: EActiveState }) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/admin/${orgId}/workflows/`,
      params,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const helpdeskWorkflowService = {
  getWorkflow,
  getWorkflowList,
};
