export const removeEmptyValues = <T>(obj: Record<string, any>): T => {
  return Object.entries(obj).reduce(
    (a, [k, v]) => (v ? { ...a, [k]: v } : a),
    {},
  ) as T;
};

export const removeNullValues = (obj: any) => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      if (value === null) {
        return undefined;
      }

      return value;
    }),
  );
};
