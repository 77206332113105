import React from "react";
import { TitleSection } from "./styles";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslate } from "react-admin";
import BasicPaymentComponent from "../components/payment/BasicPaymentComponent";
import useOnPageLoad from "../../../hooks/useOnPageLoad";

const Container = styled(Box)(({ theme }) => ({
  height: "60vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    height: "unset",
  },

  [theme.breakpoints.up("md")]: {
    marginBottom: "100px",
  },
}));

const TitleContainer = styled(Box)(() => ({
  marginTop: "20px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

const StepPayment = () => {
  const translate = useTranslate();

  useOnPageLoad();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md"),
  );

  return (
    <Container>
      <TitleContainer>
        <TitleSection>
          {isMobile ? (
            translate("app.parking.prepaid.make_a_payment")
          ) : (
            <>
              <Box style={{ marginBottom: "5px" }}>
                {translate("app.parking.prepaid.sms_step_title")}
              </Box>
              <Box data-testid="paymentPageTitle">
                {translate("app.parking.prepaid.sms_step_subtitle")}
              </Box>
            </>
          )}
        </TitleSection>
      </TitleContainer>
      <BasicPaymentComponent />
    </Container>
  );
};

export default StepPayment;
