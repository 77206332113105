import * as React from "react";

const UserIcon = ({ size = 18, color = "#606060", style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      style={style}
      viewBox="0 0 14.545 16"
    >
      <path
        id="icons8-user"
        d="M10.273,2a4.364,4.364,0,1,0,4.364,4.364A4.372,4.372,0,0,0,10.273,2Zm0,8.727A7.287,7.287,0,0,0,3,18H4.455a5.818,5.818,0,0,1,11.636,0h1.455A7.287,7.287,0,0,0,10.273,10.727Zm0-7.273A2.909,2.909,0,1,1,7.364,6.364,2.9,2.9,0,0,1,10.273,3.455Z"
        transform="translate(-3 -2)"
        fill={color}
      />
    </svg>
  );
};

export default UserIcon;
