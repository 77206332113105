import OnboardingPagesEdit from "./ModuleOnboardingPagesEdit";
import OnboardingPagesCreate from "./ModuleOnboardingPagesCreate";

const moduleOnboardingPagesResource = {
  create: OnboardingPagesCreate,
};

export const moduleOnboardingPagesResource1 = {
  edit: OnboardingPagesEdit,
};
export default moduleOnboardingPagesResource;
