export const getPasswordBackground = (
  isValid: boolean,
  isTouched: boolean | undefined,
): string => {
  if (!isTouched) {
    return "#EEEEEE";
  }

  if (isValid) {
    return "#daecd8";
  }

  return "#fbcdcd";
};
