import React, { useContext, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { MobileButton, MobileLightButton } from "../../prepaid/styles";
import { Box } from "@mui/material";
import { useTranslate } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import { ParkingContext } from "../../../../services/parking/parkingContext";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import ErrorMessageContainer from "../../../../components/errors/ErrorContainer";
import { styled } from "@mui/material/styles";
import { PaymentProcessStep } from "../../../../types/enums/payment-process-step.enum";

const Container = styled(Box)(() => ({
  width: "100%",
  marginBottom: "40px",
  textAlign: "center",
}));

const PaymentElementContainer = styled(Box)(() => ({
  margin: "30px 0",
}));

const ErrorContainer = styled(Box)(() => ({
  margin: "20px 0",
}));

const StripePaymentElement = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const { orgId } = useParams();

  const { user, saveCurrentStep } = useContext(ParkingContext);

  const [paymentErrorMessage, setPaymentErrorMessage] = useState<
    string | undefined
  >("");

  const handlePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/#/parking/prepaid/${orgId}/steps/sms-step`,
      },
    });

    setPaymentErrorMessage(error.message);
  };

  const options: StripePaymentElementOptions = {
    layout: "tabs",
    defaultValues: {
      billingDetails: {
        name: user.firstName + " " + user.lastName,
        phone: user.phoneNumber,
        email: user.email,
      },
    },
  };

  const stepBack = () => {
    saveCurrentStep(PaymentProcessStep.STEP_FORM);
    navigate(-1);
    return;
  };

  return (
    <Container>
      <PaymentElementContainer>
        <PaymentElement id="payment-element" options={options} />
      </PaymentElementContainer>
      {paymentErrorMessage && (
        <ErrorContainer>
          <ErrorMessageContainer>{paymentErrorMessage}</ErrorMessageContainer>
        </ErrorContainer>
      )}
      <MobileButton style={{ width: "100%" }} onClick={() => handlePayment()}>
        {translate("app.parking.prepaid.pay")}
      </MobileButton>
      <MobileLightButton
        style={{ width: "100%", marginTop: "15px" }}
        onClick={() => stepBack()}
      >
        {translate("app.auth.back")}
      </MobileLightButton>
    </Container>
  );
};

export default StripePaymentElement;
