import * as React from "react";
import {
  ChangeEvent,
  FC,
  memo,
  ReactElement,
  useCallback,
  useMemo,
} from "react";
import {
  TablePagination,
  TablePaginationBaseProps,
  Theme,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import {
  ListPaginationContextValue,
  sanitizeListRestProps,
  useListPaginationContext,
  useTranslate,
} from "ra-core";

import { PaginationActions, PaginationLimit } from "react-admin";
import { LabelDisplayedRowsArgs } from "@mui/material/TablePagination/TablePagination";

const CustomPagination: FC<PaginationProps> = memo((props) => {
  const {
    rowsPerPageOptions = DefaultRowsPerPageOptions,
    actions,
    limit = DefaultLimit,
    ...rest
  } = props;
  const { isLoading, hasNextPage, page, perPage, total, setPage, setPerPage } =
    useListPaginationContext(props);
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const totalPages = useMemo(() => {
    return total != null ? Math.ceil(total / perPage) : undefined;
  }, [perPage, total]);

  /**
   * Warning: MUI's page is 0-based
   */
  const handlePageChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      event && event.stopPropagation();
      if (totalPages) {
        if (page < 0 || page > totalPages - 1) {
          throw new Error(
            translate("ra.navigation.page_out_of_boundaries", {
              page: page + 1,
            }),
          );
        }
        setPage(page + 1);
      }
    },
    [totalPages, setPage, translate],
  );

  const handlePerPageChange = useCallback(
    (event: ChangeEvent<{ value: number | string }>) => {
      setPerPage(event?.target?.value as number);
    },
    [setPerPage],
  );

  const labelDisplayedRows = useCallback(
    ({ from, to, count }: LabelDisplayedRowsArgs) =>
      count === -1 && hasNextPage
        ? translate("ra.navigation.partial_page_range_info", {
            offsetBegin: from,
            offsetEnd: to,
            _: `%{from}-%{to} of more than %{to}`,
          })
        : translate("ra.navigation.page_range_info", {
            offsetBegin: from,
            offsetEnd: to,
            total: count === -1 ? to : count,
            _: `%{from}-%{to} of %{count === -1 ? to : count}`,
          }),
    [translate, hasNextPage],
  );

  const labelItem = useCallback(
    (type: "first" | "last" | "next" | "previous") =>
      translate(`ra.navigation.${type}`, { _: `Go to ${type} page` }),
    [translate],
  );

  if (isLoading) {
    return <Toolbar variant="dense" />;
  }

  // Avoid rendering TablePagination if "page" value is invalid
  if (
    totalPages &&
    (total === 0 || page < 1 || (total != null && page > totalPages))
  ) {
    return limit;
  }

  if (isSmall) {
    return (
      <TablePagination
        className="pagination-list"
        count={total == null ? -1 : total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePerPageChange}
        rowsPerPageOptions={rowsPerPageOptions}
        component="span"
        labelDisplayedRows={labelDisplayedRows}
        {...sanitizeListRestProps(rest)}
      />
    );
  }

  const ActionsComponent = actions
    ? actions // overridden by caller
    : !isLoading && total != null
    ? PaginationActions // regular navigation
    : undefined; // partial navigation (uses default TablePaginationActions)

  return (
    <TablePagination
      count={total == null ? -1 : total}
      rowsPerPage={perPage}
      page={page - 1}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handlePerPageChange}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ActionsComponent={ActionsComponent}
      nextIconButtonProps={{
        disabled: !hasNextPage,
      }}
      component="span"
      labelRowsPerPage={translate("ra.navigation.page_rows_per_page")}
      labelDisplayedRows={labelDisplayedRows}
      getItemAriaLabel={labelItem}
      rowsPerPageOptions={rowsPerPageOptions}
      {...sanitizeListRestProps(rest)}
    />
  );
});

const DefaultLimit = <PaginationLimit />;
const DefaultRowsPerPageOptions = [5, 10, 25];

export default CustomPagination;

export interface PaginationProps
  extends TablePaginationBaseProps,
    Partial<ListPaginationContextValue> {
  rowsPerPageOptions?: number[];
  actions?: FC;
  limit?: ReactElement;
}
