import React, { useCallback, useMemo, useRef, useState } from "react";
import { useLogin, useNotify, useTranslate } from "react-admin";
import { Box, ButtonBase } from "@mui/material";
import {
  AuthPageContainer,
  LoginFormContainer,
  LoginFormTitle,
} from "../../themes/styles";
import { FormikProps } from "formik";
import LogoIcon from "../icons/LogoIcon";
import { UnauthenticatedTopic } from "../../types/enums/unauthenticated-topic.enum";
import { CodeService } from "../../api/CodeService";
import { AuthService } from "../../api/AuthService";
import ResetPasswordForm from "./ResetPasswordForm";
import LoginPageForm from "./LoginPageForm";

interface FormValues {
  email: string;
  password: string;
  orgId: string;
}

const LoginPage = () => {
  const login = useLogin();
  const [resetPasswordProcess, setResetPasswordProcess] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetPasswordData, setResetPasswordData] = useState<{
    email: string;
    password: string;
    orgId: string;
  }>({ email: "", password: "", orgId: "" });
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const translate = useTranslate();
  const notify = useNotify();
  const formikRef = useRef<FormikProps<FormValues> | null>(null);

  const handleLogin = useCallback(
    ({
      email,
      password,
      orgId,
    }: {
      email: string;
      password: string;
      orgId: string;
    }) => {
      login({ email, password, orgId }).catch((e) => {
        notify(e, { type: "error" });
      });
      // fetch org data
    },
    [login, notify],
  );

  const handleResetPassword = async (data: {
    email: string;
    password: string;
    orgId: string;
  }) => {
    setIsLoading(true);
    const newData = {
      email: data.email,
      topic: UnauthenticatedTopic.RESET_PASSWORD,
      groupId: data.orgId,
    };
    try {
      await CodeService.unauthenticatedUser(newData);
      setResetPasswordData(data);
      setShowConfirmation(true);
    } catch (e) {
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
          autoHideDuration: 100000,
        },
      );
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onCodeResend = useCallback(async () => {
    try {
      await CodeService.unauthenticatedUser({
        email: resetPasswordData.email,
        topic: UnauthenticatedTopic.RESET_PASSWORD,
        groupId: resetPasswordData.orgId,
      });
    } catch (e) {
      console.log(e);
    }
  }, [resetPasswordData.email, resetPasswordData.orgId]);

  const resetFormState = () => {
    if (formikRef.current) {
      formikRef.current.setValues({
        email: "",
        password: "",
        orgId: "",
      });
      formikRef.current.setErrors({
        email: undefined,
        password: undefined,
        orgId: undefined,
      });
      formikRef.current.setTouched({
        email: false,
        password: false,
        orgId: false,
      });
    }
  };

  const onCodeSubmit = async (data: { code: string }) => {
    const newData = {
      ...data,
      ...resetPasswordData,
      groupId: localStorage.getItem("orgId") as string,
    };
    try {
      setIsLoading(true);
      await AuthService.resetPassword(newData);
      setShowConfirmation(false);
      setResetPasswordProcess(false);
      resetFormState();
      notify(translate("app.notifications.success.password_changed"), {
        type: "success",
      });
    } catch (e) {
      console.log(e);
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResettingProcess = useCallback(() => {
    resetFormState();
    setResetPasswordProcess(!resetPasswordProcess);
  }, [resetPasswordProcess]);

  const formTitle = useMemo(() => {
    if (showConfirmation) {
      return "app.auth.login.confirm_changes";
    }

    if (resetPasswordProcess) {
      return "app.auth.login.reset_password";
    }

    return "app.auth.login.title";
  }, [resetPasswordProcess, showConfirmation]);

  return (
    <AuthPageContainer>
      <LogoIcon width={110} height={60} />
      <LoginFormContainer>
        <>
          <div data-testid="formTitle">
            <LoginFormTitle>{translate(formTitle)}</LoginFormTitle>
          </div>

          {showConfirmation ? (
            <>
              <ResetPasswordForm
                onCodeSubmit={onCodeSubmit}
                onCodeResend={onCodeResend}
                isLoading={isLoading}
              />
            </>
          ) : (
            <>
              <LoginPageForm
                isLoading={isLoading}
                resetPasswordProcess={resetPasswordProcess}
                handleLogin={handleLogin}
                handleResetPassword={handleResetPassword}
                formikRef={formikRef}
              />

              <Box style={{ textAlign: "center" }} mt={1}>
                <ButtonBase
                  aria-label="resetPasswordButton"
                  onClick={() => handleResettingProcess()}
                  style={{
                    fontSize: "14px",
                    color: "#848484",
                  }}
                >
                  {resetPasswordProcess
                    ? "Login"
                    : translate("app.auth.login.forgot_password")}
                </ButtonBase>
              </Box>
            </>
          )}
        </>
      </LoginFormContainer>
    </AuthPageContainer>
  );
};

export default LoginPage;
