import * as React from "react";
import { ReactElement } from "react";
import { InputAdornment } from "@mui/material";
import { useTranslate } from "ra-core";
import { StyledTextInput } from "../../themes/styles";
import { TextInputProps } from "react-admin";

interface ICustomTextInput {
  startIcon?: ReactElement;
}

const CustomTextInput = (
  props: Omit<TextInputProps, "sx"> & ICustomTextInput,
) => {
  const translate = useTranslate();

  if (props.label) {
    throw new Error(
      "<SearchInput> isn't designed to be used with a label prop. Use <TextInput> if you need a label.",
    );
  }
  const styledTextProps = props as Omit<TextInputProps, "sx">;
  return (
    <StyledTextInput
      hiddenLabel
      label=""
      resettable
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {props.startIcon || ""}
          </InputAdornment>
        ),
        placeholder: translate(props.placeholder as string),
      }}
      size="small"
      variant="outlined"
      {...styledTextProps}
    />
  );
};

export default CustomTextInput;
