import React from "react";
import {
  ArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  minValue,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  TranslatableInputs,
  useAuthenticated,
  useRecordContext,
  useRedirect,
  useUpdate,
} from "react-admin";
import {
  StyledColorInput,
  StyledDeleteButton,
  StyledNumberInput,
  StyledSaveButton,
  StyledSelect,
  StyledTextInput,
} from "../../../themes/styles";
import { attributeTypes } from "../../../utils/attributeTypes";
import { AllowedAttributeType } from "../../../types/enums/ticket-attribute-type.enums";
import { Toolbar, ToolbarProps } from "@mui/material";
import { TicketStatusType } from "../../../types/enums/ticket-status-type.enums";
import { ITicketAttributeOption } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import { removeNullValues } from "../../../utils/objectService";
import { ITicketAttribute } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";

const AttributesEditToolbar = (props: ToolbarProps) => {
  const record = useRecordContext<ITicketAttribute>();

  return (
    <Toolbar
      {...props}
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <StyledSaveButton />
      <StyledDeleteButton disabled={!record.isRemovable} />
    </Toolbar>
  );
};

const AttributesEdit = () => {
  useAuthenticated();

  const [update] = useUpdate();
  const redirect = useRedirect();
  const save = async (data: Record<string, any>) => {
    data.active = TicketStatusType.ACTIVE;

    if (data?.options) {
      data.options = data.options.map(
        (item: ITicketAttributeOption, index: number) => {
          return { ...item, position: index };
        },
      );
    }

    try {
      update("helpdesk/admin/organization/attributes", {
        id: data.id,
        data: removeNullValues(data),
      }).then(() => {
        redirect("list", "helpdesk/admin/organization/attributes");
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm toolbar={<AttributesEditToolbar />} onSubmit={save}>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="name"
            label="app.auth.name"
            validate={required()}
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>

        <StyledSelect
          source="type"
          label="app.helpdesk.type"
          choices={attributeTypes}
          validate={required()}
          variant="outlined"
          sx={{ width: "200px", marginTop: "15px" }}
        />
        <StyledNumberInput
          fullWidth
          source="position"
          label="app.helpdesk.position"
          variant="outlined"
          sx={{ width: "200px", marginTop: "20px" }}
          validate={[minValue(0)]}
        />
        <BooleanInput
          name="isOptional"
          source="isOptional"
          label="app.helpdesk.isOptional"
        />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.type === AllowedAttributeType.SingleSelect) {
              return (
                <>
                  <BooleanInput
                    name="isSavingNewValues"
                    source="isSavingNewValues"
                    label="app.helpdesk.isSavingNewValues"
                  />

                  <ArrayInput
                    source="options"
                    label="app.helpdesk.options"
                    validate={required()}
                  >
                    <SimpleFormIterator inline>
                      <TextInput
                        variant="outlined"
                        source="value.pl"
                        label="app.helpdesk.attribute_name_pl"
                        style={{ marginTop: "15px" }}
                      />
                      <TextInput
                        variant="outlined"
                        source="value.en"
                        label="app.helpdesk.attribute_name_en"
                        style={{ marginTop: "15px" }}
                      />
                      <StyledColorInput
                        variant="outlined"
                        source="style.color"
                        label="app.helpdesk.color"
                        validate={required()}
                        sx={{ width: "200px" }}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </>
              );
            }
            return <></>;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default AttributesEdit;
