import React, { useEffect, useState } from "react";
import { loginScheme } from "../../utils/validationSchemes";
import {
  CircularProgress,
  FormControl,
  TextField,
  AutocompleteRenderInputParams,
  Box,
} from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import {
  CustomButton,
  CustomFormLabel,
  FormTextInput,
} from "../../themes/styles";
import { Field, Form, Formik, FormikProps } from "formik";
import ErrorMessageContainer from "../errors/ErrorContainer";
import PasswordInput from "../forms/PasswordInput";
import { getPasswordBackground } from "../../utils/passwordService";
import { useTranslate } from "react-admin";
import { OrganizationService } from "../../api/OrganizationService";
import { IOrganization } from "../../types/interfaces/organization.interface";
import { IBasicResponse } from "../../types/interfaces/response/basic-response.interface";
import { styled } from "@mui/material/styles";
import useParkingSettings from "../../hooks/useParkingSettings";
import useHelpdeskSettings from "../../hooks/useHelpdeskSettings";

type Props = {
  isLoading: boolean;
  resetPasswordProcess: boolean;
  handleResetPassword: (data: {
    email: string;
    password: string;
    orgId: string;
  }) => void;
  handleLogin: (data: {
    email: string;
    password: string;
    orgId: string;
  }) => void;
  formikRef: { current: FormikProps<FormValues> | null };
};

export const StyledTextInput = styled(TextField, {
  name: "RaSearchInput",
  overridesResolver: (props, styles) => styles.root,
})({
  marginTop: "0 !important",
  "& .MuiFormLabel-root": {
    display: "none",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#EEEEEE !important",
    borderRadius: "10px",
    padding: "12px !important",
  },
  "& .MuiInputBase-root:hover": {
    backgroundColor: "#EEEEEE !important",
  },
  "& .MuiInputBase-root:before": {
    border: "none !important",
  },
  "& .MuiInputBase-root:after": {
    border: "none !important",
  },
  "& .MuiInputBase-root:hover:before": {
    border: "none !important",
  },
});

interface FormValues {
  email: string;
  password: string;
  orgId: string;
}

const LoginPageForm = (props: Props) => {
  const {
    isLoading,
    formikRef,
    resetPasswordProcess,
    handleLogin,
    handleResetPassword,
  } = props;
  const translate = useTranslate();

  const [organizationList, setOrganizationList] = useState<IOrganization[]>([]);
  const [orgId, setOrgId] = useState<string | undefined>("");

  useHelpdeskSettings(orgId);
  useParkingSettings(orgId);

  const handleSubmit = async (data: {
    email: string;
    password: string;
    orgId: string;
  }) => {
    if (resetPasswordProcess) {
      handleResetPassword(data);
    } else {
      handleLogin(data);
    }
  };

  const fetchOrgList = async () => {
    try {
      const { data } =
        await OrganizationService.getOrganizationsList<
          IBasicResponse<IOrganization[]>
        >();
      setOrganizationList(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchOrgList();
  }, []);

  const handleOrgIdChange = (
    _: React.SyntheticEvent,
    value: IOrganization | null,
  ) => {
    localStorage.setItem("orgId", value?.id || "");
    setOrgId(value?.id);
    if (formikRef.current) {
      formikRef.current.setFieldTouched("orgId");
      formikRef.current.setFieldValue("orgId", value?.id || "");
    }
  };

  return (
    <Formik
      initialValues={{ email: "", password: "", orgId: "" }}
      validationSchema={loginScheme}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={(f) => (formikRef.current = f)}
      key="AuthForm"
    >
      {({ touched, values, handleChange, errors, setFieldTouched }) => (
        <Form style={{ flexDirection: "column", display: "flex" }}>
          <FormControl>
            <CustomFormLabel>Email</CustomFormLabel>
            <Field>
              {() => (
                <FormTextInput
                  inputProps={{ "data-testid": "email" }}
                  name="email"
                  id="email"
                  type="email"
                  value={values.email}
                  onBlur={() => setFieldTouched("email")}
                  onChange={handleChange("email")}
                />
              )}
            </Field>
          </FormControl>

          {errors.email && touched.email && (
            <div data-testid="emailError">
              <ErrorMessageContainer>{errors.email}</ErrorMessageContainer>
            </div>
          )}

          <FormControl>
            <CustomFormLabel>
              {translate("app.auth.login.password")}
            </CustomFormLabel>
            <Field>
              {() => (
                <PasswordInput
                  name="password"
                  inputProps={{ "data-testid": "password" }}
                  value={values.password}
                  onChange={handleChange("password")}
                  onBlur={() => setFieldTouched("password")}
                  style={{
                    backgroundColor: getPasswordBackground(
                      !errors.password,
                      touched?.password,
                    ),
                  }}
                />
              )}
            </Field>
          </FormControl>

          {errors.password && touched.password && (
            <div data-testid="passwordError">
              <ErrorMessageContainer>{errors.password}</ErrorMessageContainer>
            </div>
          )}

          <FormControl>
            <CustomFormLabel>
              {translate("app.auth.organization")}
            </CustomFormLabel>
            <Autocomplete
              id="organization-input"
              onChange={handleOrgIdChange}
              renderInput={(params: AutocompleteRenderInputParams) => {
                params.inputProps[
                  "data-testid" as keyof typeof params.inputProps
                ] = "orgId";
                return (
                  <StyledTextInput
                    {...params}
                    label={translate("app.auth.choose_organization")}
                    variant="filled"
                  />
                );
              }}
              options={organizationList}
              getOptionLabel={(option: IOrganization) =>
                `${option.displayName}`
              }
            />
          </FormControl>

          {errors.orgId && touched.orgId && (
            <div data-testid="orgIdError">
              <ErrorMessageContainer>{errors.orgId}</ErrorMessageContainer>
            </div>
          )}

          <Box mt={4}>
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <CustomButton
                type="submit"
                aria-label="submitLogin"
                disabled={Object.values(values).includes("")}
                style={{
                  width: "100%",
                }}
              >
                {translate("app.auth.next")}
              </CustomButton>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default LoginPageForm;
