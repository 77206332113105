import { useTranslate } from "react-admin";
import React, { ReactElement } from "react";
import Box from "@mui/material/Box";
import CustomTextInput from "./CustomTextInput";
import { styledCustomInputWrapper } from "../../themes/styles";

interface ICustomInputWrapper {
  source: string;
  label?: string;
  name?: string;
  variant?: string;
  title?: string;
  resettable?: boolean;
  startIcon?: ReactElement;
  placeholder?: string;
  alwaysOn?: boolean;
}

const CustomInputWrapper = (props: ICustomInputWrapper) => {
  const translate = useTranslate();
  const { title = false } = props;
  const textInputProps: Omit<ICustomInputWrapper, "variant"> = props;

  return (
    <Box sx={styledCustomInputWrapper.wrapper}>
      {title && <span className="input-title">{translate(title)}</span>}
      <CustomTextInput alwaysOn resettable {...textInputProps} />
    </Box>
  );
};

export default CustomInputWrapper;
