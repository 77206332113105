import React from "react";
import { Box } from "@mui/material";
import { Title, ItemTitle, ItemContent, ItemContainer } from "./styles";

const StatutePage = () => {
  return (
    <Box>
      <Title data-testid="regulationsPage">
        <b>Regulamin paringu</b>
      </Title>
      <ItemContainer>
        <ItemTitle>
          <b>1. Przedmiot regulaminu.</b>
        </ItemTitle>
        <ItemContent>
          Przedmiotem Regulaminu jest określenie zasad korzystania z parkingu
          prowadzonego przez Net Parking I Sp. z o.o., z siedzibą w Warszawie,
          03-107, Przyrzecze 1J/1, wpisaną do rejestru przedsiębiorców Sądu
          Rejonowego dla m.st. Warszawy, XIII Wydział Gospodarczy, pod numerem
          KRS 0000773228, o kapitale zakładowym w wysokości 10000,00 złotych,
          REGON 382637773, NIP 5242881946 (zwaną dalej: „NP”).
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemTitle>
          <b>
            2. Dla wyjaśnienia pojęć użytych w treści regulaminu, poniższe
            zwroty otrzymują następujące znaczenie:
          </b>
        </ItemTitle>
        <ItemContent>
          2.1. Pojazd – pojazd, którym Korzystający z Parkingu wjeżdża na
          Parking; <br />
          2.2 Czas Postoju Pojazdu - okres, na jaki została zawarta Umowa Najmu
          Miejsca Parkingowego poprzez wpisanie numeru rejestracyjnego Pojazdu
          do Parkometru lub Aplikacji Mobilnej;
          <br /> 2.3. Miejsce Parkingowe - miejsce przeznaczone do parkowania
          Pojazdu na Parkingu;
          <br /> 2.4. Korzystający z Parkingu - najemca Miejsca Parkingowego
          będący właścicielem bądź posiadaczem Pojazdu;
          <br /> 2.5. Opłata Parkometrowa - czynsz należny z tytułu zawarcia
          Umowy Najmu Miejsca Parkingowego. Wysokość czynszu zależna jest od
          Czasu Postoju Pojazdu na Parkingu i określona jest w informacji
          umieszczonej na Parkometrze lub w Cenniku wywieszonym na terenie
          Parkingu;
          <br /> 2.6. Opłata Dodatkowa - kara umowna w kwocie wskazanej w
          informacji umieszczonej na Parkometrze lub w Cenniku, płatna przez
          Korzystającego z Parkingu w przypadkach określonych w Regulaminie;
          <br /> 2.7. Parking - teren oraz wjazdy na teren oznaczone
          oznakowaniem pionowym przez NP, będący parkingiem płatnym i
          niestrzeżonym;
          <br /> 2.8. Parkometr - urządzenie umieszczone na terenie Parkingu
          przystosowane do pobierania Opłaty Parkometrowej należnej tytułem
          najmu Miejsca Parkingowego (instrukcja obsługi Parkometru umieszczona
          jest na Parkometrze);
          <br /> 2.9. Regulamin - treść niniejszego dokumentu;
          <br /> 2.10. Aplikacja Mobilna do płatności – aplikacja mobilna
          umożliwiająca wniesienie Opłaty Parkometrowej za Czas Postoju Pojazdu
          na terenie Parkingu. Nazwa Aplikacji Mobilnej jest umieszczona na
          Parkometrze.
          <br /> 2.11. Umowa Najmu - Umowa Najmu Miejsca Parkingowego zawarta
          pomiędzy Korzystającym z Parkingu, a NP zgodnie z treścią pkt.
          <br />
          3.1 Regulaminu.
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemTitle>
          <b>3. Zawarcie Umowy Najmu Miejsca Parkingowego.</b>
        </ItemTitle>
        <ItemContent>
          3.1. Poprzez i z chwilą wjazdu na teren Parkingu Korzystający z
          Parkingu zawiera z NP Umowę Najmu Miejsca Parkingowego na warunkach
          określonych w niniejszym Regulaminie. W kwestiach nieuregulowanych w
          niniejszym Regulaminie zastosowanie znajdują przepisy ustawy kodeks
          cywilny z dnia 23 kwietnia 1964 r.
          <br /> 3.2. Umowa Najmu zostaje zawarta w momencie wjazdu Pojazdu na
          teren Parkingu i wpisania numeru rejestracyjnego Pojazdu do Parkometru
          lub Aplikacji Mobilnej i kończy się z momentem wyjazdu Pojazdu z
          terenu Parkingu z zastrzeżeniem pkt. 3.3 poniżej.
          <br /> 3.3. W przypadku niezaakceptowania zasad Regulaminu
          Korzystający z Parkingu ma prawo odstąpić od Umowy Najmu w czasie 10
          minut od wjazdu na teren Parkingu poprzez opuszczenie terenu Parkingu.
          W przypadku wykonania prawa odstąpienia od Umowy Najmu, będzie ona
          uważana za niezawartą.
        </ItemContent>
      </ItemContainer>

      {/*;d;------------------------------*/}
      <ItemContainer>
        <ItemTitle>
          <b>4. Opłata Parkometrowa.</b>
        </ItemTitle>
        <ItemContent>
          4.1. Korzystający z Parkingu zobowiązany jest z góry uiścić Opłatę
          Parkometrową określoną w Cenniku, odpowiednią dla danego planowanego
          Czasu Postoju Pojazdu, w Parkometrze lub za pośrednictwem Aplikacji
          Mobilnej do płatności. Cennik może stanowić, że w określonych
          przypadkach Parkowanie odbywa się gratis (bez Opłaty Parkometrowej), z
          zastrzeżeniem, że każdy Pojazd może skorzystać z Parkowania gratis
          jeden raz w ciągu każdej dobry. W każdym przypadku Korzystający z
          Parkingu zobowiązany jest do wpisania numeru rejestracyjnego Pojazdu
          do Parkometru lub Aplikacji Mobilnej.
          <br /> 4.2. Opłatę Parkometrową uiszcza się poprzez wpisanie numeru
          rejestracyjnego Pojazdu do Parkometru i pozostawienie odpowiedniej
          kwoty gotówki w Parkometrze lub poprzez dokonanie płatności kartą
          płatniczą, jeżeli Parkometr umożliwia taką metodę płatności lub
          dokonanie płatności przez Aplikację Mobilną do płatności. Parkometr
          przyjmuje monety o nominałach: 50gr, 1zł, 2zł, 5 zł. Parkometr nie
          wydaje reszty.
          <br /> 4.3. Potwierdzeniem uiszczenia Opłaty Parkometrowej lub
          uzyskania uprawnienia do parkowania Pojazdu bez opłaty jest wpisanie
          numeru rejestracyjnego do Parkometru lub Aplikacji Mobilnej. Numer
          pojazdu jest rejestrowany przez system obsługujący Parkometr bądź
          Aplikację Mobilna do płatności.
          <br /> 4.4 Możliwe jest również opłacenie czasu postoju Pojazdu
          poprzez wykupienie abonamentu. Warunki zakupu abonamentu określa
          odrębny Regulamin.
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <b>5. Czas postoju Pojazdu.</b>
        </ItemTitle>
        <ItemContent>
          5.1.Maksymalny dopuszczalny nieprzerwany czas postoju wynosi 2 dni (48
          godzin). Po upływie tego terminu Korzystający z Parkingu zobowiązany
          jest opuścić Pojazdem teren Parkingu. W przypadku nieopuszczenia
          terenu Parkingu poczytuje się, że Korzystający z Parkingu korzysta z
          Miejsca Parkingowego bezumownie.
          <br /> 5.2. Maksymalny dopuszczalny czas postoju opisany w pkt 5.1.
          powyżej nie obowiązuje Najemców z aktywnym i opłaconym abonamentem
          uprawniającym do parkowania pojazdu w okresie na jaki został wykupiony
          abonament na najem miejsca parkingowego na przedmiotowym parkingu.
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemTitle>
          <b>6. Obowiązki Korzystającego z Parkingu.</b>
        </ItemTitle>
        <ItemContent>
          6.1. Korzystający z Parkingu zobowiązuje się do uiszczenia Opłaty
          Parkometrowej, a w przypadkach przewidzianych w punkcie 8 Regulaminu
          również do uiszczenia Opłaty Dodatkowej.
          <br /> 6.2. Korzystający z Parkingu odpowiada za wszystkie szkody
          wyrządzone na terenie Parkingu, wynikające zarówno z przyczyn leżących
          po jego stronie, jak i związanych z postojem Pojazdu na Parkingu.
          <br /> 6.3. Korzystający z Parkingu zobowiązany jest nie utrudniać
          korzystania z Parkingu innym Korzystającym z Parkingu.
          <br /> 6.4. Korzystający z Parkingu zobowiązany jest do zatrzymywania
          Pojazdu w odległości umożliwiającej dostęp do sąsiednich Pojazdów.
          <br /> 6.5. Korzystający z Parkingu zobowiązany jest do przestrzegania
          znaków drogowych i oznaczonych kierunków ruchu oraz stosowania się do
          poleceń obsługi Parkingu.
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemTitle>
          <b>7. Zasady korzystania z Parkingu.</b>
        </ItemTitle>
        <ItemContent>
          7.1. Parking jest niestrzeżony i płatny przez 24h przez siedem dni w
          każdym tygodniu.
          <br /> 7.2. Parking jest czynny 24h przez siedem dni w każdym
          tygodniu.
          <br /> 7.3. NP nie odpowiada za szkody powstałe z przyczyn leżących po
          stronie innych Korzystających z Parkingu bądź osób trzecich.
          <br /> 7.4. Na terenie Parkingu dozwolona jest prędkość do 10 km/h.
          <br />
          7.5. Zabroniony jest wjazd na Parking pojazdów przewożących materiały
          niebezpieczne, w szczególności wybuchowe, łatwopalne, żrące lub
          stwarzające niebezpieczeństwo dla innych użytkowników Parkingu lub ich
          mienia.
          <br /> 7.6. Na terenie Parkingu zakazane jest: naprawianie, mycie,
          odkurzanie pojazdów, wymiana płynów chłodzących, paliwa lub oleju,
          palenie i używanie otwartego ognia, magazynowanie paliw, substancji
          łatwopalnych i pustych pojemników po paliwie, tankowanie,
          nieuzasadnione pozostawianie Pojazdu z pracującym silnikiem.
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemTitle>
          <b>8. Kary Umowne i odszkodowanie.</b>
        </ItemTitle>
        <ItemContent>
          8.1. W przypadku, gdy Korzystający z Parkingu nie wpisze numeru
          rejestracyjnego Pojazdu do Parkometru lub Aplikacji Mobilnej i/lub nie
          uiści Opłaty Parkometrowej upoważniającej do postoju w czasie, kiedy
          Pojazd znajduje się na terenie Parkingu, wówczas NP jest uprawniona do
          naliczenia kary umownej w postaci Opłaty Dodatkowej w wysokości
          określonej w cenniku.
          <br /> 8.2. Za każdy przypadek nienależytego wykonania Umowy Najmu w
          zakresie obowiązków Korzystającego z Parkingu wskazanych w punktach
          6.3 do 6.4, jak również zasad korzystania z Parkingu wskazanych w
          punktach 7.5 - 7.7, niezależnie od uprawnienia wynikającego z punktu
          8.1. NP ma prawo naliczyć Korzystającemu z Parkingu karę umowną w
          postaci Opłaty Dodatkowej.
          <br /> 8.3. Opłata Dodatkowa może zostać również naliczona w
          przypadku, kiedy Pojazd zablokuje przejazd, drogę pożarową lub zajmuje
          miejsce przeznaczone dla osób niepełnosprawnych bez stosownego
          upoważnienia.
          <br /> 8.4. Oryginał potwierdzenia naliczenia Opłaty Dodatkowej NP
          umieści na Pojeździe, w miarę możliwości na przedniej szybie Pojazdu,
          kopię potwierdzenia naliczenia Opłaty Dodatkowej zachowuje NP.
          <br /> 8.5. Opłatę Dodatkową uiszcza się poprzez dokonanie wpłaty w
          sposób wskazany na dokumencie potwierdzającym naliczenie Opłaty
          Dodatkowej, o którym mowa w punkcie 8.4, w terminie wskazanym w tym
          dokumencie.
          <br /> 8.6. NP może dochodzić odszkodowania przekraczającego wysokość
          kar umownych zastrzeżonych w niniejszym Regulaminie.
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemTitle>
          <b>9. Odholowanie Pojazdu.</b>
        </ItemTitle>
        <ItemContent>
          9.1. NP lub odpowiednie służby publiczne są uprawnione do odholowania
          Pojazdu w razie zaistnienia nagłego zagrożenia, pozostawienia Pojazdu
          w miejscu niedozwolonym lub w sytuacjach określonych przepisami prawa.
          <br />
          9.2. W razie odholowania Pojazdu, informacja o miejscu pozostawienia
          Pojazdu zostanie udzielona odpowiednio przez NP pod numerem telefonu
          umieszczonym na Parkometrze lub odpowiednie służby publiczne, w sposób
          wynikający z obowiązujących przepisów prawa.
          <br /> 9.3. Jeżeli konieczność odholowania Pojazdu wynika z przyczyn
          leżących po stronie Korzystającego z Parkingu, wówczas koszty
          odholowania zostaną zwrócone NP przez Korzystającego z Parkingu.
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemTitle>
          <b>10. Koszty i windykacja.</b>
        </ItemTitle>
        <ItemContent>
          10.1. W uzasadnionych przypadkach oraz zgodnie z obowiązującymi
          przepisami prawa, nieopłacone w terminie:
          <br /> 10.1.1. Opłata Dodatkowa lub
          <br />
          10.1.2. odszkodowanie z tytułu nienależytego wykonania Umowy Najmu lub
          <br />
          10.1.3. koszty odholowania Pojazdu w przypadku, o którym mowa w
          punkcie 9.3., mogą być dochodzone w drodze postępowania windykacyjnego
          z wykorzystaniem danych właściciela Pojazdu uzyskanych z Centralnej
          Ewidencji Pojazdów i Kierowców (CEPIK). W uzasadnionych przypadkach
          wynikających z obowiązujących przepisów prawa Korzystający z Parkingu
          może zostać dodatkowo obciążony kosztem pozyskania danych właściciela
          Pojazdu z CEPiK w wysokości przewidzianej w § 6 rozporządzenia
          Ministra Spraw Wewnętrznych i Administracji z dnia 19 września 2001 r.
          w sprawie centralnej ewidencji pojazdów (Dz.U. z 2001 r. Nr 106, poz.
          1166 z późn. zm.), a w razie zasądzenia przez sąd powszechny
          należności przysługującej NP wynikającej z postanowień Regulaminu,
          także kosztami postępowania sądowego i windykacji.
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <b>11. Dane osobowe.</b>
        </ItemTitle>
        <ItemContent>
          11.1. W związku z zawarciem i realizacją Umowy Najmu Miejsca
          Parkingowego, NP przetwarza dane osobowe osoby zawierającej Umowę
          Najmu (Korzystającego z Parkingu) i jest administratorem tych danych
          osobowych. Dane osobowe są również przetwarzane w celach rachunkowych
          (w tym wystawienia faktury), postępowań windykacyjnych, a także
          realizacji reklamacji.
          <br /> 11.2. NP przetwarza następujące dane: numer rejestracyjny
          pojazdu oraz wizerunek Korzystającego z Parkingu poprzez monitoring
          wizyjny (o ile został zainstalowany), a w przypadku procesów
          reklamacyjnych, windykacyjnych i egzekucyjnych przetwarzane są
          następujące dane: imię, nazwisko, adres zamieszkania, numer
          rejestracyjny, marka pojazdu oraz wizerunek Korzystającego z Parkingu.
          Podanie numeru rejestracyjnego jest zobowiązaniem wynikającym z Umowy
          Najmu. Brak podania danych w przypadku procesów reklamacyjnych,
          windykacyjnych i egzekucyjnych skutkować będzie pobraniem tych danych
          z rejestrów publicznych.
          <br /> 11.3. Odbiorcami danych przetwarzanych w związku z zawarciem i
          realizacją Umowy Najmu Miejsca Parkingowego są firmy windykacyjne,
          dostawcy usług IT oraz systemów parkingowych i informatycznych, a
          także podmioty serwisujące te systemy i urządzenia na których są
          zainstalowane, a w granicach przewidzianych prawem również podmioty,
          którym NP zobowiązana jest udostępniać takie dane.
          <br />
          11.4. Dane osobowe będą przechowywane przez okres obowiązywania Umowy
          Najmu, a po jej zakończeniu przez okres wymagany prawem, w
          szczególności zaś przez okres wynikający z regulacji dotyczących
          rachunkowości i w okresie zapewniającym NP możliwość ustalenia i
          dochodzenia roszczeń związanych z Umową Najmu.
          <br /> 11.5. NP respektuje przysługujące każdej osobie uprawnienia
          związane z przetwarzaniem jej danych osobowych. W szczególności każdej
          osobie, której dane są przetwarzane przysługuje prawo: uzyskania
          informacji o przetwarzaniu jej danych osobowych, prawo dostępu do
          danych, żądania ich sprostowania, uzupełnienia i zmiany, prawo do
          usunięcia danych, prawo do ograniczenia przetwarzania danych, prawo do
          przenoszenia danych, prawo sprzeciwu wobec przetwarzania jej danych.
          <br /> 11.6. NP realizuje wyżej wskazane prawa zapewniając możliwość
          złożenia odpowiedniego wniosku za pośrednictwem poczty elektronicznej
          na adres biuro@netparking.pl, a także pisemnie na adres NP z
          oznaczeniem „dane osobowe”, z tym zastrzeżeniem, że:
          <br /> 11.6.1. osoba, której dane dotyczą ma prawo dostępu do swoich
          danych oraz prawo do informacji o danych, jednak prawo to może
          aktywnie realizować nie częściej niż raz na 6 miesięcy. Częstsze
          korzystanie z prawa do informacji i dostępu do danych podlegać będzie
          opłacie w wysokości 50 zł uiszczanej na rachunek bankowy NP, przed
          realizacją przez NP złożonego wniosku. Opłata ta stanowi uzasadnione
          koszty ponoszone przez NP w związku z realizacją prawa dostępu i jest
          dopuszczalna prawem.
          <br /> 11.6.2. osoba ma prawo do żądania usunięcia jej danych
          osobowych, w szczególności gdy dane nie są już niezbędne dla celów dla
          jakich zostały zebrane, a w szczególności dla realizacji Umowy Najmu.
          <br /> 11.7. NP informuje, że osoba, która zawarta Umowę Najmu Miejsca
          Parkingowego nie podlega decyzjom opartym wyłącznie o zautomatyzowane
          przetwarzanie danych osobowych.
          <br /> 11.8. Wobec przetwarzania danych przez NP, osobie, której dane
          dotyczą, przysługuje prawo wniesienia skargi do Urzędu Ochrony Danych
          Osobowych.
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <b>12. Postanowienia końcowe.</b>
        </ItemTitle>
        <ItemContent>
          12.1. NP nie przewiduje szczególnego trybu rozpoznawania reklamacji
          związanych z Umową Najmu. Wszelkie reklamacje powinny być zgłaszane do
          NP w terminie 14 dni i w sposób przewidziany przepisami prawa.
          <br /> 12.2 Wszelkie uwagi i zgłoszenia dotyczące funkcjonowania
          Parkingu należy zgłaszać do administracji NP – tel. 792147 666, mail:
          reklamacje@netparking.pl bądź za pośrednictwem strony internetowej
          www.reklamacje-netparking.pl <br />
          12.2 Regulamin obowiązuje od dnia 15 maja 2023 r. Regulamin został
          zatwierdzony uchwałą Zarządu NP z dnia 15 maja 2023 r.
        </ItemContent>
      </ItemContainer>
    </Box>
  );
};

export default StatutePage;
