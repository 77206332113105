import { isTokenValid } from "../utils/tokenService";
import { apiRequest } from "../api/client";
import { IProfile } from "../types/interfaces/profile.interface";
import { IBasicResponse } from "../types/interfaces/response/basic-response.interface";
import { IAuthUser } from "../types/interfaces/AuthUser";
import i18nProvider from "../translations/i18n-provider";
import { redirect } from "react-router";
import { ConfigManager } from "../constants/ConfigManager";
import { AuthService } from "../api/AuthService";
import { Roles } from "../types/enums/roles";
import { addRefreshAuthToAuthProvider } from "ra-core";

const { translate } = i18nProvider;

export const refreshAuth = async () => {
  try {
    const token = localStorage.getItem("token");
    if (token && !isTokenValid(token)) {
      const data = await AuthService.refreshToken();

      localStorage.setItem("token", data?.accessToken);
      localStorage.setItem("refreshToken", data?.refreshToken);
    }

    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
};

const customAuthProvider = {
  login: async ({
    email,
    password,
    orgId,
  }: {
    email: string;
    password: string;
    orgId: string;
  }) => {
    try {
      const { data } = await apiRequest<IBasicResponse<IAuthUser>>({
        method: "POST",
        url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/auth/login`,
        data: {
          email,
          password,
          groupId: orgId,
        },
      });
      localStorage.setItem("token", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      try {
        const { data } = await apiRequest<IBasicResponse<IProfile>>({
          method: "GET",
          url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/users/me`,
        });
        if (!data.roles.includes(Roles.ROLE_APP_MANAGEMENT_ACCESS)) {
          return Promise.reject(
            translate("app.notifications.error.access_denied"),
          );
        }
        localStorage.setItem("roles", JSON.stringify(data.roles));
      } catch (e) {
        return Promise.reject(
          translate("app.notifications.error.unexpected_error"),
        );
      }
    } catch (e) {
      return Promise.reject(
        e?.response?.data?.error?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
      );
    }
  },
  getIdentity: async () => {
    try {
      const { data } = await apiRequest<IBasicResponse<IProfile>>({
        method: "GET",
        url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/users/me`,
      });

      return Promise.resolve({
        id: data.id,
        fullName: `${data.firstName} ${data.lastName}`,
      });
    } catch (e) {
      return Promise.reject();
    }
  },
  getPermissions: async () => {
    const roles = localStorage.getItem("roles");
    return Promise.resolve(roles ? JSON.parse(roles) : []);
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("orgId");
    localStorage.removeItem("roles");

    return Promise.resolve("/login");
  },
  checkAuth: async () => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const orgId = localStorage.getItem("orgId");

    if (!token || !orgId) {
      return Promise.reject();
    }

    if (token && !isTokenValid(token)) {
      if (refreshToken && isTokenValid(refreshToken)) {
        const data = await AuthService.refreshToken();
        localStorage.setItem("token", data?.accessToken);
        localStorage.setItem("refreshToken", data?.refreshToken);
      }
    }

    return Promise.resolve();
  },
  checkError: (error: { status?: number }) => {
    const status = error?.status;
    if (status === 401) {
      redirect("/login");
      return Promise.reject();
    }
    return Promise.resolve();
  },
};

export const authProvider = addRefreshAuthToAuthProvider(
  customAuthProvider,
  refreshAuth,
);
