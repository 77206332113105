import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StopwatchContainer = styled(Box)(() => ({
  fontSize: "34px",
  fontWeight: "bold",
}));

type Props = {
  setSeconds: (prevSeconds: (prevSeconds: number) => number) => void;
  setMinutes: (minutes: (prevMinutes: number) => number) => void;
  setHours: (hours: (prevHours: number) => number) => void;
  hours: number;
  minutes: number;
  seconds: number;
  isActiveStopwatch: boolean;
  setIsActiveStopwatch: (isActive: boolean) => void;
};

const Stopwatch = ({
  setSeconds,
  setMinutes,
  setHours,
  hours,
  minutes,
  seconds,
  isActiveStopwatch,
  setIsActiveStopwatch,
}: Props) => {
  useEffect(() => {
    if (!isActiveStopwatch) {
      setInterval(() => {
        setSeconds((prevSeconds: number) => {
          if (prevSeconds === 59) {
            setMinutes((prevMinutes: number) => {
              if (prevMinutes === 59) {
                setHours((prevHours: number) => prevHours + 1);
                return 0;
              } else {
                return prevMinutes + 1;
              }
            });
            return 0;
          } else {
            return prevSeconds + 1;
          }
        });
      }, 1000);

      setIsActiveStopwatch(true);
    }
  }, [
    isActiveStopwatch,
    setHours,
    setIsActiveStopwatch,
    setMinutes,
    setSeconds,
  ]);

  return (
    <StopwatchContainer>
      {String(hours).padStart(2, "0")}:{String(minutes).padStart(2, "0")}:
      {String(seconds).padStart(2, "0")}
    </StopwatchContainer>
  );
};

export default Stopwatch;
