import { useEffect, useState } from "react";
import { mobileModulesService } from "../api/MobileModulesService";
import { IBasicResponse } from "../types/interfaces/response/basic-response.interface";
import { FileRejection } from "react-dropzone";
import { uploadedAttachmentFormattedRejectionReason } from "../utils/ticket/ticketsHelper";
import { useNotify, useRecordContext } from "react-admin";

const useUploadModuleIcon = () => {
  const notify = useNotify();
  const record = useRecordContext();

  const [uploadedAttachmentId, setUploadedAttachmentId] = useState<string>("");

  useEffect(() => {
    setUploadedAttachmentId(record?.icon?.iconId || "");
  }, []);

  const uploadFiles = async (files: File[]) => {
    for (const fileItem of files) {
      const formData = new FormData();
      formData.append("icon", fileItem);

      try {
        const { data } =
          await mobileModulesService.uploadMobileIcon<
            IBasicResponse<{ iconId: string }>
          >(formData);
        setUploadedAttachmentId(data.iconId);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleFileRejections = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      const message =
        uploadedAttachmentFormattedRejectionReason(fileRejections);

      notify(message, { type: "error" });
    }
  };

  const deleteUploadedFile = () => {
    setUploadedAttachmentId("");
  };

  return {
    deleteUploadedFile,
    handleFileRejections,
    uploadFiles,
    uploadedAttachmentId,
  };
};

export default useUploadModuleIcon;
