import React from "react";
import { Box } from "@mui/material";
import { ItemContainer, ItemTitle, ItemContent, Title } from "./styles";

const PrivacyPolicyPage = () => {
  return (
    <Box>
      <Title data-testid="privacyPolicyPage">Polityka prywatności</Title>
      <ItemContent style={{ margin: "30px 0" }}>
        Polityka prywatności opisuje zasady przetwarzania przez nas informacji
        na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw.
        cookies.
      </ItemContent>
      <ItemContainer>
        <ItemTitle>1. Informacje ogólne</ItemTitle>
        <ItemContent>
          1.1. Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod
          adresem url: netparking.pl <br />
          1.2. Operatorem serwisu oraz Administratorem danych osobowych jest:
          Net Parking I Sp. z o.o. Przyrzecze 1J/1 <br />
          1.3. Adres kontaktowy poczty elektronicznej operatora:
          biuro@netparking.pl
          <br /> 1.4. Operator jest Administratorem Twoich danych osobowych w
          odniesieniu do danych podanych dobrowolnie w Serwisie.
          <br />
          1.5. Serwis wykorzystuje dane osobowe w następujących celach: <br />
          1.5.1. Obsługa zapytań przez formularz <br />
          1.5.2. Realizacja zamówionych usług
          <br />
          1.6. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach
          i ich zachowaniu w następujący sposób: <br />
          1.6.1. Poprzez dobrowolnie wprowadzone w formularzach dane, które
          zostają wprowadzone do systemów Operatora. <br />
          1.6.2. Poprzez zapisywanie w urządzeniach końcowych plików cookie
          (tzw. „ciasteczka”).
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          2. Wybrane metody ochrony danych stosowane przez Operatora
        </ItemTitle>
        <ItemContent>
          1. Miejsca logowania i wprowadzania danych osobowych są chronione w
          warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane
          logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze
          użytkownika i mogą być odczytane jedynie na docelowym serwerze.
          <br /> 2. Dane osobowe przechowywane w bazie danych są zaszyfrowane w
          taki sposób, że jedynie posiadający Operator klucz może je odczytać.
          Dzięki temu dane są chronione na wypadek wykradzenia bazy danych z
          serwera.
          <br />
          3. Hasła użytkowników są przechowywane w postaci hashowanej. Funkcja
          hashująca działa jednokierunkowo - nie jest możliwe odwrócenie jej
          działania, co stanowi obecnie współczesny standard w zakresie
          przechowywania haseł użytkowników.
          <br /> 4. Operator okresowo zmienia swoje hasła administracyjne.
          <br /> 5. W celu ochrony danych Operator regularnie wykonuje kopie
          bezpieczeństwa.
          <br /> 6. Istotnym elementem ochrony danych jest regularna
          aktualizacja wszelkiego oprogramowania, wykorzystywanego przez
          Operatora do przetwarzania danych osobowych, co w szczególności
          oznacza regularne aktualizacje komponentów programistycznych.
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>3. Hosting</ItemTitle>
        <ItemContent>
          1. Serwis jest hostowany (technicznie utrzymywany) na serwerach
          operatora: OVH
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych
        </ItemTitle>
        <ItemContent>
          1.W niektórych sytuacjach Administrator ma prawo przekazywać Twoje
          dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania
          zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na
          Administratorze. Dotyczy to takich grup odbiorców: 1.1 Operatorzy
          płatności <br />
          2. Twoje dane osobowe przetwarzane przez Administratora nie dłużej,
          niż jest to konieczne do wykonania związanych z nimi czynności
          określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W
          odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej
          niż przez 3 lata.
          <br /> 3. Przysługuje Ci prawo żądania od Administratora:
          <br />
          3.1. dostępu do danych osobowych Ciebie dotyczących,
          <br /> 3.2. ich sprostowania, <br />
          3.3. usunięcia, <br />
          3.4. ograniczenia przetwarzania,
          <br /> 3.5. oraz przenoszenia danych.
          <br /> 4. Przysługuje Ci prawo do złożenia sprzeciwu w zakresie
          przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania danych
          osobowych w celu wykonania prawnie uzasadnionych interesów
          realizowanych przez Administratora, w tym profilowania, przy czym
          prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia
          ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych
          wobec Ciebie interesów, praw i wolności, w szczególności ustalenia,
          dochodzenia lub obrony roszczeń.
          <br /> 5. Na działania Administratora przysługuje skarga do Prezesa
          Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
          <br /> 6. Podanie danych osobowych jest dobrowolne, lecz niezbędne do
          obsługi Serwisu. <br />
          7. W stosunku do Ciebie mogą być podejmowane czynności polegające na
          zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu
          świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia
          przez Administratora marketingu bezpośredniego.
          <br /> 8. Dane osobowe nie są przekazywane od krajów trzecich w
          rozumieniu przepisów o ochronie danych osobowych. Oznacza to, że nie
          przesyłamy ich poza teren Unii Europejskiej.
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>5. Informacje w formularzach</ItemTitle>
        <ItemContent>
          1. Serwis zbiera informacje podane dobrowolnie przez użytkownika, w
          tym dane osobowe, o ile zostaną one podane.
          <br /> 2. Serwis może zapisać informacje o parametrach połączenia
          (oznaczenie czasu, adres IP). <br />
          3. Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą
          powiązanie danych w formularzu z adresem e-mail użytkownika
          wypełniającego formularz. W takim wypadku adres e-mail użytkownika
          pojawia się wewnątrz adresu url strony zawierającej formularz.
          <br /> 4. Dane podane w formularzu są przetwarzane w celu wynikającym
          z funkcji konkretnego formularza, np. w celu dokonania procesu obsługi
          zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp.
          Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do
          czego on służy.
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>6. Logi Administratora</ItemTitle>
        <ItemContent>
          1. Informacje zachowaniu użytkowników w serwisie mogą podlegać
          logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>7. Istotne techniki marketingowe</ItemTitle>
        <ItemContent>
          1. Operator stosuje analizę statystyczną ruchu na stronie, poprzez
          Google Analytics (Google Inc. z siedzibą w USA). Operator nie
          przekazuje do operatora tej usługi danych osobowych, a jedynie
          zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek w
          urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach
          użytkownika gromadzonych przez sieć reklamową Google użytkownik może
          przeglądać i edytować informacje wynikające z plików cookies przy
          pomocy narzędzia: https://www.google.com/ads/preferences/
          <br /> 2. Operator stosuje techniki remarketingowe, pozwalające na
          dopasowanie przekazów reklamowych do zachowania użytkownika na
          stronie, co może dawać złudzenie, że dane osobowe użytkownika są
          wykorzystywane do jego śledzenia, jednak w praktyce nie dochodzi do
          przekazania żadnych danych osobowych od Operatora do operatorom
          reklam. Technologicznym warunkiem takich działań jest włączona obsługa
          plików cookie.
          <br /> 3. Operator stosuje korzysta z piksela Facebooka. Ta
          technologia powoduje, że serwis Facebook (Facebook Inc. z siedzibą w
          USA) wie, że dana osoba w nim zarejestrowana korzysta z Serwisu.
          Bazuje w tym wypadku na danych, wobec których sam jest
          administratorem, Operator nie przekazuje od siebie żadnych dodatkowych
          danych osobowych serwisowi Facebook. Usługa bazuje na wykorzystaniu
          ciasteczek w urządzeniu końcowym użytkownika.
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>8. Informacja o plikach cookies</ItemTitle>
        <ItemContent>
          1. Serwis korzysta z plików cookies.
          <br /> 2. Pliki cookies (tzw. „ciasteczka”) stanowią dane
          informatyczne, w szczególności pliki tekstowe, które przechowywane są
          w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do
          korzystania ze stron internetowych Serwisu. Cookies zazwyczaj
          zawierają nazwę strony internetowej, z której pochodzą, czas
          przechowywania ich na urządzeniu końcowym oraz unikalny numer.
          <br /> 3. Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
          Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator
          Serwisu.
          <br /> 4. Pliki cookies wykorzystywane są w następujących celach:
          <br /> 4.1. utrzymanie sesji użytkownika Serwisu (po zalogowaniu),
          dzięki której użytkownik nie musi na każdej podstronie Serwisu
          ponownie wpisywać loginu i hasła;
          <br /> 4.2. realizacji celów określonych powyżej w części “Istotne
          techniki marketingowe”;
          <br /> 5. W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
          cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
          cookies). Cookies „sesyjne” są plikami tymczasowymi, które
          przechowywane są w urządzeniu końcowym Użytkownika do czasu
          wylogowania, opuszczenia strony internetowej lub wyłączenia
          oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies
          przechowywane są w urządzeniu końcowym Użytkownika przez czas
          określony w parametrach plików cookies lub do czasu ich usunięcia
          przez Użytkownika.
          <br /> 6. Oprogramowanie do przeglądania stron internetowych
          (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza
          przechowywanie plików cookies w urządzeniu końcowym Użytkownika.
          Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie.
          Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe
          jest także automatyczne blokowanie plików cookies Szczegółowe
          informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki
          internetowej.
          <br /> 7. Ograniczenia stosowania plików cookies mogą wpłynąć na
          niektóre funkcjonalności dostępne na stronach internetowych Serwisu.
          <br /> 8. Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
          Serwisu wykorzystywane mogą być również przez współpracujące z
          operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google
          (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w
          USA), Twitter (Twitter Inc. z siedzibą w USA).
        </ItemContent>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          9. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?
        </ItemTitle>
        <ItemContent>
          1. Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić
          ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików
          cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa,
          utrzymania preferencji użytkownika może utrudnić, a w skrajnych
          przypadkach może uniemożliwić korzystanie ze stron www <br />
          2. W celu zarządzania ustawienia cookies wybierz z listy poniżej
          przeglądarkę internetową, której używasz i postępuj zgodnie z
          instrukcjami: <br />
          2.1. Edge <br />
          2.2. Internet Explorer <br />
          2.3. Chrome <br />
          2.4. Safari <br />
          2.5. Firefox <br />
          2.6. Opera <br />
          3. Urządzenia mobilne: <br />
          3.1. Android <br />
          3.2. Safari (iOS) 3.3. Windows Phone
          <br />
        </ItemContent>
      </ItemContainer>
      <ItemContent style={{ marginTop: "30px" }}>
        Niniejszy wzór polityki został wygenerowany bezpłatnie, w celach
        informacyjnych, w oparciu o naszą wiedzę, branżowe praktyki i przepisy
        prawa obowiązujące na dzień 2018-08-14. Zalecamy sprawdzenie wzoru
        polityki przed użyciem jej na stronie. Wzór opiera się na najczęściej
        występujących na stronach internetowych sytuacjach, ale może nie
        odzwierciedlać pełnej i dokładnej specyfiki Twojej strony www.
        Przeczytaj uważnie wygenerowany dokument i w razie potrzeb dostosuj go
        do Twojej sytuacji lub zasięgnij porady prawnej. Nie bierzemy
        odpowiedzialności za skutki posługiwania się tym dokumentem, ponieważ
        tylko Ty masz wpłw na to, czy wszystkie informacje w nim zawarte są
        zgodne z prawdą. Zwróć także uwagę, że Polityka Prywatności, nawet
        najlepsza, jest tylko jednym z elementów Twojej troski o dane osobowe i
        prywatność użytkownika na stronie www.
      </ItemContent>
    </Box>
  );
};

export default PrivacyPolicyPage;
