import React from "react";
import {
  CreateButton,
  Datagrid,
  FunctionField,
  NumberField,
  TextField,
  useLocaleState,
  TopToolbar,
  ListToolbarProps,
  useTranslate,
} from "react-admin";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import { listTopFilter, StyledList } from "../../../themes/styles";
import { sanitizeListRestProps } from "ra-core";
import CustomPagination from "../../../components/common/CustomPagination";
import { IMobileModule } from "../../../types/interfaces/mobile-module.interface";

const ModulesListActions = (props: ListToolbarProps) => {
  const { className, ...rest } = props;
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const ModulesListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const ModulesListView = () => {
  const [locale] = useLocaleState();
  const translate = useTranslate();

  const rowStyle = (record: IMobileModule) => ({
    opacity: ["inactive", "archived"].includes(record.active) ? 0.3 : 1,
  });

  return (
    <SkeletonLoaderList>
      <Datagrid rowClick="edit" bulkActionButtons={false} rowSx={rowStyle}>
        <TextField source={`name.${locale}`} label="app.mobile.name" />
        <FunctionField
          label="app.mobile.type"
          render={(record: IMobileModule) => (
            <span>{translate(`app.mobile.${record.type}`)}</span>
          )}
        />
        <FunctionField
          label="Status"
          render={(record: IMobileModule) => (
            <span>{translate(`app.mobile.${record.active}`)}</span>
          )}
        />
        <NumberField source="position" label="app.mobile.position" />
      </Datagrid>
    </SkeletonLoaderList>
  );
};

const ModulesList = () => {
  return (
    <StyledList
      title="app.mobile.mobile_modules"
      pagination={<ModulesListPagination />}
      actions={<ModulesListActions />}
      queryOptions={{ meta: { active: "all" } }}
    >
      <ModulesListView />
    </StyledList>
  );
};

export default ModulesList;
