import * as React from "react";
import CustomLayout, { LayoutProps } from "./common/Layout";
import StandardAppBar from "./StandardAppBar";
import { StandardMenu } from "./StandardMenu";

const StandardLayout = (props: LayoutProps) => {
  return (
    <CustomLayout {...props} appBar={StandardAppBar} menu={StandardMenu} />
  );
};

export default StandardLayout;
