import React from "react";
import {
  Create,
  SimpleForm,
  email,
  required,
  BooleanInput,
  ListToolbarProps,
  useAuthenticated,
} from "react-admin";
import {
  StyledArraySelect,
  StyledSaveButton,
  StyledTextInput,
} from "../../../themes/styles";
import { Toolbar } from "@mui/material";

const UsersCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const UsersCreate = () => {
  useAuthenticated();
  return (
    <Create redirect="list">
      <SimpleForm toolbar={<UsersCreateFormToolbar />}>
        <StyledTextInput
          variant="outlined"
          name="email"
          source="email"
          label="app.auth.email"
          validate={email()}
        />

        <StyledTextInput
          variant="outlined"
          name="firstName"
          source="firstName"
          label="app.auth.name"
          validate={required()}
        />

        <StyledTextInput
          variant="outlined"
          name="lastName"
          source="lastName"
          label="app.auth.surname"
          validate={required()}
        />

        <StyledTextInput
          variant="outlined"
          name="phoneNumber"
          source="phoneNumber"
          label="app.auth.phoneNumber"
          validate={required()}
        />

        <BooleanInput
          name="active"
          source="active"
          variant="outlined"
          label="app.auth.active"
        />

        <StyledArraySelect
          variant="outlined"
          name="roles"
          choices={[
            { id: "ADMIN", name: "ADMIN" },
            { id: "CUSTOMER", name: "CUSTOMER" },
            { id: "EMPLOYEE", name: "EMPLOYEE" },
          ]}
          source="roles"
        />
      </SimpleForm>
    </Create>
  );
};

export default UsersCreate;
