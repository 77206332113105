import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import {
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  getMarkerEnd,
  MarkerType,
  useReactFlow,
  useNodes,
} from "reactflow";
import { getSmartEdge } from "@tisoap/react-flow-smart-edge";
import { styled } from "@mui/material/styles";
import { Input } from "@mui/material";

const LabelInput = styled(Input)(() => ({}));

const CustomEdge = (props: EdgeProps) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    data,
    sourcePosition,
    targetPosition,
    style,
  } = props;
  const { setEdges } = useReactFlow();
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [label, setLabel] = useState<string>("");

  useEffect(() => {
    setLabel(data && data?.label ? data.label : "Default Label");
  }, []);

  const handleDoubleClick = () => {
    setEditMode(true);
  };

  const handleBlur = () => {
    setEditMode(false);
    saveUpdatedEdges();
  };

  const markerEnd = getMarkerEnd(MarkerType.Arrow, "1__type=arrow");

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setEditMode(false);
      saveUpdatedEdges();
    }
  };
  const nodes = useNodes();
  const getSmartEdgeResponse = getSmartEdge({
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes,
  });

  if (getSmartEdgeResponse === null) {
    return <></>;
  }

  const { edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse;

  const saveUpdatedEdges = () => {
    setEdges((edges: Edge[]) => {
      return edges.map((eItem) => {
        const eData = {
          label,
        };
        return eItem.id === id ? { ...eItem, data: eData } : { ...eItem };
      });
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLabel(e.currentTarget.value);
  };

  return (
    <g onDoubleClick={handleDoubleClick}>
      <path
        style={style}
        className="react-flow__edge-path"
        d={svgPathString}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <div
          onDoubleClick={handleDoubleClick}
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${
              edgeCenterX - 20 / 2
            }px,${edgeCenterY - 40 / 2}px)`,
            pointerEvents: "all",
          }}
        >
          {isEditMode ? (
            <LabelInput
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              value={label}
              style={{ maxWidth: "100px" }}
            />
          ) : (
            <span>{label}</span>
          )}
        </div>
      </EdgeLabelRenderer>
    </g>
  );
};

export default CustomEdge;
