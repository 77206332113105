import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ContentContainer } from "./styles";
import StepTwo from "./StepTwo";
import StepTimePicker from "./StepTimePicker";
import StepOne from "./StepOne";
import SmsStep from "./SmsStep";
import StepForm from "./StepForm";
import StepPayment from "./StepPayment";
import ParkingPrepaidPageTemplate from "../components/ParkingPrepaidPageTemplate";
import { CircularProgress } from "@mui/material";
import { PaymentContextProvider } from "../../../services/payment/PaymentContext";
import usePrepaidInitialization from "../../../hooks/usePrepaidInitialization";

const steps = {
  "1": <StepOne />,
  "2": <StepTwo />,
  "3": <StepTimePicker />,
  "sms-step": <SmsStep />,
  "step-form": <StepForm />,
  "step-payment": <StepPayment />,
};

const ParkingPrepaid = () => {
  const { step, orgId } = useParams();
  const navigate = useNavigate();

  const { isLoading } = usePrepaidInitialization();

  if (!Object.keys(steps).includes(step as string)) {
    navigate(`/parking/prepaid/${orgId}/steps/1`);
    return <></>;
  }

  return (
    <PaymentContextProvider>
      <ParkingPrepaidPageTemplate>
        {isLoading ? (
          <CircularProgress size={60} />
        ) : (
          <ContentContainer>
            {steps[step as keyof typeof steps]}
          </ContentContainer>
        )}
      </ParkingPrepaidPageTemplate>
    </PaymentContextProvider>
  );
};

export default ParkingPrepaid;
