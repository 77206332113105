import { styled } from "@mui/material/styles";
import { LayoutClasses } from "../components/common/Layout";
import { Drawer, Input, FormLabel, ButtonBase } from "@mui/material";
import {
  CLOSED_DRAWER_WIDTH,
  DRAWER_WIDTH,
  SidebarClasses,
} from "../components/common/Sidebar";
import makeStyles from "@mui/styles/makeStyles";
import MenuList from "@mui/material/MenuList";
import {
  MenuClasses,
  Show,
  List,
  TextInput,
  SelectArrayInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  NumberInput,
  Datagrid,
  EditButton,
  DateInput,
  FileInput,
  AutocompleteInput,
  ImageInput,
  SimpleFormIterator,
} from "react-admin";
import lodashGet from "lodash/get";
import { ColorInput } from "react-admin-color-picker";
import { RichTextInput } from "ra-input-rich-text";
import Autocomplete from "@mui/material/Autocomplete";

export const StyledFileInput = styled(FileInput, {
  name: "RaImageInput",
  overridesResolver: (props, styles) => styles.root,
})({
  "& .RaFileInput-dropZone": {
    boxShadow: "0px 0px 6px #0000000D !important",
    borderRadius: "8px",
    borderColor: "transparent !important",
    background: "white",
  },
  "& .RaFileInput-removeButton": {
    display: "flex",
  },
  "& .previews": {
    display: "flex",
    gap: "10px",
  },
});

export const StyledDeleteButton = styled(DeleteButton, {
  name: "RaDeleteButton",
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  background: "black",
  borderRadius: "8px",
  textTransform: "capitalize",
  padding: "9px 14px",
  color: "white",
  fontSize: "14px",
  "&:hover": {
    background: "black",
  },

  "&.Mui-disabled": {
    color: "white",
    opacity: "0.5",
  },
}));

export const StyledImageInput = styled(ImageInput, {
  name: "RaImageInput",
  overridesResolver: (props, styles) => styles.root,
})({
  "& .RaFileInput-dropZone": {
    boxShadow: "0px 0px 6px #0000000D !important",
    borderRadius: "8px",
    borderColor: "transparent !important",
    background: "white",
  },
});

export const StyledSimpleFormIterator = styled(SimpleFormIterator, {
  name: "RaSimpleIterator",
  overridesResolver: (props, styles) => styles.root,
})({
  "& .RaSimpleFormIterator-line": {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
});

export const StyledDateInput = styled(DateInput, {
  name: "RaDataInput",
  overridesResolver: (props, styles) => styles.root,
})({
  marginTop: 0,
  background: "white",
  borderRadius: "8px",
  "& .MuiOutlinedInput-notchedOutline legend": {
    display: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 0px 6px #0000000D",
    borderRadius: "8px",
    borderColor: "transparent !important",
    top: 0,
  },
  "& .MuiFormControl-root": {
    borderRadius: "8px",
  },
  "& fieldset:focus": {
    boxShadow: "0px 0px 6px #0000000D",
    borderColor: "white !important",
  },
  "& .MuiSvgIcon-root": {
    paddingRight: "12px",
  },
  "& .RaResettableTextField-clearIcon": {
    padding: 0,
  },
  "& .MuiFormHelperText-root": {
    display: "none",
  },
  "&.show-help-text .MuiFormHelperText-root": {
    display: "block",
  },
});

export const LoginFormContainer = styled("div")(({ theme }) => ({
  padding: "60px 70px",
  borderRadius: "15px",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16)",
  width: "540px",

  [theme.breakpoints.down("sm")]: {
    width: "360px",
    padding: "20px 30px",
  },
}));

export const AuthPageContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  gap: "60px",
}));

export const LoginFormTitle = styled("div")(({ theme }) => ({
  fontSize: "24px",
  textAlign: "center",
  color: "rgba(0, 0, 0, 1)",
  fontWeight: 500,

  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

export const CustomFormLabel = styled(FormLabel)(() => ({
  fontSize: "14px",
  color: "#BFBFBF",
  textAlign: "start",
  marginBottom: "5px",

  "&.Mui-focused": {
    color: "#BFBFBF",
  },
}));

export const CustomLightButton = styled(ButtonBase)(() => ({
  backgroundColor: "#fff",
  borderRadius: "30px",
  boxShadow: "0px 0px 6px #0000000D",
  color: "rgba(10, 10, 10, 1)",
  padding: "20px",
  fontWeight: "bold",

  "&.Mui-disabled": {
    opacity: 0.3,
  },
}));

export const CustomButton = styled(ButtonBase)(() => ({
  backgroundColor: "rgba(10, 10, 10, 1)",
  borderRadius: "30px",
  color: "white",
  padding: "20px",

  "&.Mui-disabled": {
    opacity: 0.3,
  },
}));

export const FormTextInput = styled(Input)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  height: "50px",
  padding: "0 10px",
  marginBottom: "10px",
  marginTop: "0px !important",
  borderRadius: "10px",
  fontSize: "16px",

  "&.MuiInputBase-root:before": {
    borderBottom: "none !important",
  },

  "&.MuiInputBase-root:after": {
    borderBottom: "none !important",
  },

  "&.MuiInputBase-root:hover:before": {
    borderBottom: "none !important",
  },

  "& .MuiInput-input": {
    paddingTop: "4px !important",
  },

  "& .MuiInput-input:-webkit-autofill,.MuiInput-input:-webkit-autofill:hover,.MuiInput-input:-webkit-autofill:focus,.MuiInput-input:-webkit-autofill:active":
    {
      transitionDelay: "9999s",
    },
}));

export const StyledLayout = styled("div", {
  name: "RaLayout",
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  minHeight: "100vh",
  backgroundColor: theme.palette.background.default,
  position: "relative",
  minWidth: "fit-content",
  width: "100%",
  color: theme.palette.getContrastText(theme.palette.background.default),

  [`& .${LayoutClasses.appFrame}`]: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(7),
    },
  },
  [`& .${LayoutClasses.contentWithSidebar}`]: {
    display: "flex",
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${LayoutClasses.content}`]: {
    padding: "22px",
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
}));

export const StyledDrawer = styled(Drawer, {
  name: "RaSidebar",
  slot: "Root",
  overridesResolver: (props, styles) => styles.root,
  shouldForwardProp: () => true,
})(({ open, theme }) => ({
  height: "calc(100vh - 3em)",
  marginTop: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [`&.${SidebarClasses.appBarCollapsed}`]: {
    marginTop: theme.spacing(-6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(-7),
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${SidebarClasses.docked}`]: {},
  [`& .${SidebarClasses.paper}`]: {},
  [`& .${SidebarClasses.paperAnchorLeft}`]: {},
  [`& .${SidebarClasses.paperAnchorRight}`]: {},
  [`& .${SidebarClasses.paperAnchorTop}`]: {},
  [`& .${SidebarClasses.paperAnchorBottom}`]: {},
  [`& .${SidebarClasses.paperAnchorDockedLeft}`]: {},
  [`& .${SidebarClasses.paperAnchorDockedTop}`]: {},
  [`& .${SidebarClasses.paperAnchorDockedRight}`]: {},
  [`& .${SidebarClasses.paperAnchorDockedBottom}`]: {},
  [`& .${SidebarClasses.modal}`]: {},

  [`& .${SidebarClasses.fixed}`]: {
    position: "fixed",
    height: "calc(100vh - 3em)",
    overflowX: "hidden",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  [`& .MuiPaper-root`]: {
    position: "relative",
    width: open ? 320 : 68,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "transparent",
    borderRight: "none",
    [theme.breakpoints.only("xs")]: {
      marginTop: 0,
      height: "100vh",
      position: "inherit",
      backgroundColor: theme.palette.background.default,
      width: 254,
      scrollbarWidth: "none",
    },
    [theme.breakpoints.up("md")]: {
      border: "none",
    },
    zIndex: "inherit",
  },
  [`& .MuiPaper-root::-webkit-scrollbar`]: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
}));

export const standardLayoutStyles = {
  layout: {
    "& .RaSidebar-fixed": {
      top: 80,
      bottom: 0,
      height: "auto !important",
      scrollbarWidth: "none !important",
      background: "white",
    },
    "& .RaSidebar-fixed::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiDrawer-root": {
      background: "white",
    },
  },
  dashboardMenuItem: {
    paddingLeft: "10px",
    color: "black",
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
  },
};

export const useStyles = makeStyles({
  menu: {
    display: "flex",
    flexDirection: "column",
    width: "180px",
    padding: "40px 20px",
    color: "black",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "uppercase",
    background: "white",
  },
  menuUser: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    maxWidth: "130px",
  },
  menuUserItem: {
    padding: "10px 20px",
    fontSize: "14px",
    color: "black",
  },
  menuItem: {
    marginBottom: "15px",
  },
  menuItemTitle: {
    justifyContent: "start",
    padding: "0px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "black",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  appBarMenu: {
    display: "flex",
    gap: "7px",
  },
  appBarMenuItem: {
    display: "flex",
    justifyContent: "center",
    width: "40px",
    alignItems: "center",
  },
  popover: {
    boxShadow: "0px 1px 3px #00000026",
  },
  popoverContent: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    color: "black",
    fontSize: "14px",
    padding: "15px 30px",
    maxWidth: "130px",
  },
  button: {
    textTransform: "lowercase",
    padding: "0px",
    color: "black",
    justifyContent: "start",
  },
});

export const styledCustomInputWrapper = {
  wrapper: {
    display: "flex",
    "& .input-title": {
      marginRight: "60px",
      fontSize: "24px",
      color: "black",
      fontWeight: "bold",
      alignSelf: "center",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n" +
        "    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n" +
        "    sans-serif",
    },
  },
};

export const standardAppBarStyle = {
  appBar: {
    justifyContent: "center",
    paddingRight: "15px",
    height: "80px",
    backgroundColor: "white",
    boxShadow: "0px 1px 6px #00000014",
    font: "normal normal normal 14px/18px Azo Sans",
    letterSpacing: "0px",
    transform: "unset !important",
    visibility: "initial !important",
    "& .RaLoadingIndicator-loadedIcon": {
      width: "40px",
      borderRadius: "8px",
      maxHeight: "33px",
    },
    "& .RaLoadingIndicator-loadedIcon svg path": {
      fill: "#606060",
    },
    "& .MuiButtonBase-root": {
      minWidth: "unset",
    },
    "& .logout": {
      borderRadius: "8px",
      padding: 0,
      height: "33px",
    },
    "& .logout .RaLogout-icon": {
      display: "flex",
      justifyContent: "center",
    },
  },
  logout: {
    marginRight: "8px",
    "& .MuiListItemText-root": {
      display: "none",
    },
    "& .MuiListItemIcon-root": {
      width: "40px",
      borderRadius: "8px",
    },
  },
  userMenuListItemText: {
    "& .MuiTypography-root": {
      color: "black",
      fontSize: "14px",
    },
  },
};

export const standardMenu = {
  menu: {
    padding: "20px 30px 30px 24px",
    boxSizing: "unset",
    "& .MuiList-root": {
      sx: {
        padding: "20px",
      },
    },
    "& .RaMenu-open": {
      width: "320px !important",
    },
    divider: {
      margin: "30px 0 !important",
      borderWidth: "1px",
      background: "#D8D8D8",
      width: "40px",
      alignSelf: "center",
    },
    menuItemPopover: {
      maxWidth: "240px",
      left: "47px !important",
      "& .MuiList-root": {
        padding: "20px 30px",
      },
      "& .MuiMenuItem-root": {
        padding: "0 0 3px",
        color: "black",
        fontSize: "14px",
      },
      "& .MuiPaper-root": {
        boxShadow: "0px 1px 3px #00000026",
      },
    },
    menuItemButton: {
      minWidth: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      "& .MuiListItemIcon-root": {
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
};

export const Root = styled(MenuList, {
  name: "RaStandardMenu",
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  fontSize: "14px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  [`&.${MenuClasses.open}`]: {
    width: lodashGet(theme, "sidebar.width", DRAWER_WIDTH),
  },

  [`&.${MenuClasses.closed}`]: {
    width: lodashGet(theme, "sidebar.closedWidth", CLOSED_DRAWER_WIDTH),
  },
}));

export const itemInfoComponent = {
  "& .item-info__container": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingLeft: "0px !important",
    fontSize: "14px",

    "& .item-info__title": {
      color: "#5C5C5C",
    },

    "& .item-info__value": {
      color: "black",
      fontWeight: "bold",
    },

    "& .item-info__value--link": {
      color: "black",
      fontWeight: "bold",
      textDecoration: "underline",
    },
  },
};

export const StyledColorInput = styled(ColorInput, {
  name: "ColorInput",
  overridesResolver: (props, styles) => styles.root,
})({
  "& .MuiFormControl-root": {
    background: "white",
    boxShadow: "0px 0px 6px #0000000D",
  },
  "& .MuiInputBase-root": {
    background: "white",
    boxShadow: "0px 0px 6px #0000000D",
    borderRadius: "8px",
  },
  "& .MuiInputBase-root::before": {
    borderBottom: "unset !important",
  },
  "& .MuiFilledInput-root:hover": {
    background: "white",
  },
  "& .MuiFilledInput-root::after": {
    borderBottom: "unset",
  },
  "& .MuiInputBase-root::before:hover": {
    borderBottom: "unset !important",
  },
});

export const StyledMUIAutocompleteInput = styled(Autocomplete)(() => ({
  "& .MuiOutlinedInput-notchedOutline legend": {
    display: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 0px 6px #0000000D",
    borderRadius: "8px",
    borderColor: "transparent !important",
    top: 0,
  },
  "& .MuiFormControl-root": {
    borderRadius: "8px",
  },
  "& fieldset:focus": {
    boxShadow: "0px 0px 6px #0000000D",
    borderColor: "white !important",
  },
  "& .MuiSvgIcon-root": {
    paddingRight: "12px",
  },
  "& .RaResettableTextField-clearIcon": {
    padding: 0,
  },
}));

export const StyledTextInput = styled(TextInput, {
  name: "RaSearchInput",
  overridesResolver: (props, styles) => styles.root,
})({
  marginTop: 0,
  background: "white",
  borderRadius: "8px",
  "& .MuiOutlinedInput-notchedOutline legend": {
    display: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 0px 6px #0000000D",
    borderRadius: "8px",
    borderColor: "transparent !important",
    top: 0,
  },
  "& .MuiFormControl-root": {
    borderRadius: "8px",
  },
  "& fieldset:focus": {
    boxShadow: "0px 0px 6px #0000000D",
    borderColor: "white !important",
  },
  "& .MuiSvgIcon-root": {
    paddingRight: "12px",
  },
  "& .RaResettableTextField-clearIcon": {
    padding: 0,
  },
});

export const StyledShow = styled(Show, {
  name: "showPage",
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    "& .MuiToolbar-root": {
      background: "transparent",
    },
    "& .RaShow-main": {
      maxWidth: "550px",
    },
    "& .RaTabbedShowLayout-content": {
      overflowX: "scroll",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .RaShow-main": {
      maxWidth: "400px",
    },

    "& .RaShow-main .RaList-main": {
      width: "100%",
    },
  },
  "& .MuiPaper-root": {
    marginTop: "35px",
  },

  "& .show-tab": {
    textTransform: "capitalize",
  },

  "& .Mui-selected": {
    color: "black",
    fontWeight: "bold",
  },
}));

export const StyledDatagrid = styled(Datagrid, {
  name: "RaList-root",
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  "& .MuiToolbar-root.RaBulkActionsToolbar-toolbar": {
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
    [theme.breakpoints.down("sm")]: {
      background: "rgb(236, 238, 240) !important",
      display: "flex !important",
      flexDirection: "row !important",
    },
  },
  "& .MuiTableBody-root": {
    borderBottom: "2px solid rgba(0, 0, 0, 0.08)",
  },
  "& .MuiPaper-root": {
    marginTop: "50px",
    boxShadow: "none",
    borderRadius: "8px 8px 0 0",

    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      width: "calc(100vw - 30px)",
    },
  },
  "& .MuiTableCell-root": {
    borderBottom: "none",
    fontSize: "0.875rem",
  },
  "& .MuiTableCell-root .MuiTypography-root": {
    fontWeight: "normal",
    color: "black",
  },
  "& .MuiTableHead-root": {
    borderBottom: "4px solid rgba(0, 0, 0, 0.08)",
  },
  "& .MuiTableHead-root .MuiTableCell-head": {
    fontWeight: "bold",
  },
  "& .MuiToolbar-root": {
    flexWrap: "unset",
    [theme.breakpoints.down("md")]: {
      padding: "3px 0",
    },
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
      display: "flex",
      flexDirection: "column",
    },
  },
  "& .MuiToolbar-root .MuiToolbar-root": {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "unset",
    },
  },
}));

export const StyledList = styled(List, {
  name: "RaList-root",
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  "& .MuiToolbar-root.RaBulkActionsToolbar-toolbar": {
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
    [theme.breakpoints.down("sm")]: {
      background: "rgb(236, 238, 240) !important",
      display: "flex !important",
      flexDirection: "row !important",
    },
  },
  "& .RaList-actions": {
    [theme.breakpoints.down("sm")]: {
      borderRadius: "10px",
      padding: "20px",
      backgroundColor: "#fafafb",
    },
  },
  "& .MuiTableBody-root": {
    borderBottom: "2px solid rgba(0, 0, 0, 0.08)",
  },
  "& .MuiPaper-root": {
    marginTop: "50px",
    boxShadow: "none",
    borderRadius: "8px 8px 0 0",

    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      width: "calc(100vw - 30px)",
    },
  },
  "&.styled-list .MuiPaper-root": {
    overflowX: "auto",
  },
  "&.styled-list--inner .MuiPaper-root": {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 50px)",
    },
  },
  "& .MuiFormControl-root": {
    borderRadius: "8px",
    marginBottom: 0,
  },
  "& .MuiTableCell-root": {
    borderBottom: "none",
    fontSize: "0.875rem",
  },
  "& .MuiTableCell-root .MuiTypography-root": {
    fontWeight: "normal",
    color: "black",
  },
  "& .MuiTableHead-root": {
    borderBottom: "4px solid rgba(0, 0, 0, 0.08)",
  },
  "& .MuiTableHead-root .MuiTableCell-head": {
    fontWeight: "bold",
  },
  "& .list-select-input": {
    backgroundColor: "white",
  },
  "& .list-select-input fieldset legend": {
    display: "none",
  },
  "& .list-select-input fieldset": {
    top: 0,
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    background: "white",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend": {
    display: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 0px 6px #0000000D",
    borderRadius: "8px",
    borderColor: "transparent !important",
    top: 0,
  },
  "& .MuiAutocomplete-root .RaAutocompleteInput-textField": {
    background: "white",
  },
  "& .MuiAutocomplete-root fieldset legend": {
    display: "none",
  },
  "& .MuiToolbar-root": {
    flexWrap: "unset",
    [theme.breakpoints.down("md")]: {
      padding: "3px 0",
    },
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
      display: "flex",
      flexDirection: "column",
    },
  },
  "& .pagination-list .MuiToolbar-root": {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  "& .pagination-list .MuiTablePagination-toolbar": {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  "& .filter-field": {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "15px",
    },
  },
  "& .filter-field .MuiBox-root": {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .filter-field .RaFilterFormInput-hideButton": {
    marginBottom: "4px",
  },
  "& .filter-field .MuiBox-root .input-title": {
    [theme.breakpoints.down("md")]: {
      alignSelf: "start",
    },
  },
  "& .RaFilterFormInput-spacer": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .MuiBox-root": {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  "& .RaFilter-form": {
    alignItems: "center",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "start",
      gap: "20px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      width: "100%",
    },
  },
  "& .MuiToolbar-root .MuiToolbar-root": {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "unset",
    },
  },
  "& .RaFilter-form span": {
    [theme.breakpoints.down("lg")]: {
      // display: 'none'
    },
  },
}));

export const StyledRichTextInput = styled(RichTextInput, {
  name: "RaRichTextInput",
  overridesResolver: (props, styles) => styles.root,
})({
  "& .ProseMirror": {
    borderRadius: "8px !important",
    borderColor: "transparent !important",
    backgroundColor: "white !important",
    boxShadow: "0px 0px 6px #0000000D !important",
  },
  "& .RaRichTextInput-editorContent .tiptap": {
    minHeight: 200,
  },
});

export const StyledNumberInput = styled(NumberInput, {
  name: "RaNumberInput",
  overridesResolver: (props, styles) => styles.root,
})({
  marginTop: 0,
  background: "white",
  borderRadius: "8px",
  "& .MuiOutlinedInput-notchedOutline legend": {
    display: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 0px 6px #0000000D",
    borderRadius: "8px",
    borderColor: "transparent !important",
    top: 0,
  },
  "& .MuiFormControl-root": {
    borderRadius: "8px",
  },
  "& fieldset:focus": {
    boxShadow: "0px 0px 6px #0000000D",
    borderColor: "white !important",
  },
  "& .MuiSvgIcon-root": {
    paddingRight: "12px",
  },
  "& .RaResettableTextField-clearIcon": {
    padding: 0,
  },
});

export const buttons = {
  darkButton: {
    background: "black",
    borderRadius: "8px",
    height: "40px",
    textTransform: "capitalize",
    color: "white",
    fontSize: "14px",
    "&:hover": {
      background: "black",
    },
    padding: "9px 14px",
  },
  whiteButton: {
    background: "white",
    boxShadow: "0px 0px 6px #0000000D",
    textTransform: "capitalize",
    borderRadius: "8px",
    border: "none",
    height: "40px",
    color: "black",
    padding: "9px 14px",
    fontSize: "14px",
    "&:hover": {
      background: "white",
      border: "none",
    },
  },
  button: {
    boxShadow: "0px 0px 6px #0000000D",
    textTransform: "capitalize",
    borderRadius: "8px",
    border: "none",
    height: "40px",
    padding: "9px 14px",
    fontSize: "14px",
  },
};

export const listTopFilter = {
  topToolbar: {
    rightFilters: {
      "& .MuiButtonBase-root": buttons.whiteButton,
      "& .MuiButtonBase-root.create-button": buttons.darkButton,
      "&.MuiToolbar-root": {
        gap: "16px !important",
      },
    },
  },
  list: {
    container: {
      "& .MuiTableBody-root": {
        borderBottom: "2px solid rgba(0, 0, 0, 0.08)",
      },
      "& .MuiPaper-root": {
        marginTop: "50px",
        boxShadow: "none",
        borderRadius: "8px 8px 0 0",
      },
      "&.styled-list .MuiPaper-root": {
        overflowX: "auto",
      },
      "& .MuiFormControl-root": {
        borderRadius: "8px",
        marginBottom: 0,
      },
      "& .MuiTableCell-root": {
        borderBottom: "none",
      },
      "& .MuiTableCell-root .MuiTypography-root": {
        fontWeight: "normal",
        color: "black",
      },
      "& .MuiTableHead-root": {
        borderBottom: "4px solid rgba(0, 0, 0, 0.08)",
      },
      "& .MuiTableHead-root .MuiTableCell-head": {
        fontWeight: "bold",
      },
      "& .list-select-input": {
        backgroundColor: "white",
      },
      "& .list-select-input fieldset legend": {
        display: "none",
      },
      "& .list-select-input fieldset": {
        top: 0,
        borderColor: "white",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        background: "white",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend": {
        display: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        boxShadow: "0px 0px 6px #0000000D",
        borderRadius: "8px",
        borderColor: "transparent !important",
        top: 0,
      },
      "& .MuiAutocomplete-root .RaAutocompleteInput-textField": {
        background: "white",
      },
      "& .MuiAutocomplete-root fieldset legend": {
        display: "none",
      },
    },
  },
  pagination: {
    "&.MuiTablePagination-root": {
      backgroundColor: "white",
      borderRadius: "0 0 8px 8px",
    },
    "&.MuiTablePagination-root .MuiInputBase-root": {
      border: "1px solid rgba(0, 0, 0, 0.08)",
      borderRadius: "6px",
      height: "36px",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "8px",
      width: "36px",
      height: "36px",
      background: "transparent",
      border: "1px solid rgba(0, 0, 0, 0.08)",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#000000",
      color: "white",
    },
    "& .MuiPaginationItem-root.Mui-selected:hover": {
      backgroundColor: "#000000",
    },
    "& .MuiPaginationItem-root.Mui-disabled": {
      opacity: 1,
    },
    "& .MuiTablePagination-displayedRows": {
      display: "none",
    },
    "& .MuiPagination-ul li": {
      marginRight: "5px",
    },
  },
};

export const StyledAutocompleteInput = styled(AutocompleteInput, {
  name: "RaAutocompleteInput",
  overridesResolver: (props, styles) => styles.root,
})({
  marginTop: 0,
  background: "white",
  borderRadius: "8px",
  "& .MuiOutlinedInput-notchedOutline legend": {
    display: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 0px 6px #0000000D",
    borderRadius: "8px",
    borderColor: "transparent !important",
    top: 0,
  },
  "& .MuiFormControl-root": {
    borderRadius: "8px",
  },
  "& fieldset:focus": {
    boxShadow: "0px 0px 6px #0000000D",
    borderColor: "white !important",
  },
  "& .MuiSvgIcon-root": {
    paddingRight: "12px",
  },
  "& .RaResettableTextField-clearIcon": {
    padding: 0,
  },
});

export const StyledSelect = styled(SelectInput, {
  name: "RaSelectInput",
  overridesResolver: (props, styles) => styles.root,
})({
  marginTop: 0,
  background: "white",
  borderRadius: "8px",
  "& .MuiOutlinedInput-notchedOutline legend": {
    display: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 0px 6px #0000000D",
    borderRadius: "8px",
    borderColor: "transparent !important",
    top: 0,
  },
  "& .MuiFormControl-root": {
    borderRadius: "8px",
  },
  "& fieldset:focus": {
    boxShadow: "0px 0px 6px #0000000D",
    borderColor: "white !important",
  },
  "& .MuiSvgIcon-root": {
    paddingRight: "12px",
  },
  "& .RaResettableTextField-clearIcon": {
    padding: 0,
  },
  "& .MuiFormHelperText-root": {
    display: "none",
  },
});

export const StyledEditButton = styled(EditButton, {
  name: "RaCreateButton",
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  background: "white",
  boxShadow: "0px 0px 6px #0000000D",
  textTransform: "capitalize",
  borderRadius: "8px",
  border: "none",
  height: "40px",
  color: "black",
  padding: "9px 14px",
  fontSize: "14px",
  "&:hover": {
    background: "white",
    border: "none",
  },
}));

export const StyledSaveButton = styled(SaveButton, {
  name: "RaCreateButton",
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  background: "white",
  boxShadow: "0px 0px 6px #0000000D",
  textTransform: "capitalize",
  borderRadius: "8px",
  border: "none",
  height: "40px",
  color: "black",
  padding: "9px 14px",
  fontSize: "14px",
  "&:hover": {
    background: "white",
    border: "none",
  },
}));

export const StyledArraySelect = styled(SelectArrayInput, {
  name: "RaSelectInput",
  overridesResolver: (props, styles) => styles.root,
})({
  marginTop: 0,
  background: "white",
  borderRadius: "8px",
  "& .MuiOutlinedInput-notchedOutline legend": {
    display: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 0px 6px #0000000D",
    borderRadius: "8px",
    borderColor: "transparent !important",
    top: 0,
  },
  "& .MuiFormControl-root": {
    borderRadius: "8px",
  },
  "& fieldset:focus": {
    boxShadow: "0px 0px 6px #0000000D",
    borderColor: "white !important",
  },
  "& .MuiSvgIcon-root": {
    paddingRight: "12px",
  },
  "& .RaResettableTextField-clearIcon": {
    padding: 0,
  },
  "& .MuiFormHelperText-root": {
    display: "none",
  },
});
