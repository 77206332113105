import React, { useMemo } from "react";
import DialogContent from "@mui/material/DialogContent";
import {
  StyledNumberInput,
  StyledSelect,
  StyledTextInput,
} from "../../../../themes/styles";
import { Button, Form, maxValue, minValue, required } from "react-admin";
import DialogActions from "@mui/material/DialogActions";
import { IRefundRequest } from "../../../../types/interfaces/admin-parking/refund-request.interface";

type Props = {
  handleSubmit: (data: Partial<IRefundRequest>) => void;
  amount: number | undefined;
  refundAmount: number | null | undefined;
  isLoading: boolean;
};

const CreateRefundForm = (props: Props) => {
  const { handleSubmit, amount, refundAmount, isLoading } = props;

  const maxRefundValue = useMemo(() => {
    if (amount) {
      return refundAmount ? amount / 100 - refundAmount / 100 : amount / 100;
    }
  }, [amount, refundAmount]);

  const formattedDefaultValue = useMemo(() => {
    if (amount) {
      return amount / 100;
    }
  }, [amount]);

  return (
    <Form onSubmit={(data: Partial<IRefundRequest>) => handleSubmit(data)}>
      <DialogContent>
        <StyledNumberInput
          fullWidth
          source="amount"
          label="app.parking.amount"
          format={(value: number) => value.toFixed(2)}
          parse={(value: string) => parseFloat(value)}
          defaultValue={formattedDefaultValue}
          validate={[minValue(0), maxValue(maxRefundValue)]}
        />
        <StyledTextInput
          fullWidth
          source="reasonDetails"
          label="app.parking.description_of_reason"
          variant="outlined"
        />
        <StyledSelect
          fullWidth
          variant="outlined"
          source="reason"
          label="app.parking.reason"
          validate={required()}
          choices={[
            { id: "duplicate", name: "app.parking.duplicate" },
            { id: "other", name: "app.parking.other" },
            {
              id: "requested_by_customer",
              name: "app.parking.requested_by_customer",
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          label="app.parking.create"
          variant="outlined"
          disabled={isLoading}
        />
      </DialogActions>
    </Form>
  );
};

export default CreateRefundForm;
