import React, { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import { styled } from "@mui/material/styles";
import { ConfigManager } from "../../constants/ConfigManager";

const Image = styled("img")(() => ({ maxWidth: "100px", height: "100%" }));
const ImageWrapper = styled("div")(() => ({
  color: "#fff",
  width: "100%",
  textDecoration: "none",
  backgroundColor: "grey",
  padding: 0,
}));

const CustomImageField = () => {
  const record = useRecordContext();
  const [src, setSrc] = useState<string>("");

  useEffect(() => {
    if (record?.src) {
      setSrc(record.src);
    } else {
      setSrc(
        `${ConfigManager.getInstance().getApiUrl()}/api/v1/storage/files/${record?.iconId}`,
      );
    }
  }, []);

  return (
    <ImageWrapper>
      <Image crossOrigin="anonymous" src={src} alt="Image" />
    </ImageWrapper>
  );
};

export default CustomImageField;
