import React, { useEffect, useState, useContext, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  Stripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { ConfigManager } from "../../../../constants/ConfigManager";
import { ParkingContext } from "../../../../services/parking/parkingContext";
import StripePaymentElement from "./StripePaymentElement";

const StripePaymentComponent = () => {
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null);

  const { parkingPaymentParams } = useContext(ParkingContext);
  const initStripe = async () => {
    const stripeObjectData = await loadStripe(
      ConfigManager.getInstance().getStripeKey() as string,
    );
    setStripeObject(stripeObjectData);
  };

  useEffect(() => {
    initStripe();
  }, []);

  const appearance: Appearance = {
    theme: "stripe",
  };

  const options: StripeElementsOptions = useMemo(() => {
    return {
      clientSecret: parkingPaymentParams?.paymentIntentClientSecret,
      appearance,
      customerOptions: {
        customer: parkingPaymentParams?.customerId ?? "",
        ephemeralKey: parkingPaymentParams?.customerEphemeralKeySecret ?? "",
      },
    };
  }, []);

  return (
    <>
      {parkingPaymentParams && (
        <Elements stripe={stripeObject} options={options}>
          <StripePaymentElement />
        </Elements>
      )}
    </>
  );
};

export default StripePaymentComponent;
