import React, { DragEvent } from "react";
import { Node } from "reactflow";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StatusContainer = styled(Box)(() => ({
  padding: "10px 20px",
  border: "1px solid black",
  maxWidth: "300px",
  cursor: "pointer",
  textAlign: "center",
  borderRadius: "3px",
}));

const Container = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
  padding: "20px",
}));

const SidePanel = ({ availableNodes }: { availableNodes: Node[] }) => {
  const onDragStart = (event: DragEvent<HTMLDivElement>, data: Node) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(data));
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <aside>
      <Container>
        {availableNodes.map((node: Node) => (
          <StatusContainer
            onDragStart={(event) => onDragStart(event, node)}
            draggable
          >
            {node?.data.label}
          </StatusContainer>
        ))}
      </Container>
    </aside>
  );
};

export default SidePanel;
