import React, { useEffect, useState } from "react";
import { DialogTitle } from "@mui/material";
import {
  Button,
  Form,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import { buttons, StyledAutocompleteInput } from "../../../../themes/styles";
import DialogActions from "@mui/material/DialogActions";
import IconSave from "@mui/icons-material/Save";
import IconCancel from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import { userService } from "../../../../api/UserService";
import { Roles } from "../../../../types/enums/roles";
import { IBasicResponse } from "../../../../types/interfaces/response/basic-response.interface";
import { IUser } from "../../../../types/interfaces/user.interface";
import { helpdeskTicketsService } from "../../../../api/HelpdeskTicketsService";
import { ITicket } from "../../../../types/interfaces/helpdesk/ticket/ticket.interface";

type Props = { isOpen: boolean; close: () => void };

type User = {
  id: string;
  name: string;
};

const AssignToUserDialog = ({ close, isOpen }: Props) => {
  const translate = useTranslate();
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);

  const refresh = useRefresh();
  const ticket = useRecordContext<ITicket>();
  const notify = useNotify();

  const handleSubmit = async (data: any) => {
    const assigneeData = {
      assigneeId: data.assigneeId,
    };
    try {
      await helpdeskTicketsService.updateTicket(ticket.id, assigneeData);
      refresh();
      close();
    } catch (e) {
      notify(
        e?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await userService.getUserList<IBasicResponse<IUser[]>>({
        roles: [Roles.ROLE_MODULE_HELPDESK_ASSIGNEE],
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const createAvailableUsersList = async () => {
    const users = await fetchUsers();

    if (!users) {
      setAvailableUsers([]);
      return;
    }

    setAvailableUsers(
      users.map((item: IUser) => ({
        id: item.id,
        name: item.firstName + " " + item.lastName,
      })),
    );
  };

  useEffect(() => {
    createAvailableUsersList();
  }, []);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      transitionDuration={200}
      onClose={() => close()}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {translate("app.helpdesk.assignee_to_user")}
      </DialogTitle>
      <Form key="add-attachment-form" onSubmit={(data) => handleSubmit(data)}>
        <DialogContent>
          <StyledAutocompleteInput
            source="assigneeId"
            label="app.helpdesk.choose_user"
            choices={availableUsers || []}
            defaultValue={""}
            variant="outlined"
            style={{ width: "100%" }}
            validate={required()}
          />
        </DialogContent>

        <DialogActions
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Button label="ra.action.save" type="submit" sx={buttons.darkButton}>
            <IconSave />
          </Button>
          <Button
            label="ra.action.cancel"
            onClick={() => close()}
            sx={buttons.whiteButton}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default AssignToUserDialog;
