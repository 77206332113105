import React from "react";
import { Box, Grid } from "@mui/material";
import { itemInfoComponent, StyledShow } from "../../../themes/styles";
import {
  TextField,
  useTranslate,
  useShowController,
  useAuthenticated,
  FunctionField,
  DateField,
} from "react-admin";
import { ITransaction } from "../../../types/interfaces/admin-parking/transaction.interface";

const TransactionsShow = () => {
  useAuthenticated();
  const translate = useTranslate();
  const { record } = useShowController<ITransaction>();

  return (
    <StyledShow>
      <Box sx={itemInfoComponent}>
        <Grid container spacing={3} sx={{ ml: 0, mt: 0, mb: 2, p: 3 }}>
          <Grid className="item-info__container" item xs={5}>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.transaction_type")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  label="app.parking.transaction_type"
                  render={(record: ITransaction) => (
                    <span className="item-info__value">
                      {translate(`app.parking.${record.type}`)}
                    </span>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.transaction_status")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  label="app.parking.transaction_status"
                  render={(record: ITransaction) => (
                    <span className="item-info__value">
                      {translate(
                        `app.parking.transaction_statuses.${record.status}`,
                      )}
                    </span>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.amount")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  render={(record: ITransaction) => (
                    <span className="item-info__value">
                      {`${(record.amount / 100).toFixed(2)} ${record.currency}`}
                    </span>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.created_at")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <DateField source="createdAt" className="item-info__value" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.plate_number")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <TextField source="plateNumber" className="item-info__value" />
              </Grid>
            </Grid>

            {record?.user?.email && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">Email</span>
                </Grid>
                <Grid item xs={6}>
                  <TextField source="user.email" className="item-info__value" />
                </Grid>
              </Grid>
            )}
            {record?.user?.firstName && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.parking.user")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <FunctionField
                    render={(record: ITransaction) => (
                      <span className="item-info__value">
                        {`${record.user?.firstName} ${record.user?.lastName}`}
                      </span>
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {record?.user?.phoneNumber && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.parking.user_phone_number")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    source="user.phoneNumber"
                    className="item-info__value"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </StyledShow>
  );
};

export default TransactionsShow;
