import { ConfigManager } from "../constants/ConfigManager";
import { AxiosRequestConfig } from "axios";
import { apiRequest } from "./client";

const createMobileModulePage = async <T>(
  data: any,
  moduleId: string | undefined,
) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/mobile/admin/${orgId}/modules/${moduleId}/pages`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const updateMobileModulePage = async <T>(
  data: any,
  moduleId: string | undefined,
  pageId: string,
) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/mobile/admin/${orgId}/modules/${moduleId}/pages/${pageId}`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const mobileModulesPageService = {
  createMobileModulePage,
  updateMobileModulePage,
};
