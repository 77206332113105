import React from "react";
import {
  BooleanInput,
  DeleteButton,
  Edit,
  required,
  SaveButton,
  SimpleForm,
  useRecordContext,
  useTranslate,
  useAuthenticated,
} from "react-admin";
import {
  buttons,
  StyledArraySelect,
  StyledTextInput,
} from "../../../themes/styles";
import { Toolbar } from "@mui/material";

const UserEditFormToolbar = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        confirmContent={
          <div>
            {translate("app.auth.delete_confirm", {
              name: record?.firstName + " " + record?.lastName,
            })}
            ?
          </div>
        }
        confirmTitle={<div>{translate("app.auth.confirmation")}</div>}
      />
    </Toolbar>
  );
};

const UsersEdit = () => {
  useAuthenticated();
  return (
    <Edit
      redirect="show"
      mutationMode="pessimistic"
      sx={{
        "& .MuiButton-text, .MuiButton-root": buttons.whiteButton,
        "& .RaEdit-main": {
          paddingTop: "40px",
        },
      }}
    >
      <SimpleForm sx={{ paddingTop: "20px" }} toolbar={<UserEditFormToolbar />}>
        <StyledTextInput
          name="firstName"
          variant="outlined"
          source="firstName"
          label="app.auth.name"
          validate={required()}
        />

        <StyledTextInput
          name="email"
          variant="outlined"
          source="email"
          label="app.auth.email"
          validate={required()}
        />

        <StyledTextInput
          name="lastName"
          variant="outlined"
          source="lastName"
          label="app.auth.surname"
          validate={required()}
        />

        <StyledTextInput
          name="phoneNumber"
          variant="outlined"
          source="phoneNumber"
          label="app.auth.phoneNumber"
          validate={required()}
        />

        <BooleanInput
          name="active"
          source="active"
          variant="outlined"
          label="app.auth.active"
        />

        <StyledArraySelect
          variant="outlined"
          name="roles"
          choices={[
            { id: "ADMIN", name: "ADMIN" },
            { id: "CUSTOMER", name: "CUSTOMER" },
            { id: "EMPLOYEE", name: "EMPLOYEE" },
          ]}
          source="roles"
        />
      </SimpleForm>
    </Edit>
  );
};

export default UsersEdit;
