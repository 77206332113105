import jwtDecode from "jwt-decode";
import { JwtData } from "../types/JwtData";
export const isTokenValid = (token: string) => {
  const tokenData: JwtData = jwtDecode(token);
  const expirationDate = new Date(tokenData.exp * 1000);

  return expirationDate > new Date();
};

export const fetchUserDataFromToken = (token: string) => {
  const tokenData: JwtData = jwtDecode(token);
  const { given_name, family_name, email } = tokenData;

  return {
    firstName: given_name,
    lastName: family_name,
    userEmail: email,
  };
};
