import React, { useContext, useCallback } from "react";
import Przelewy24PaymentComponent from "./Przelewy24PaymentComponent";
import { PaymentContext } from "../../../../services/payment/PaymentContext";
import { PaymentService } from "../../../../types/enums/payment-service.enum";
import StripePaymentComponent from "./StripePaymentComponent";

const BasicPaymentComponent = () => {
  const { paymentService } = useContext(PaymentContext);

  const getPaymentService = useCallback(() => {
    switch (paymentService) {
      case PaymentService.PRZELEWY24:
        return <Przelewy24PaymentComponent />;
      case PaymentService.STRIPE:
        return <StripePaymentComponent />;
      default:
        break;
    }
  }, [paymentService]);

  return <>{getPaymentService()}</>;
};

export default BasicPaymentComponent;
