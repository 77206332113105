import * as React from "react";
import DashboardIcon from "../icons/DashboardIcon";
import { DashboardMenuItem, DashboardMenuItemProps } from "react-admin";
import { useBasename } from "ra-core";
import { standardLayoutStyles } from "../../themes/styles";

const StandardDashboardMenuItem = (
  props: DashboardMenuItemProps & { menuOpen: boolean },
) => {
  const basename = useBasename();

  const {
    leftIcon = <DashboardIcon color={!props.menuOpen ? "black" : "#606060"} />,
    to = `${basename}/`,
    primaryText = "ra.page.dashboard",
    title = "Start",
    menuOpen,
    ...rest
  } = props;

  return (
    <>
      {menuOpen && <h3>{title}</h3>}
      <DashboardMenuItem
        primaryText={menuOpen ? primaryText : ""}
        leftIcon={leftIcon}
        to={to}
        {...rest}
        sx={standardLayoutStyles.dashboardMenuItem}
      />
    </>
  );
};

export default StandardDashboardMenuItem;
