import AttributesList from "./AttributesList";
import AttributesCreate from "./AttributesCreate";
import AttributesEdit from "./AttributesEdit";

const attributesResource = {
  list: AttributesList,
  create: AttributesCreate,
  edit: AttributesEdit,
};

export default attributesResource;
