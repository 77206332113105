import * as React from "react";

const LogoutIcon = ({ size = 20, color = "#606060" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18.692 18"
    >
      <path
        id="Path_339"
        data-name="Path 339"
        d="M41,32a9,9,0,1,0,5.133,16.377c.243-.173.471-.352.692-.541h.005c0-.005,0-.005.005-.005q.3-.26.584-.552L47.5,47.2c.065-.065.135-.13.195-.2a1.057,1.057,0,0,0,.092-.124,9.044,9.044,0,0,0,.784-1.028h-1.7c-.157.189-.33.362-.5.535l-.243.243c-.005,0-.005.005-.011.005a7.24,7.24,0,0,1-.681.552,7.615,7.615,0,1,1-.005-12.37,8.137,8.137,0,0,1,.714.579c.059.054.108.108.162.168a6.542,6.542,0,0,1,.568.595h.211a.762.762,0,0,0,.222,0h1.271a9.9,9.9,0,0,0-.79-1.038c-.027-.038-.054-.081-.087-.119s-.087-.081-.124-.124c-.1-.108-.211-.211-.319-.319s-.2-.195-.3-.287c-.119-.108-.243-.206-.368-.308-.119-.087-.227-.184-.346-.27a.824.824,0,0,1-.087-.059v.005A8.955,8.955,0,0,0,41,32Zm6.231,5.538v2.769H37.538v1.385h9.692v2.769L50.692,41Z"
        transform="translate(-32 -32)"
        fill={color}
      />
    </svg>
  );
};

export default LogoutIcon;
