import { AxiosRequestConfig } from "axios";
import { apiRequest } from "./client";
import { ConfigManager } from "../constants/ConfigManager";
import { ICreateTicket } from "../types/interfaces/helpdesk/ticket/create-ticket.interface";
import { EResource, getResourceUrl } from "../utils/resourcesHelper";

const uploadTemporaryAttachment = async <T>(attachment: FormData) => {
  const url = getResourceUrl(EResource.TICKET_TEMPORARY_ATTACHMENT);

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/${url}`,
      data: attachment,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return await apiRequest<T>(config);
  } catch (e) {
    console.log(e, "uploadTemporaryAttachment");
    throw e;
  }
};

const uploadAttachment = async <T>(
  attachment: { attachmentId: string } | null,
  ticketId: string,
) => {
  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/tickets/${ticketId}/attachments`,
      data: attachment,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    console.log(e, "uploadAttachment");
    throw e;
  }
};

const createTicket = async <T>(data: ICreateTicket) => {
  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/tickets`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    console.log(e, "createTicket");
    throw e;
  }
};

const getTicketComments = async <T>(ticketId: string | undefined) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/admin/${orgId}/tickets/${ticketId}/comments`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const getTicketHistory = async <T>(ticketId: string | undefined) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/admin/${orgId}/tickets/${ticketId}/history`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const getTicketAttachments = async <T>(ticketId: string | undefined) => {
  const url = getResourceUrl(EResource.TICKET_ATTACHMENTS, { ticketId });

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/${url}`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const getHelpdeskStatuses = async <T>() => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/statuses`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const createTicketComment = async <T>(
  ticketId: string | undefined,
  data: { comment: string },
) => {
  const url = getResourceUrl(EResource.TICKETS_COMMENT, { ticketId });

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/${url}`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const updateTicket = async <T>(
  ticketId: string,
  data: { assigneeId: string },
) => {
  const orgId = ConfigManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/admin/${orgId}/tickets/${ticketId}`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const helpdeskTicketsService = {
  uploadTemporaryAttachment,
  getTicketHistory,
  getTicketComments,
  createTicketComment,
  createTicket,
  getTicketAttachments,
  uploadAttachment,
  getHelpdeskStatuses,
  updateTicket,
};
