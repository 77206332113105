import { AxiosRequestConfig } from "axios";
import { apiRequest } from "./client";
import { ConfigManager } from "../constants/ConfigManager";

const resetUserPassword = async (
  data: { password: string },
  userId: string | number,
) => {
  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/admin/${ConfigManager.getInstance().getOrgId()}/users/${userId}/password`,
      data,
    };
    return await apiRequest(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const AdminService = {
  resetUserPassword,
};
