import React from "react";
import {
  itemInfoComponent,
  StyledDatagrid,
  StyledList,
} from "../../../themes/styles";
import { Grid } from "@mui/material";
import {
  ArrayField,
  FunctionField,
  Show,
  TextField,
  useAuthenticated,
  usePermissions,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { IVehicleDetail } from "../../../types/interfaces/admin-parking/vehicle-detail.interface";
import TransactionsTable from "../../../components/resources/admin-parking/transactions/TransactionsTable";
import ExtendParkingTime from "../../../components/resources/admin-parking/vehilces/ExtendParkingTime";
import { Roles } from "../../../types/enums/roles";

const TransactionsList = () => {
  const record = useRecordContext();

  return (
    <StyledList
      resource="parking/admin/organization/transactions"
      queryOptions={{ meta: { plateNumber: record.plateNumber } }}
      sort={{ field: "createdAt", order: "DESC" }}
      className="styled-list"
      sx={{
        "& .MuiPaper-root": { marginTop: "0 !important" },
      }}
      actions={false}
    >
      <TransactionsTable />
    </StyledList>
  );
};

const VehiclesShow = () => {
  useAuthenticated();
  const translate = useTranslate();
  const { permissions } = usePermissions();

  return (
    <Show>
      <Grid sx={itemInfoComponent}>
        <Grid container sx={{ ml: 0, mt: 0, mb: 2, p: 3 }} rowSpacing={2}>
          <Grid className="item-info__container" item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <span className="item-info__title">
                  {translate("app.parking.plate_number")}
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField source="plateNumber" className="item-info__value" />
              </Grid>
            </Grid>
          </Grid>
          <Grid className="item-info__container" item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <span className="item-info__title">
                  {translate("app.parking.registered_vehicles")}
                </span>
              </Grid>
              <Grid item xs={12}>
                <FunctionField
                  render={(record: IVehicleDetail) => {
                    if (record.registeredVehicles.length === 0) {
                      return (
                        <b>{translate("app.parking.vehicles_not_found")}</b>
                      );
                    }
                    return (
                      <ArrayField source="registeredVehicles">
                        <StyledDatagrid bulkActionButtons={false} width="100%">
                          <TextField source="brand" label="app.parking.brand" />
                          <TextField source="model" label="app.parking.model" />
                          <TextField source="color" label="app.parking.color" />
                        </StyledDatagrid>
                      </ArrayField>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {permissions?.includes(Roles.ROLE_MODULE_PARKING_MANAGER) && (
            <Grid className="item-info__container" item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <span className="item-info__title">
                    {translate("app.parking.transactions")}
                  </span>
                  <ExtendParkingTime />
                </Grid>

                <Grid item xs={12}>
                  <TransactionsList />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Show>
  );
};

export default VehiclesShow;
