import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import Navbar from "./navbar";
import { ParkingContext } from "../../../services/parking/parkingContext";
import { Link, useParams } from "react-router-dom";

const PageContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  position: "relative",
  flexDirection: "column",
  padding: "0px !important",

  [theme.breakpoints.down("md")]: {
    justifyContent: "start",
    paddingTop: "100px !important",
  },
}));

const CustomLogo = styled(Box)(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  marginTop: "130px",
  padding: "20px 100px",
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    marginTop: "50px",
    padding: "10px 60px",
  },

  [theme.breakpoints.down("md")]: {
    padding: "10px 20px",
  },
}));

const StaticPageTemplate = ({ children }: { children: React.ReactNode }) => {
  const { customerLogo } = useContext(ParkingContext);
  const { orgId } = useParams();

  return (
    <PageContainer maxWidth={false}>
      {customerLogo && (
        <CustomLogo>
          <Link to={`/parking/prepaid/${orgId}/steps/1`}>
            <img src={customerLogo} alt="Logo" style={{ maxWidth: "120px" }} />
          </Link>
        </CustomLogo>
      )}
      <Navbar />
      <ContentContainer>{children}</ContentContainer>
    </PageContainer>
  );
};

export default StaticPageTemplate;
