import React from "react";
import {
  Button,
  DateField,
  FunctionField,
  TopToolbar,
  useAuthenticated,
  useShowController,
  useTranslate,
  useRedirect,
  resolveBrowserLocale,
  useNotify,
} from "react-admin";
import { buttons, itemInfoComponent, StyledShow } from "../../../themes/styles";
import { Box, Grid } from "@mui/material";
import { ITransaction } from "../../../types/interfaces/admin-parking/transaction.interface";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { dataProvider } from "../../../providers/dataProvider";

const ReservationsShowActions = () => {
  const redirect = useRedirect();
  const { record } = useShowController();
  const translate = useTranslate();
  const notify = useNotify();

  const reservationCancelHandler = async () => {
    try {
      await dataProvider.delete("parking/admin/organization/reservations", {
        id: record.id,
      });

      notify(translate("app.parking.reservation_was_canceled"), {
        type: "success",
      });
      redirect("list", "parking/admin/organization/reservations");
    } catch (e) {
      notify(e?.message, {
        type: "error",
      });
    }
  };

  const reservationEditHandler = () => {
    redirect("edit", "parking/admin/organization/reservations", record.id);
  };

  if (new Date(record?.to) < new Date()) {
    return <></>;
  }

  return (
    <TopToolbar className="list-topbar">
      <Button
        sx={buttons.whiteButton}
        key={`action-edit`}
        color="primary"
        label="app.parking.edit"
        onClick={reservationEditHandler}
      >
        <EditIcon />
      </Button>
      <Button
        sx={buttons.whiteButton}
        key={`action-cancel`}
        color="primary"
        label="app.parking.cancel"
        onClick={reservationCancelHandler}
      >
        <CancelIcon />
      </Button>
    </TopToolbar>
  );
};

const ReservationsShow = () => {
  useAuthenticated();

  const translate = useTranslate();
  const { record } = useShowController();

  return (
    <StyledShow actions={<ReservationsShowActions />}>
      <Box sx={itemInfoComponent}>
        <Grid container spacing={3} sx={{ ml: 0, mt: 0, mb: 2, p: 3 }}>
          <Grid className="item-info__container" item xs={5}>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.plate_number")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <span className="item-info__value">
                    {record.vehicle.plateNumber}
                  </span>
                </Box>
              </Grid>
            </Grid>
            {record?.user?.firstName && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.parking.user")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <FunctionField
                    render={(record: ITransaction) => (
                      <span className="item-info__value">
                        {`${record.user?.firstName} ${record.user?.lastName}`}
                      </span>
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.reservationFrom")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <DateField
                    source="from"
                    className="item-info__value"
                    locales={resolveBrowserLocale()}
                    options={{ timeZone: "utc" }}
                    showTime={true}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.reservationTo")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <DateField
                    source="to"
                    className="item-info__value"
                    locales={resolveBrowserLocale()}
                    options={{ timeZone: "utc" }}
                    showTime={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledShow>
  );
};

export default ReservationsShow;
