import axios, { AxiosRequestConfig } from "axios";
import { apiRequest } from "./client";
import { ConfigManager } from "../constants/ConfigManager";

const refreshToken = async () => {
  try {
    const token = localStorage.getItem("refreshToken");

    const response = await axios.post(
      `${ConfigManager.getInstance().getApiUrl()}/api/v1/auth/refresh`,
      {
        refreshToken: token,
      },
    );

    return {
      accessToken: response.data.data.accessToken,
      refreshToken: response.data.data.refreshToken,
    };
  } catch (e) {
    console.log(e);
  }
};

const resetPassword = async (data: {
  email: string;
  code: string;
  password: string;
  groupId: string | undefined;
}) => {
  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/users/account/reset-password`,
      data,
    };
    return await apiRequest(config);
  } catch (e) {
    console.log(e, "resetPassword");
    throw e.response?.data?.error;
  }
};

export const AuthService = {
  refreshToken,
  resetPassword,
};
