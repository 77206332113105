import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Title = styled(Box)(() => ({
  fontSize: "18px",
  marginBottom: "30px",
  fontWeight: "bold",
}));

export const ItemContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "30px",
}));

export const ItemTitle = styled(Box)(() => ({
  fontSize: "14px",
  fontWeight: "bold",
}));

export const ItemContent = styled(Box)(() => ({
  fontSize: "14px",
  textAlign: "justify",
  lineHeight: "24px",
}));
