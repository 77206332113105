export const isDarkColor = (color: string) => {
  // Convert hex color to RGB
  const hexToRgb = (hex: string) => {
    return {
      r: parseInt(hex.substring(1, 3), 16),
      g: parseInt(hex.substring(3, 5), 16),
      b: parseInt(hex.substring(5, 7), 16),
    };
  };

  // Calculate luminance
  const rgb = hexToRgb(color);
  const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;

  // If luminance is less than or equal to 0.5, it's considered dark
  return luminance <= 0.8;
};
