export const convertMillisecondsToTime = (milliseconds: number) => {
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(milliseconds / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((milliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds
  const seconds = Math.floor((milliseconds % 60000) / 1000); // 1 second = 1000 milliseconds

  return {
    hours,
    minutes,
    seconds,
  };
};

export const parseDateToMilliseconds = (date: string) => {
  return new Date(date).getTime();
};
