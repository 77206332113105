import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { isTokenValid } from "../utils/tokenService";
import { AuthService } from "./AuthService";
import { resolveBrowserLocale } from "react-admin";

interface HeadersInterface {
  Accept: string;
  "Content-Type"?: string;
  Authorization?: string;
  "Accept-Language"?: string;
}

export const getDefaultHeaders = () => {
  const token = localStorage.getItem("token");

  const headers: HeadersInterface = {
    Accept: "application/json",
  };

  headers["Accept-Language"] = resolveBrowserLocale();
  headers["Content-Type"] = "application/json";
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};

const api = axios.create();

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (
      token &&
      !isTokenValid(token) &&
      refreshToken &&
      isTokenValid(refreshToken)
    ) {
      const data = await AuthService.refreshToken();
      config.headers["Authorization"] = `Bearer ${data?.accessToken}`;
      config.headers["Accept"] = "application/json";
      config.headers["Content-Type"] = "application/json";

      localStorage.setItem("token", data?.accessToken);
      localStorage.setItem("refreshToken", data?.refreshToken);
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export const apiRequest = async <T>(config: AxiosRequestConfig): Promise<T> => {
  config["headers"] = {
    ...getDefaultHeaders(),
    ...(config && config.headers),
  };
  const response = await api<T>(config);

  return response.data;
};
