import { IWorkflowNode } from "../../types/interfaces/helpdesk/workflow/workflow-node.interface";
import { Edge, Node } from "reactflow";
import { resolveBrowserLocale } from "react-admin";

export const getInitialNode = (nodes: IWorkflowNode[]) => {
  return nodes.find(
    (item: IWorkflowNode) => item.isInitial && item.sourceSteps.length === 0,
  );
};

export const getFinalNode = (nodes: IWorkflowNode[]) => {
  return nodes.find(
    (item: IWorkflowNode) => item.isFinal && item.destinationSteps.length === 0,
  );
};

export const areNodesValid = (nodes: IWorkflowNode[]) => {
  const initialNode = getInitialNode(nodes);
  const finalNode = getFinalNode(nodes);

  return (
    finalNode?.sourceSteps.length !== 0 &&
    initialNode?.destinationSteps.length !== 0
  );
};

export const getNodeType = (isFinal: boolean) => {
  if (isFinal) {
    return "output";
  }

  return "default";
};

/**
 * Transforms an array of edges into format suitable to API
 *
 * @param {Edge[]} newEdges - The array of edges to be transformed.
 */
export const transformEdges = (newEdges: Edge[]) => {
  return newEdges
    .filter((item: Edge) => item.id !== "initialNodeEdge")
    .map((item: Edge) => {
      const name = {
        [resolveBrowserLocale()]: item?.data?.label || "",
      };

      return {
        id: item.id,
        name,
        destination: item.target,
        source: item.source,
      };
    });
};

/**
 * Transforms an array of nodes into format suitable to API
 *
 * @param {Node[]} nodes - The array of nodes to be transformed.
 * @param {Edge[]} newEdges - The array of edges associated with the nodes.
 */
export const transformNodes = (nodes: Node[], newEdges: Edge[]) => {
  return nodes
    .filter((item: Node) => item.id !== "initialNode")
    .map((item: Node) => ({
      id: item.id,
      isInitial: item?.data?.isInitial || false,
      isFinal: item?.data?.isFinal || false,
      destinationSteps: getNodeDestinations(item.id, newEdges),
      sourceSteps: getNodeSources(item.id, newEdges),
      position: {
        x: Math.floor(item.position.x),
        y: Math.floor(item.position.y),
      },
    }));
};

/**
 * Get the destinations of a specified node from a list of edges.
 *
 * @param {string} nodeId - The ID of the node to find the destinations for.
 * @param {Edge[]} newEdges - The list of edges to search for destinations.
 * @returns {string[]} - An array of IDs representing the destinations of the node.
 */
const getNodeDestinations = (nodeId: string, newEdges: Edge[]): string[] => {
  return newEdges
    .filter(
      (item: Edge) => item.source === nodeId && item.id !== "initialNodeEdge",
    )
    .map((item: Edge) => item.id);
};

/**
 * Retrieves the source node ids for a given node id from a set of edges.
 *
 * @param {string} nodeId - The id of the node to get the sources for.
 * @param {Edge[]} newEdges - The set of edges to search for sources.
 * @returns {string[]} - An array of node ids that are source nodes for the given node id.
 */
const getNodeSources = (nodeId: string, newEdges: Edge[]): string[] => {
  return newEdges
    .filter(
      (item: Edge) => item.target === nodeId && item.id !== "initialNodeEdge",
    )
    .map((item) => item.id);
};
