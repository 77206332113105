import React, { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { ParkingContext } from "../../../../services/parking/parkingContext";
import { PaymentProcessStep } from "../../../../types/enums/payment-process-step.enum";
import { Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslate } from "react-admin";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "../../../../components/icons/CloseIcon";

const StepBackContainer = styled(Box)(({ theme }) => ({
  display: "none",
  position: "absolute",
  top: "10px",
  left: "10px",

  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const HamburgerMenu = styled(IconButton)(({ theme }) => ({
  display: "none",
  position: "absolute",
  top: "10px",
  right: "10px",

  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const MobileMenuItemLink = styled(Box)(() => ({
  textDecoration: "none",
  fontSize: "24px",
  color: "#0A0A0A",
  cursor: "pointer",
}));

const MobileMenuContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  marginTop: "100px",
}));

const STEP_BACK_STEPS = ["step-form", "step-payment"];

const MobileMenu = () => {
  const { step, orgId } = useParams();

  const [open, setState] = useState<boolean>(false);

  const { currentStep, saveCurrentStep, isFreeParkingProcess } =
    useContext(ParkingContext);

  const translate = useTranslate();

  const toggleDrawer = (open: boolean) => {
    setState(open);
  };

  const navigate = useNavigate();

  const handleStepBack = () => {
    saveCurrentStep(getPrevStep());
    navigate(-1);
  };

  const getPrevStep = () => {
    if (currentStep === PaymentProcessStep.STEP_FORM) {
      return isFreeParkingProcess
        ? PaymentProcessStep.STEP_SECOND
        : PaymentProcessStep.STEP_THIRD;
    }

    return PaymentProcessStep.STEP_FORM;
  };

  const redirectToPage = (page: string) => {
    navigate(`/parking/prepaid/${orgId}/page/${page}`);
    toggleDrawer(false);
  };

  return (
    <>
      {STEP_BACK_STEPS.includes(step as string) && (
        <StepBackContainer>
          <IconButton onClick={() => handleStepBack()}>
            <ArrowBackIosIcon fill="#0a0a0a" />
          </IconButton>
        </StepBackContainer>
      )}
      <HamburgerMenu
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={() => toggleDrawer(true)}
      >
        <MenuIcon />
      </HamburgerMenu>
      <Drawer
        onClose={() => toggleDrawer(false)}
        anchor="right"
        open={open}
        PaperProps={{ sx: { width: "100%" } }}
      >
        <Box style={{ padding: "30px 20px" }}>
          <IconButton onClick={() => toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
          <MobileMenuContainer>
            <Box>
              <MobileMenuItemLink onClick={() => redirectToPage("cennik")}>
                {translate("app.parking.menu.parking_price")}
              </MobileMenuItemLink>
            </Box>
            <Box>
              <MobileMenuItemLink onClick={() => redirectToPage("regulamin")}>
                {translate("app.parking.menu.parking_regulations")}
              </MobileMenuItemLink>
            </Box>
            <Box>
              <MobileMenuItemLink
                onClick={() => redirectToPage("polityka-prywatnosci")}
              >
                {translate("app.parking.menu.parking_privacy_policy")}
              </MobileMenuItemLink>
            </Box>
            {/*<Box>*/}
            {/*  <MobileMenuItemLink to={"/#/parking/prepaid/123"}>*/}
            {/*    {translate("app.parking.menu.all_parking")}*/}
            {/*  </MobileMenuItemLink>*/}
            {/*</Box>*/}
          </MobileMenuContainer>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileMenu;
