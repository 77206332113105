import React, { cloneElement, useCallback, useState } from "react";
import {
  listTopFilter,
  StyledList,
  StyledSelect,
  StyledTextInput,
} from "../../../themes/styles";
import {
  Button,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterProps,
  FunctionField,
  TextField,
  TopToolbar,
  useAuthenticated,
  useRedirect,
  useTranslate,
  useNotify,
} from "react-admin";
import CustomInputWrapper from "../../../components/common/CustomInputWrapper";
import SearchIcon from "@mui/icons-material/Search";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import CustomPagination from "../../../components/common/CustomPagination";
import { ITransaction } from "../../../types/interfaces/admin-parking/transaction.interface";
import { httpClient } from "../../../providers/dataProvider";
import { ConfigManager } from "../../../constants/ConfigManager";
import { IRefundRequest } from "../../../types/interfaces/admin-parking/refund-request.interface";
import RefundDialog from "../../../components/resources/admin-parking/transactions/RefundDialog";
import { parseDateToMilliseconds } from "../../../utils/timeService";

const TransactionsListActions = (props: any) => {
  const { filters } = props;

  return (
    <TopToolbar sx={listTopFilter.topToolbar.rightFilters}>
      {cloneElement(filters, { context: "button" })}
    </TopToolbar>
  );
};

const TransactionsListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <CustomInputWrapper
      source="plateNumber"
      alwaysOn
      resettable
      startIcon={<SearchIcon color="disabled" fontSize="large" />}
      placeholder="app.auth.search"
    />
    <DateInput
      source="fromDate"
      label="app.parking.from_date"
      variant="outlined"
      parse={parseDateToMilliseconds}
    />
    <DateInput
      source="toDate"
      label="app.parking.to_date"
      variant="outlined"
      parse={parseDateToMilliseconds}
    />

    <StyledTextInput
      source="firstName"
      variant="outlined"
      label="app.parking.user_first_name"
    />

    <StyledTextInput
      source="phoneNumber"
      variant="outlined"
      label="app.parking.user_phone_number"
    />

    <StyledTextInput
      source="lastName"
      variant="outlined"
      label="app.parking.user_last_name"
    />

    <StyledTextInput
      source="email"
      variant="outlined"
      label="app.parking.user_email"
    />

    <StyledSelect
      label="Status"
      source="status"
      variant="outlined"
      choices={[
        {
          id: "processing",
          name: "app.parking.transaction_statuses.processing",
        },
        { id: "failed", name: "app.parking.transaction_statuses.failed" },
        { id: "succeeded", name: "app.parking.transaction_statuses.succeeded" },
        { id: "canceled", name: "app.parking.transaction_statuses.canceled" },
      ]}
    />
  </Filter>
);

const TransactionsListView = () => {
  const [activeTransaction, setActiveTransaction] = useState<ITransaction>();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();

  const createRefund = async (data: Partial<IRefundRequest>) => {
    data.paymentId = activeTransaction?.id;
    if (data.amount) {
      data.amount = Math.round(data.amount * 100);
    }

    try {
      setIsLoading(true);
      await httpClient(
        `${ConfigManager.getInstance().getApiAdminUrl()}/parking/admin/organization/refunds`,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
      );

      redirect("list", "parking/admin/organization/refunds");
    } catch (e) {
      const message = e?.body?.error?.translatedMessage
        ? `app.parking.${e?.body?.error?.translatedMessage}`
        : "app.notifications.error.unexpected_error";
      notify(translate(message), { type: "error" });
    } finally {
      setIsLoading(true);
    }
  };

  const handleCloseDialog = useCallback(() => {
    setIsOpen(false);
    setActiveTransaction(undefined);
  }, []);

  return (
    <SkeletonLoaderList>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" label="ID" sortable={false} />
        <DateField
          source="createdAt"
          label="app.parking.created_at"
          showTime={true}
        />
        <FunctionField
          label="app.parking.user"
          render={(record: ITransaction) =>
            record.user?.firstName &&
            `${record.user.firstName} ${record.user.lastName[0]}.`
          }
        />
        <TextField
          source="currency"
          label="app.parking.currency"
          sortable={false}
        />
        <FunctionField
          label="app.parking.amount"
          render={(record: ITransaction) => (record.amount / 100).toFixed(2)}
        />
        <TextField
          source="plateNumber"
          label="app.parking.plate_number"
          sortable={false}
        />
        <FunctionField
          label="app.parking.transaction_type"
          render={(record: ITransaction) =>
            translate(`app.parking.${record.type}`)
          }
        />

        <FunctionField
          label="app.parking.transaction_status"
          render={(record: ITransaction) =>
            translate(`app.parking.transaction_statuses.${record.status}`)
          }
        />

        <FunctionField
          render={(record: ITransaction) => {
            if (!record.isRefundable) {
              return <></>;
            }

            return (
              <Button
                label="app.parking.refund"
                onClick={(event: any) => {
                  event.stopPropagation();
                  setIsOpen(true);
                  setActiveTransaction(record);
                }}
              />
            );
          }}
        />
      </Datagrid>
      <RefundDialog
        isOpen={isOpen}
        isLoading={isLoading}
        handleClose={handleCloseDialog}
        handleSubmit={createRefund}
        transaction={activeTransaction}
      />
    </SkeletonLoaderList>
  );
};

const TransactionsListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const TransactionsList = () => {
  useAuthenticated();

  return (
    <StyledList
      className="styled-list"
      filters={<TransactionsListFilter />}
      actions={<TransactionsListActions />}
      pagination={<TransactionsListPagination />}
      exporter={false}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <TransactionsListView />
    </StyledList>
  );
};

export default TransactionsList;
