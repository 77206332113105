import React, { useContext } from "react";
import Stopwatch from "../../../components/Stopwatch";
import { ParkingContext } from "../../../services/parking/parkingContext";

const ParkingStopwatch = () => {
  const {
    stopwatchSeconds,
    stopwatchMinutes,
    stopwatchHours,
    setStopwatchMinutes,
    setStopwatchSeconds,
    setStopwatchHours,
    isActiveStopwatch,
    setIsActiveStopwatch,
  } = useContext(ParkingContext);

  return (
    <Stopwatch
      seconds={stopwatchSeconds}
      minutes={stopwatchMinutes}
      hours={stopwatchHours}
      setMinutes={setStopwatchMinutes}
      setSeconds={setStopwatchSeconds}
      setHours={setStopwatchHours}
      setIsActiveStopwatch={setIsActiveStopwatch}
      isActiveStopwatch={isActiveStopwatch}
    />
  );
};

export default ParkingStopwatch;
