import React, { useContext } from "react";
import Navbar from "./navbar";
import { PageContainer } from "../prepaid/styles";
import Footer from "./Footer";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ParkingContext } from "../../../services/parking/parkingContext";
import { Link, useParams } from "react-router-dom";

const CustomLogo = styled(Box)(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  [theme.breakpoints.up("md")]: {
    paddingTop: "120px",
  },
}));

const ParkingPrepaidPageTemplate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { customerLogo } = useContext(ParkingContext);
  const { orgId } = useParams();

  return (
    <PageContainer maxWidth={false}>
      <Navbar />
      {customerLogo && (
        <CustomLogo>
          <Link to={`/parking/prepaid/${orgId}/steps/1`}>
            <img src={customerLogo} alt="Logo" style={{ maxWidth: "120px" }} />
          </Link>
        </CustomLogo>
      )}
      <Container>
        {children}
        <Footer />
      </Container>
    </PageContainer>
  );
};

export default ParkingPrepaidPageTemplate;
