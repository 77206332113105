import { defaultTheme, RaThemeOptions } from "react-admin";

const theme: RaThemeOptions = {
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    fontFamily: "neuzeit-grotesk,sans-serif",
  },
  palette: {
    ...defaultTheme.palette,
    mode: "light",
    primary: {
      main: "#42596D",
    },
    secondary: {
      main: "#B2C9D7",
      light: "#EEEEEE",
      dark: "#454546",
    },
    contrastThreshold: 5,
    tonalOffset: 0.5,
  },
  components: {
    ...defaultTheme.components,
    MuiSelect: {
      styleOverrides: {
        select: {
          minWidth: 167,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 600,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #000000",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "neuzeit-grotesk,sans-serif",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          fontFamily: "neuzeit-grotesk,sans-serif",
        },
      },
    },
  },
};

export default theme;
