import React from "react";
import { Handle, Position } from "reactflow";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "30px",
  borderRadius: "50%",
  backgroundColor: "#73ae98",
}));

type Data = {
  label: string;
};

type Props = {
  data: Data;
  isConnectable: boolean;
};

const InitialNode = ({ data, isConnectable }: Props) => {
  return (
    <Container>
      <div>{data?.label}</div>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </Container>
  );
};

export default InitialNode;
