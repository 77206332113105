import WorkflowsList from "./WorkflowsList";
import WorkflowsCreate from "./WorkflowsCreate";
import WorkflowsEdit from "./WorkflowsEdit";

const workflowsResources = {
  list: WorkflowsList,
  create: WorkflowsCreate,
  edit: WorkflowsEdit,
};

export default workflowsResources;
