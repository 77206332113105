import React, { useContext, useState } from "react";
import { Box, CircularProgress, FormControl } from "@mui/material";
import {
  MobileButton,
  MobileLightButton,
  StepInnerContainer,
  TitleSection,
} from "./styles";
import { Field, Formik } from "formik";
import { CustomFormLabel, FormTextInput } from "../../../themes/styles";
import ErrorMessageContainer, {
  TextInputTypes,
} from "../../../components/errors/ErrorContainer";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "react-admin";
import {
  freeParkingRegistration,
  parkingRegistration,
} from "../../../utils/validationSchemes";
import { ParkingContext } from "../../../services/parking/parkingContext";
import { PaymentType } from "../../../types/enums/payment-type.enum";
import { ParkingPublicService } from "../../../api/ParkingPublicService";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import { IPaymentResponse } from "../../../types/interfaces/parking/payment-response.interface";
import useOnPageLoad from "../../../hooks/useOnPageLoad";
import { PaymentProcessStep } from "../../../types/enums/payment-process-step.enum";
import { IParkingPayment } from "../../../types/interfaces/parking/parking-payment.interface";
import useBeforeUnload from "../../../hooks/useBeforeUnload";
import { PaymentContext } from "../../../services/payment/PaymentContext";
import { PaymentService } from "../../../types/enums/payment-service.enum";
import { removeEmptyValues } from "../../../utils/objectService";

const BackButton = styled(MobileLightButton)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StepForm = () => {
  const navigate = useNavigate();
  const translate = useTranslate();

  const { organizationInfo } = useContext(ParkingContext);

  useOnPageLoad();
  useBeforeUnload();

  const { orgId } = useParams();

  const {
    user,
    setUser,
    plateNumber,
    parkingTimeInMinutes,
    saveParkingPaymentParams,
    saveCurrentStep,
    isFreeParkingProcess,
    setParkingProcess,
  } = useContext(ParkingContext);

  const { savePaymentService, saveRedirectUrl } = useContext(PaymentContext);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchAndSavePaymentData = async () => {
    const parkingPaymentData: IParkingPayment = {
      plateNumbers: plateNumber,
      parkingTime: parkingTimeInMinutes,
      paymentType: PaymentType.PREPAID,
      returnUrl: `${window.location.origin}/#/parking/prepaid/${orgId}/steps/sms-step`,
      ...user,
    };

    try {
      setIsLoading(true);
      setErrorMessage("");
      const { data } = await ParkingPublicService.getParkingPaymentData<
        IBasicResponse<IPaymentResponse>
      >(
        removeEmptyValues<IParkingPayment>(parkingPaymentData),
        orgId as string,
      );

      if (data.paymentStatus === "paid") {
        navigate(`/parking/prepaid/${orgId}/steps/sms-step`);
        return;
      }

      if (data.paymentUrl) {
        savePaymentService(PaymentService.PRZELEWY24);
        saveRedirectUrl(data.paymentUrl);
      }

      setIsLoading(false);
      saveParkingPaymentParams(data.paymentParams);
      saveCurrentStep(PaymentProcessStep.STEP_PAYMENT);
      navigate(`/parking/prepaid/${orgId}/steps/step-payment`);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setErrorMessage(
        e?.translatedMessage || translate("app.notifications.error.undefined"),
      );
    }
  };

  const stepBack = () => {
    saveCurrentStep(
      isFreeParkingProcess
        ? PaymentProcessStep.STEP_SECOND
        : PaymentProcessStep.STEP_THIRD,
    );
    navigate(-1);
  };

  const submitForm = async () => {
    await fetchAndSavePaymentData();
  };

  return (
    <Container>
      <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <TitleSection data-testid="prepaidPageStepFormTitle">
          {translate("app.parking.prepaid.form_title")}
        </TitleSection>
      </Box>

      <StepInnerContainer>
        <Formik
          initialValues={user}
          onSubmit={submitForm}
          validationSchema={
            isFreeParkingProcess ? freeParkingRegistration : parkingRegistration
          }
          enableReinitialize
        >
          {({
            touched,
            handleSubmit,
            values,
            errors,
            setFieldTouched,
            setFieldValue,
          }) => (
            <Box>
              {organizationInfo?.name !== "swarzedz" && (
                <>
                  <FormControl style={{ width: "100%" }}>
                    <CustomFormLabel>
                      {translate("app.parking.prepaid.first_name")}
                    </CustomFormLabel>
                    <Field>
                      {() => (
                        <FormTextInput
                          value={values.firstName}
                          onBlur={() => setFieldTouched("firstName")}
                          inputProps={{ "data-testid": "firstName" }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setParkingProcess(false);
                            setFieldValue("firstName", event.target.value);
                            setUser({ ...user, firstName: event.target.value });
                          }}
                        />
                      )}
                    </Field>
                  </FormControl>

                  {errors.firstName && touched.firstName && (
                    <div data-testid="firstNameError">
                      <ErrorMessageContainer>
                        {errors.firstName}
                      </ErrorMessageContainer>
                    </div>
                  )}

                  <FormControl style={{ width: "100%" }}>
                    <CustomFormLabel>
                      {translate("app.parking.prepaid.last_name")}
                    </CustomFormLabel>
                    <Field>
                      {() => (
                        <FormTextInput
                          value={values.lastName}
                          inputProps={{ "data-testid": "lastName" }}
                          onBlur={() => setFieldTouched("lastName")}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setFieldValue("lastName", event.target.value);
                            setUser({ ...user, lastName: event.target.value });
                          }}
                        />
                      )}
                    </Field>
                  </FormControl>

                  {errors.lastName && touched.lastName && (
                    <div data-testid="lastNameError">
                      <ErrorMessageContainer>
                        {errors.lastName}
                      </ErrorMessageContainer>
                    </div>
                  )}
                </>
              )}

              <FormControl style={{ width: "100%" }}>
                <CustomFormLabel>
                  {translate("app.parking.prepaid.email")}
                </CustomFormLabel>
                <Field>
                  {() => (
                    <FormTextInput
                      value={values.email}
                      inputProps={{ "data-testid": "email" }}
                      onBlur={() => setFieldTouched("email")}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setFieldValue("email", event.target.value);
                        setUser({ ...user, email: event.target.value });
                      }}
                    />
                  )}
                </Field>
              </FormControl>

              {errors.email && touched.email && (
                <div data-testid="emailError">
                  <ErrorMessageContainer>{errors.email}</ErrorMessageContainer>
                </div>
              )}

              <FormControl style={{ width: "100%" }}>
                <CustomFormLabel>
                  {translate("app.parking.prepaid.phone_number")}
                </CustomFormLabel>
                <Field>
                  {() => (
                    <FormTextInput
                      value={values.phoneNumber}
                      inputProps={{ "data-testid": "phoneNumber" }}
                      onBlur={() => setFieldTouched("phoneNumber")}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setFieldValue("phoneNumber", event.target.value);
                        setUser({ ...user, phoneNumber: event.target.value });
                      }}
                    />
                  )}
                </Field>
              </FormControl>

              {errors.phoneNumber && touched.phoneNumber && (
                <div data-testid="phoneNumberError">
                  <ErrorMessageContainer>
                    {errors.phoneNumber}
                  </ErrorMessageContainer>
                </div>
              )}

              <Box>
                <ErrorMessageContainer variant={TextInputTypes.API_ERROR}>
                  {errorMessage}
                </ErrorMessageContainer>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginTop: "10px",
                }}
              >
                <MobileButton
                  aria-label="submitStepForm"
                  disabled={isLoading}
                  onClick={() => handleSubmit()}
                >
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    translate("app.auth.next")
                  )}
                </MobileButton>
                <BackButton onClick={() => stepBack()}>
                  {translate("app.auth.back")}
                </BackButton>
              </Box>
            </Box>
          )}
        </Formik>
      </StepInnerContainer>
    </Container>
  );
};

export default StepForm;
