import {
  fetchUtils,
  HttpError,
  addRefreshAuthToDataProvider,
} from "react-admin";
import Qs from "qs";
import i18nProvider from "../translations/i18n-provider";
import { isTokenValid } from "../utils/tokenService";
import { redirect } from "react-router";
import { AuthService } from "../api/AuthService";
import { ConfigManager } from "../constants/ConfigManager";
import { v4 as uuidv4 } from "uuid";
import { refreshAuth } from "./authProvider";
import mobileModules from "../mockData/mobileModules.json";

const { translate } = i18nProvider;

export const httpClient = async (url, options = {}) => {
  let token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  if (token && !isTokenValid(token)) {
    if (!refreshToken || !isTokenValid(refreshToken)) {
      redirect("/login");
    }

    try {
      const data = await AuthService.refreshToken();
      token = data?.accessToken;
      localStorage.setItem("token", data?.accessToken);
      localStorage.setItem("refreshToken", data?.refreshToken);
    } catch (e) {
      console.log(e);
      redirect("/login");
    }
  }

  if (url.includes("organization")) {
    const orgId = ConfigManager.getInstance().getOrgId();
    if (orgId) {
      url = url.replace("organization", orgId);
    }
  }

  options.headers =
    options.headers ||
    new Headers({
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    });
  return fetchUtils.fetchJson(url, options);
};

const MONEY_AMOUNT_KEYS = ["fromAmount", "toAmount"];
const RESOURCES_WITH_REQUIRED_ID = ["history"];

const isResourceNeedGetGeneratedId = (resource) => {
  return RESOURCES_WITH_REQUIRED_ID.some((res) => resource.includes(res));
};

const customDataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { order } = params.sort;
    const query = {
      order,
      offset: JSON.stringify((page - 1) * perPage),
      limit: JSON.stringify(perPage),
      search: params?.filter?.search,
    };

    for (const [key, value] of Object.entries(params.filter)) {
      query[key] = value;
      if (MONEY_AMOUNT_KEYS.includes(key)) {
        query[key] = value * 100;
      }
    }

    if (params?.meta) {
      for (const [key, value] of Object.entries(params.meta)) {
        query[key] = value;
      }
    }

    if (params?.filter?.active) {
      query["active"] = params?.filter?.active;
    }
    if (params?.filter?.removed) {
      query["removed"] = true;
    }

    const url = `${ConfigManager.getInstance().getApiAdminUrl()}/${resource}?${Qs.stringify(
      query,
    )}`;

    if (url.includes("mobile-modules")) {
      return {
        data: mobileModules.data,
        total: 10,
      };
    }

    const { json } = await httpClient(url);

    if (isResourceNeedGetGeneratedId(resource)) {
      json.data = json.data.map((obj) => {
        obj.id = uuidv4();
        return obj;
      });
    }

    if (resource.includes("vehicles")) {
      json.data = json.data.map((obj) => {
        obj.id = obj.plateNumber;
        return obj;
      });
    }

    return {
      data: json.data,
      total: parseInt(json.total, 10),
    };
  },

  getOne: async (resource, params) => {
    const url = `${ConfigManager.getInstance().getApiAdminUrl()}/${resource}/${
      params.id
    }`;

    const { json } = await httpClient(url);

    if (resource.includes("vehicles")) {
      json.data.id = json.data.plateNumber;
    }

    return { data: json.data };
  },

  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${ConfigManager.getInstance().getApiAdminUrl()}/${resource}?${Qs.stringify(
      query,
    )}`;
    const { json } = await httpClient(url);

    return { data: json.data };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${ConfigManager.getInstance().getApiAdminUrl()}/${resource}?${Qs.stringify(
      query,
    )}`;
    const { json } = await httpClient(url);

    return {
      data: json.data,
      total: json.total,
    };
  },

  create: async (resource, params) => {
    try {
      const { json } = await httpClient(
        `${ConfigManager.getInstance().getApiAdminUrl()}/${resource}`,
        {
          method: "POST",
          body: JSON.stringify(params.data),
        },
      );
      return { data: { ...params.data, id: json.data.id } };
    } catch (e) {
      throw new HttpError(
        e.body?.error?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        e?.body?.error?.statusCode || 500,
      );
    }
  },

  update: async (resource, params) => {
    try {
      const url = `${ConfigManager.getInstance().getApiAdminUrl()}/${resource}/${
        params.id
      }`;
      await httpClient(url, {
        method: "POST",
        body: JSON.stringify(params.data),
      });
      return { data: params.data };
    } catch (e) {
      throw new HttpError(
        e.body?.error?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        e?.body?.error?.statusCode || 500,
      );
    }
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${ConfigManager.getInstance().getApiAdminUrl()}/${resource}?${Qs.stringify(
      query,
    )}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },

  delete: async (resource, params) => {
    try {
      const url = `${ConfigManager.getInstance().getApiAdminUrl()}/${resource}/${
        params.id
      }`;
      const { json } = await httpClient(url, {
        method: "DELETE",
      });
      return { data: json };
    } catch (e) {
      throw new HttpError(
        e.body?.error?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        e?.body?.error?.statusCode || 500,
      );
    }
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${ConfigManager.getInstance().getApiAdminUrl()}/${resource}?${Qs.stringify(
      query,
    )}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },
};

export const dataProvider = addRefreshAuthToDataProvider(
  customDataProvider,
  refreshAuth,
);
