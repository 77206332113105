import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ButtonBase } from "@mui/material";

const Timer = React.memo(
  ({ handleCodeResend }: { handleCodeResend: () => void }) => {
    const [countdown, setCountdown] = useState(0);
    const [timerId, setTimerId] = useState<ReturnType<
      typeof setInterval
    > | null>();

    const startTimer = () => {
      setCountdown(30);
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          return prevCountdown - 1;
        });
      }, 1000);
      setTimerId(timer);
    };

    const resetTimer = useCallback(() => {
      if (timerId) {
        clearInterval(timerId);
      }
      setTimerId(null);
    }, [timerId]);

    useEffect(() => {
      if (countdown === 0) {
        resetTimer();
      }
    }, [countdown, resetTimer]);

    useEffect(() => {
      startTimer();
    }, []);

    const handleButtonClick = () => {
      if (!timerId) {
        startTimer();
      }
      handleCodeResend();
    };

    return (
      <Box>
        {countdown === 0 ? (
          <ButtonBase
            style={{
              backgroundColor: "#f2f2f2",
              border: "1px solid #757575",
              borderRadius: "20px",
              padding: "7px",
            }}
            onClick={() => handleButtonClick()}
          >
            Send Code
          </ButtonBase>
        ) : (
          <div style={{ color: "#757575" }}>Resend in {countdown}</div>
        )}
      </Box>
    );
  },
);

export default Timer;
