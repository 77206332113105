import React from "react";
import { Resource } from "react-admin";
import statusesResource from "./statuses";
import DashboardIcon from "../../components/icons/DashboardIcon";
import { Roles } from "../../types/enums/roles";
import attributesResource from "./attributes";
import workflowsResources from "./workflows";
import ticketsResources from "./tickets";
import { EResource, getResourceUrl } from "../../utils/resourcesHelper";

const helpdesk = (permissions: string[] | undefined, moduleEnabled = false) => {
  const resources = [];

  const url = "helpdesk/admin/organization/statuses";
  const workflowsUrl = "helpdesk/admin/organization/workflows";
  const ticketAttributesUrl = "helpdesk/admin/organization/attributes";

  const ticketsUrl = getResourceUrl(EResource.TICKETS);

  if (moduleEnabled) {
    resources.push(
      <Resource
        {...ticketsResources}
        name={ticketsUrl}
        key={ticketsUrl}
        options={{
          label: "app.helpdesk.tickets",
          menuParent: "helpdesk-parent",
          sectionKey: "tickets-section",
          sectionName: "tickets-section",
        }}
      />,
    );

    resources.push(
      <Resource
        key="helpdesk-parent"
        name="helpdesk-parent"
        icon={DashboardIcon}
        options={{
          label: "app.helpdesk.tickets",
          isMenuParent: true,
          sectionKey: "helpdesk-parent",
          sectionName: "app.helpdesk.helpdesk",
        }}
      />,
    );

    if (permissions && permissions.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN)) {
      resources.push(
        <Resource
          {...statusesResource}
          name={url}
          key={url}
          options={{
            label: "app.helpdesk.statuses",
            menuParent: "helpdesk-parent",
            sectionKey: "statuses-section",
            sectionName: "statuses-section",
          }}
        />,
      );

      resources.push(
        <Resource
          {...attributesResource}
          name={ticketAttributesUrl}
          key={ticketAttributesUrl}
          options={{
            label: "app.helpdesk.ticket_attributes",
            menuParent: "helpdesk-parent",
            sectionKey: "statuses-section",
            sectionName: "statuses-section",
          }}
        />,
      );

      resources.push(
        <Resource
          {...workflowsResources}
          name={workflowsUrl}
          key={workflowsUrl}
          options={{
            label: "Workflows",
            menuParent: "helpdesk-parent",
            sectionKey: "workflows-section",
            sectionName: "workflows-section",
          }}
        />,
      );
    }
  }

  return resources;
};

export default helpdesk;
