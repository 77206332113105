import React from "react";

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.005"
      height="20"
      viewBox="0 0 20.005 20"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_309"
            data-name="Rectangle 309"
            width="20.005"
            height="20"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_462" data-name="Group 462" transform="translate(0 0)">
        <g
          id="Group_461"
          data-name="Group 461"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Path_37"
            data-name="Path 37"
            d="M1.254,0a1.248,1.248,0,0,0-.87,2.143L8.238,10,.384,17.852a1.248,1.248,0,1,0,1.729,1.8l.036-.036L10,11.763l7.854,7.854a1.249,1.249,0,1,0,1.8-1.73l-.036-.035L11.768,10l7.854-7.854A1.248,1.248,0,1,0,17.857.379L10,8.233,2.149.379A1.246,1.246,0,0,0,1.254,0"
            transform="translate(0 0.001)"
            fill="#0a0a0a"
          />
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
