import { useEffect, useState } from "react";
import { helpdeskService } from "../api/Helpdesk";
import { IBasicResponse } from "../types/interfaces/response/basic-response.interface";
import { IConfigResponse } from "../types/interfaces/config-response.interface";
import { fetchConfig } from "../utils/config";
import { SettingsManager } from "../constants/SettingsManager";

const defaultSettings: THelpdeskSettings = {
  enabled: false,
  attachments: {
    acceptTypes: [
      "image/gif",
      "image/jpeg",
      "image/png",
      "text/csv",
      "application/vnd.ms-excel",
      "application/pdf",
      "image/bmp",
      "application/msword",
      "text/plain",
      "image/tiff",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "video/webm",
      "video/mp4",
      "video/x-msvideo",
      "video/x-matroska",
      "audio/aac",
      "audio/vnd.wav",
      "audio/wav",
      "audio/flac",
      "audio/opus",
      "audio/mp4",
      "audio/mpeg",
    ],
    acceptExtensions: [],
    accept:
      "image/gif,image/jpeg,image/png,text/csv,application/vnd.ms-excel,application/pdf,image/bmp,application/msword,text/plain,image/tiff,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,video/webm,video/mp4,video/x-msvideo,video/x-matroska,audio/aac,audio/vnd.wav,audio/wav,audio/flac,audio/opus,audio/mp4,audio/mpeg",
    limits: {
      maxSize: 20000000,
    },
  },
};

export type THelpdeskSettings = {
  enabled: boolean;
  attachments: {
    acceptTypes: string[];
    acceptExtensions: string[];
    accept: string;
    limits: {
      maxSize: number;
    };
  };
};

const useHelpdeskSettings = (orgId?: string) => {
  const [settings, setSettings] = useState<THelpdeskSettings>(defaultSettings);

  useEffect(() => {
    if (localStorage.getItem("orgId")) {
      assignSettings();
    }
  }, [orgId]);

  const fetchSettings = async () => {
    try {
      const { api_url }: IConfigResponse = await fetchConfig();
      const { data } =
        await helpdeskService.getSettings<IBasicResponse<THelpdeskSettings>>(
          api_url,
        );

      return data;
    } catch (e) {
      return defaultSettings;
    }
  };

  const assignSettings = async () => {
    const helpdeskSettings = await fetchSettings();

    SettingsManager.getInstance().setHelpdeskSettings(helpdeskSettings);
    setSettings(helpdeskSettings);
  };

  return { settings };
};

export default useHelpdeskSettings;
