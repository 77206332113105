import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useParams } from "react-router-dom";
import { useTranslate } from "react-admin";

const Menu = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "30px",

  [theme.breakpoints.down("lg")]: {
    gap: "10px",
  },
}));

const MenuItemLink = styled(Link)(() => ({
  textDecoration: "none",
  fontSize: "16px",
  color: "#0A0A0A",
}));

const DesktopMenu = () => {
  const translate = useTranslate();

  const { orgId } = useParams();

  return (
    <Menu>
      <Box>
        <MenuItemLink to={`/parking/prepaid/${orgId}/page/cennik`}>
          {translate("app.parking.menu.parking_price")}
        </MenuItemLink>
      </Box>
      <Box>
        <MenuItemLink to={`/parking/prepaid/${orgId}/page/regulamin`}>
          {translate("app.parking.menu.parking_regulations")}
        </MenuItemLink>
      </Box>
      <Box>
        <MenuItemLink
          to={`/parking/prepaid/${orgId}/page/polityka-prywatnosci`}
        >
          {translate("app.parking.menu.parking_privacy_policy")}
        </MenuItemLink>
      </Box>
      {/*<Box>*/}
      {/*  <MenuItemLink to={"/#/parking/prepaid/123"}>*/}
      {/*    {translate("app.parking.menu.all_parking")}*/}
      {/*  </MenuItemLink>*/}
      {/*</Box>*/}
    </Menu>
  );
};

export default DesktopMenu;
