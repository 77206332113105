import React from "react";
import { Box, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = {
  decrease: () => void;
  increase: () => void;
  title: string;
  value: string | number;
};

const Container = styled(Box)(() => ({
  maxWidth: "170px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const ActionButton = styled(ButtonBase)(() => ({
  backgroundColor: "lightgray",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px",
  borderRadius: "8px",
  fontSize: "30px",
}));

const ActionButtonContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const Content = styled(Box)(() => ({
  border: "2px solid black",
  padding: "20px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
}));

const Title = styled(Box)(() => ({
  color: "gray",
  textTransform: "uppercase",
  fontSize: "14px",
}));

const Value = styled(Box)(() => ({
  fontSize: "34px",
}));

const NumberButton = React.memo((props: Props) => {
  const { decrease, value, increase, title } = props;

  return (
    <Container>
      <ActionButtonContainer>
        <ActionButton
          data-testid={`${title}IncreaseButton`}
          onClick={() => increase()}
        >
          +
        </ActionButton>
      </ActionButtonContainer>
      <Content>
        <Title>{title}:</Title>
        <Value data-testid={`${title}Value`}>{value}</Value>
      </Content>
      <ActionButtonContainer>
        <ActionButton
          data-testid={`${title}DecreaseButton`}
          onClick={() => decrease()}
        >
          -
        </ActionButton>
      </ActionButtonContainer>
    </Container>
  );
});

export default NumberButton;
