import * as React from "react";

const MobileAppIcon = ({ size = 18, color = "#606060", style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 13.538 16"
      style={style}
    >
      <path
        id="icons8-mobile"
        d="M3.673,1A1.765,1.765,0,0,0,2,2.846v9.846a1.765,1.765,0,0,0,1.673,1.846h.788V13.308H3.673c-.192,0-.442-.226-.442-.615V2.846c0-.389.25-.615.442-.615h8.072A1.691,1.691,0,0,0,10.173,1ZM7.365,3.462A1.765,1.765,0,0,0,5.692,5.308v9.846A1.765,1.765,0,0,0,7.365,17h6.5a1.765,1.765,0,0,0,1.673-1.846V5.308a1.765,1.765,0,0,0-1.673-1.846Zm0,1.231h6.5c.192,0,.442.226.442.615v9.846c0,.389-.25.615-.442.615h-6.5c-.192,0-.442-.226-.442-.615V5.308C6.923,4.918,7.173,4.692,7.365,4.692Zm2.019,8.615v1.231h2.462V13.308Z"
        transform="translate(-2 -1)"
        fill={color}
      />
    </svg>
  );
};

export default MobileAppIcon;
