import React, { createContext, useState } from "react";
import { PaymentService } from "../../types/enums/payment-service.enum";

type PaymentContextType = {
  paymentService: PaymentService;
  savePaymentService: (service: PaymentService) => void;
  redirectUrl: string;
  saveRedirectUrl: (url: string) => void;
};

export const PaymentContext = createContext<PaymentContextType>({
  paymentService: PaymentService.STRIPE,
  savePaymentService: (service: PaymentService) => console.log(service),
  redirectUrl: "",
  saveRedirectUrl: (url: string) => console.log(url),
});

export const PaymentContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [paymentService, setPaymentService] = useState<PaymentService>(
    PaymentService.STRIPE,
  );
  const [redirectUrl, setRedirectUrl] = useState<string>("");

  return (
    <PaymentContext.Provider
      value={{
        paymentService,
        savePaymentService: setPaymentService,
        redirectUrl,
        saveRedirectUrl: setRedirectUrl,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
