import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OrganizationService } from "../api/OrganizationService";
import { IBasicResponse } from "../types/interfaces/response/basic-response.interface";
import { IOrganization } from "../types/interfaces/organization.interface";
import { ParkingContext } from "../services/parking/parkingContext";

const usePrepaidInitialization = () => {
  const { orgId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { saveCustomerLogo, saveOrganizationInfo } = useContext(ParkingContext);

  const checkIfOrgExists = useCallback(async () => {
    try {
      await OrganizationService.getOrganizationInfo<
        IBasicResponse<IOrganization>
      >(orgId as string);
    } catch (e) {
      window.location.assign("https://yourbuildingapp.com");
      console.log(e);
    }
  }, []);

  const fetchOrgInfo = useCallback(async () => {
    try {
      const { data } = await OrganizationService.getOrganizationInfo<
        IBasicResponse<IOrganization>
      >(orgId as string);
      saveOrganizationInfo(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchCustomerLogo = useCallback(async () => {
    try {
      const customerLogo = await OrganizationService.getOrganizationLogo<Blob>(
        orgId as string,
      );
      saveCustomerLogo(URL.createObjectURL(customerLogo));
    } catch (e) {
      // set default image
      console.log(e);
    }
  }, []);

  const initPrepaidProcess = async () => {
    setIsLoading(true);
    await Promise.all([
      checkIfOrgExists(),
      fetchCustomerLogo(),
      fetchOrgInfo(),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    initPrepaidProcess();
  }, []);

  return { isLoading };
};

export default usePrepaidInitialization;
