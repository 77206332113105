import React from "react";
import {
  StyledArraySelect,
  StyledSaveButton,
  StyledTextInput,
} from "../../../themes/styles";
import {
  Create,
  ListToolbarProps,
  required,
  SimpleForm,
  TranslatableInputs,
} from "react-admin";
import { Toolbar } from "@mui/material";
import useWorkflowRoles from "../../../hooks/useWorkflowRoles";

const WorkflowsCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const WorkflowsCreate = () => {
  const { isLoading, workflowRoles } = useWorkflowRoles();

  return (
    <Create>
      <SimpleForm toolbar={<WorkflowsCreateFormToolbar />}>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="name"
            label="app.auth.name"
            validate={required()}
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="description"
            label="app.auth.description"
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>
        <StyledArraySelect
          source="roles"
          label="app.auth.role"
          choices={workflowRoles}
          optionValue="role"
          isLoading={isLoading}
          validate={required()}
          variant="outlined"
          sx={{ width: "200px", marginTop: "15px" }}
        />
      </SimpleForm>
    </Create>
  );
};

export default WorkflowsCreate;
