import React, { useEffect, useState } from "react";
import {
  Edit,
  ListToolbarProps,
  required,
  SimpleForm,
  TranslatableInputs,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import {
  StyledArraySelect,
  StyledDeleteButton,
  StyledSaveButton,
  StyledSelect,
  StyledTextInput,
} from "../../../themes/styles";
import { Toolbar } from "@mui/material";
import { ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
import Flow from "../../../components/resources/admin-parking/workflow/Flow";
import useWorkflowRoles from "../../../hooks/useWorkflowRoles";
import { IWorkflowStep } from "../../../types/interfaces/helpdesk/workflow/workflow-step.interface";
import { IUpdateWorkflowStatus } from "../../../types/interfaces/helpdesk/workflow/update-workflow-status.interface";
import { areNodesValid } from "../../../utils/workflow/workflowService";
import { TicketStatusType } from "../../../types/enums/ticket-status-type.enums";
import { httpClient } from "../../../providers/dataProvider";
import { ConfigManager } from "../../../constants/ConfigManager";
import ErrorMessageContainer from "../../../components/errors/ErrorContainer";
import ErrorContainer from "../../../components/errors/ErrorContainer";
import { helpdeskWorkflowService } from "../../../api/HelpdeskWorkflow";
import { EActiveState } from "../../../types/enums/active-state.enum";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import { IWorkflow } from "../../../types/interfaces/helpdesk/workflow/workflow.interface";
import { helpdeskActiveTypes } from "../../../utils/helpdeskTicketStatusTypes";

const WorkflowsEditFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton alwaysEnable />
      <StyledDeleteButton />
    </Toolbar>
  );
};

const WorkflowsEdit = () => {
  return (
    <Edit redirect="list" mutationMode="pessimistic">
      <EditPageView />
    </Edit>
  );
};

const EditPageView = () => {
  const { isLoading, workflowRoles } = useWorkflowRoles();
  const [steps, setSteps] = useState<IWorkflowStep[]>([]);
  const [statuses, setStatuses] = useState<IUpdateWorkflowStatus[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isThereActiveWorkflow, setIsThereActiveWorkflow] =
    useState<boolean>(false);

  const workflow = useRecordContext<IWorkflow>();

  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();

  const save = async (data: any) => {
    data.statuses = statuses;
    data.steps = steps;
    data.active = TicketStatusType.ACTIVE;

    setErrorMessage("");

    if (!areNodesValid(data.statuses)) {
      setErrorMessage(
        translate("app.helpdesk.workflow.notConnectedRequiredNodes"),
      );
    }

    try {
      await httpClient(
        `${ConfigManager.getInstance().getApiAdminUrl()}/helpdesk/admin/organization/workflows/${
          data.id
        }`,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
      );

      redirect("list", "helpdesk/admin/organization/workflows");
    } catch (e) {
      setErrorMessage(
        e?.translatedMessage || translate("app.notifications.error.undefined"),
      );
    }
  };

  const synchronizeWorkflowDataAndForm = (
    newStatuses: IUpdateWorkflowStatus[],
    newSteps: IWorkflowStep[],
  ) => {
    setStatuses(newStatuses);
    setSteps(newSteps);
  };

  const fetchActiveWorkflow = async () => {
    try {
      const { data } = await helpdeskWorkflowService.getWorkflowList<
        IBasicResponse<IWorkflow[]>
      >({ active: EActiveState.ACTIVE });

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const checkActiveWorkflows = async () => {
    const workflows = await fetchActiveWorkflow();

    if (!workflows) {
      setIsThereActiveWorkflow(false);
      return;
    }

    const res = workflows.some((item: IWorkflow) => {
      return item.roles.some((role: string) => {
        return workflow.roles.includes(role);
      });
    });

    setIsThereActiveWorkflow(res);
  };

  useEffect(() => {
    checkActiveWorkflows();
  }, []);

  const handleActiveChange = (event: any) => {
    if (isThereActiveWorkflow && event.target.value === EActiveState.ACTIVE) {
      notify(translate("app.helpdesk.workflow.activeMessage"), {
        type: "warning",
      });
    }
  };

  return (
    <>
      <SimpleForm toolbar={<WorkflowsEditFormToolbar />} onSubmit={save}>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="name"
            label="app.auth.name"
            validate={required()}
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="description"
            label="app.auth.description"
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>
        <StyledArraySelect
          source="roles"
          label="app.auth.role"
          choices={workflowRoles}
          optionValue="role"
          isLoading={isLoading}
          validate={required()}
          variant="outlined"
          sx={{ width: "200px", marginTop: "15px" }}
        />
        <StyledSelect
          source="active"
          label="Active"
          choices={helpdeskActiveTypes}
          isLoading={isLoading}
          validate={required()}
          variant="outlined"
          onChange={handleActiveChange}
          sx={{ width: "200px", marginTop: "15px" }}
        />
      </SimpleForm>

      {errorMessage && (
        <div style={{ textAlign: "center" }}>
          <ErrorContainer>
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          </ErrorContainer>
        </div>
      )}

      <div className="flow-container" style={{ height: "500px" }}>
        <ReactFlowProvider>
          <Flow
            synchronizeWorkflowDataAndForm={synchronizeWorkflowDataAndForm}
          />
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default WorkflowsEdit;
