import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconCancel from "@mui/icons-material/Cancel";
import IconSave from "@mui/icons-material/Save";
import React from "react";
import {
  Button,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import { buttons, StyledRichTextInput } from "../../../../themes/styles";
import { helpdeskTicketsService } from "../../../../api/HelpdeskTicketsService";
import { ITicket } from "../../../../types/interfaces/helpdesk/ticket/ticket.interface";

type Props = { isOpen: boolean; close: () => void };

const AddCommentDialog = ({ close, isOpen }: Props) => {
  const notify = useNotify();
  const translate = useTranslate();
  const ticket = useRecordContext<ITicket>();
  const refresh = useRefresh();

  const handleSubmit = async (data: any) => {
    const newCommentData = {
      comment: data.comment,
    };

    try {
      await helpdeskTicketsService.createTicketComment(
        ticket.id,
        newCommentData,
      );
      notify(translate("app.helpdesk.comment_added"), { type: "success" });
      refresh();
      close();
    } catch (e) {
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        { type: "error" },
      );
    }
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      transitionDuration={200}
      onClose={() => close()}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {translate("app.helpdesk.add_comment")}
      </DialogTitle>
      <Form onSubmit={(data) => handleSubmit(data)}>
        <DialogContent>
          <StyledRichTextInput
            source="comment"
            variant="outlined"
            label="app.helpdesk.comment"
            fullWidth
          />
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Button label="ra.action.save" type="submit" sx={buttons.darkButton}>
            <IconSave />
          </Button>
          <Button
            label="ra.action.cancel"
            onClick={() => close()}
            sx={buttons.whiteButton}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default AddCommentDialog;
