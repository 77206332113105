import TicketsList from "./TicketsList";
import TicketsCreate from "./TicketsCreate";
import TicketsShow from "./TicketsShow";

const ticketsResources = {
  list: TicketsList,
  create: TicketsCreate,
  show: TicketsShow,
};

export default ticketsResources;
