import React from "react";
import { codeScheme } from "../../utils/validationSchemes";
import { Field, Form, Formik } from "formik";
import { CircularProgress, FormControl } from "@mui/material";
import {
  CustomButton,
  CustomFormLabel,
  FormTextInput,
} from "../../themes/styles";
import ErrorMessageContainer from "../errors/ErrorContainer";
import Timer from "../Timer";
import { useTranslate } from "react-admin";

type Props = {
  onCodeSubmit: (data: { code: string }) => void;
  isLoading: boolean;
  onCodeResend: () => void;
};

const ResetPasswordForm = (props: Props) => {
  const translate = useTranslate();
  const { onCodeResend, onCodeSubmit, isLoading } = props;

  return (
    <Formik
      initialValues={{ code: "" }}
      validateOnChange={true}
      validationSchema={codeScheme}
      onSubmit={onCodeSubmit}
      key="ConfirmationForm"
      enableReinitialize
    >
      {({
        handleSubmit,
        isValid,
        handleChange,
        values,
        errors,
        touched,
        setFieldTouched,
      }) => (
        <Form style={{ display: "flex", flexDirection: "column" }}>
          <FormControl>
            <CustomFormLabel>
              {translate("app.auth.login.code")}
            </CustomFormLabel>
            <Field>
              {() => (
                <FormTextInput
                  id="code"
                  name="code"
                  onBlur={() => setFieldTouched("code")}
                  value={values.code}
                  onChange={handleChange("code")}
                />
              )}
            </Field>
          </FormControl>

          {errors.code && touched.code && (
            <ErrorMessageContainer>{errors.code}</ErrorMessageContainer>
          )}

          <FormControl style={{ textAlign: "end" }}>
            <Timer handleCodeResend={onCodeResend} />
          </FormControl>

          {isLoading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <CustomButton
              type="submit"
              disabled={!isValid}
              style={{ marginTop: "7px" }}
              onClick={() => handleSubmit()}
            >
              {translate("app.auth.next")}
            </CustomButton>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
