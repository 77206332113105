import * as React from "react";
import { useCallback } from "react";
import { useLocaleState, useTranslate } from "react-admin";
import Button from "@mui/material/Button";
import { useLogout } from "ra-core";
import { useStyles } from "../themes/styles";

const ToolsMenu = () => {
  const logout = useLogout();
  const translate = useTranslate();
  const [locale, setLocale] = useLocaleState();
  const classes = useStyles();
  const handleLogoutClick = useCallback(() => logout(null, "", true), [logout]);

  return (
    <div className={classes.menu}>
      <div className={classes.menuItem}>
        <div className={classes.menuItemTitle}>
          {translate("app.system.system_language")}
        </div>
      </div>
      <div className={classes.menuItem}>
        <Button
          className={classes.menuItemTitle}
          disableRipple={true}
          style={{
            textTransform: "lowercase",
            justifyContent: "start",
            paddingLeft: "10px",
            fontWeight: locale === "pl" ? "bold" : "normal",
          }}
          onClick={() => {
            setLocale("pl");
            location.reload();
          }}
        >
          {translate("app.system.system_lang_polish")}
        </Button>
      </div>
      <div className={classes.menuItem}>
        <Button
          className={classes.menuItemTitle}
          disableRipple={true}
          style={{
            textTransform: "lowercase",
            justifyContent: "start",
            paddingLeft: "10px",
            fontWeight: locale === "en" ? "bold" : "normal",
          }}
          onClick={() => {
            setLocale("en");
            location.reload();
          }}
        >
          {translate("app.system.system_lang_english")}
        </Button>
      </div>
      <div className={classes.menuItem}>
        <Button
          className={classes.menuItemTitle}
          disableRipple={true}
          onClick={handleLogoutClick}
          style={{ color: "black" }}
        >
          {translate("app.system.system_logout")}
        </Button>
      </div>
    </div>
  );
};

export default ToolsMenu;
