import React, { useEffect, useState } from "react";
import {
  Button,
  TopToolbar,
  useAuthenticated,
  useLocaleState,
  usePermissions,
  useShowController,
  useTranslate,
} from "react-admin";
import AddCommentIcon from "@mui/icons-material/AddComment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { buttons, itemInfoComponent, StyledShow } from "../../../themes/styles";
import AddCommentDialog from "../../../components/resources/helpdesk/tickets/AddCommentDialog";
import { ITicket } from "../../../types/interfaces/helpdesk/ticket/ticket.interface";
import { Box, Grid } from "@mui/material";
import TicketHistory from "../../../components/resources/helpdesk/tickets/show/TicketHistory";
import CommentList from "../../../components/resources/helpdesk/tickets/show/CommentList";
import { ITicketAttachment } from "../../../types/interfaces/helpdesk/ticket/ticket-attachments.interface";
import { helpdeskTicketsService } from "../../../api/HelpdeskTicketsService";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import CustomAttachmentField from "../../../components/forms/CustomAttachmentField";
import AddAttachmentDialog from "../../../components/resources/helpdesk/tickets/AddAttachmentDialog";
import AttributeField from "../../../components/resources/helpdesk/tickets/AttributeField";
import { styled } from "@mui/material/styles";
import AssignToUserDialog from "../../../components/resources/helpdesk/tickets/AssignToUserDialog";
import { Roles } from "../../../types/enums/roles";
import { ConfigManager } from "../../../constants/ConfigManager";
import TicketImagesGallery from "../../../components/resources/helpdesk/tickets/show/TicketImagesGallery";
import ChangeStatusSection from "../../../components/resources/helpdesk/tickets/ChangeStatusSection";

const TicketDetailSection = styled(Box)(() => ({
  margin: "0 0 0 10px",
  padding: "10px",
  textAlign: "center",
}));

export interface CustomImage {
  original: string;
  src: string;
  width: number;
  height: number;
}

const TicketsShow = () => {
  useAuthenticated();

  const [isCommentDialogOpen, setIsCommentDialogOpen] =
    useState<boolean>(false);
  const [isAttachmentDialogOpen, setIsAttachmentDialogOpen] =
    useState<boolean>(false);
  const [isAssigneeDialogOpen, setIsAssigneeDialogOpen] =
    useState<boolean>(false);

  const { permissions } = usePermissions();

  const TicketsShowActions = () => {
    return (
      <TopToolbar className="list-topbar" sx={{ alignItems: "center" }}>
        <Button
          sx={buttons.whiteButton}
          key={`action-add-comment`}
          color="primary"
          label="app.helpdesk.add_comment"
          onClick={() => {
            setIsCommentDialogOpen(true);
          }}
        >
          <AddCommentIcon />
        </Button>
        <Button
          sx={buttons.whiteButton}
          key={`action-add-attachment`}
          color="primary"
          label="app.helpdesk.add_attachment"
          onClick={() => {
            setIsAttachmentDialogOpen(true);
          }}
        >
          <AttachFileIcon />
        </Button>

        {permissions.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN) && (
          <Button
            sx={buttons.whiteButton}
            key={`action-change-user`}
            color="primary"
            label="app.helpdesk.assignee_to_user"
            onClick={() => {
              setIsAssigneeDialogOpen(true);
            }}
          >
            <PersonAddIcon />
          </Button>
        )}

        <ChangeStatusSection />
      </TopToolbar>
    );
  };

  return (
    <StyledShow actions={<TicketsShowActions />}>
      <TicketsShowComponent />
      <AddCommentDialog
        isOpen={isCommentDialogOpen}
        close={() => setIsCommentDialogOpen(false)}
      />
      <AddAttachmentDialog
        isOpen={isAttachmentDialogOpen}
        close={() => setIsAttachmentDialogOpen(false)}
      />
      <AssignToUserDialog
        isOpen={isAssigneeDialogOpen}
        close={() => setIsAssigneeDialogOpen(false)}
      />
    </StyledShow>
  );
};

const TicketsShowComponent = () => {
  const { record: ticket } = useShowController<ITicket>();
  const translate = useTranslate();
  const [locale] = useLocaleState();

  const [attachmentsList, setAttachmentsList] = useState<ITicketAttachment[]>(
    [],
  );
  const [imagesList, setImagesList] = useState<CustomImage[]>([]);

  const fetchTicketAttachments = async () => {
    try {
      if (ticket?.id) {
        const { data } = await helpdeskTicketsService.getTicketAttachments<
          IBasicResponse<ITicketAttachment[]>
        >(ticket?.id);
        return data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sortAttachmentsType = async () => {
    const attachments = await fetchTicketAttachments();

    if (attachments) {
      const imgList: CustomImage[] = [];
      const attchList: ITicketAttachment[] = [];
      attachments.map((attchItem) => {
        if (attchItem.contentType.includes("image")) {
          const imageItem: CustomImage = {
            src: `${ConfigManager.getInstance().getApiUrl()}/api/v1/storage/files/${
              attchItem.attachmentId
            }`,
            original: `${ConfigManager.getInstance().getApiUrl()}/api/v1/storage/files/${
              attchItem.attachmentId
            }`,
            width: 150,
            height: 150,
          };
          imgList.push(imageItem);
        } else {
          attchList.push(attchItem);
        }
      });
      setAttachmentsList(attchList);
      setImagesList(imgList);
    }
  };

  useEffect(() => {
    sortAttachmentsType();
  }, [ticket]);

  return (
    <>
      <TicketDetailSection>
        <b>{translate("app.helpdesk.ticket_details")}</b>
      </TicketDetailSection>
      <Box sx={itemInfoComponent}>
        <Grid container spacing={3} sx={{ ml: 0, mt: 0, mb: 2, p: 3 }}>
          <Grid className="item-info__container" item xs={6}>
            {ticket?.attributes.map((item) => {
              return (
                <Grid container key={item.id}>
                  <Grid item xs={6}>
                    <span className="item-info__title">
                      {item.name[locale as keyof typeof item.name]}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <AttributeField record={item} />
                  </Grid>
                </Grid>
              );
            })}

            {ticket?.user && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.helpdesk.created_by")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  {`${ticket.user.firstName} ${ticket.user.lastName[0]}.`}
                </Grid>
              </Grid>
            )}

            {ticket?.assignee && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.helpdesk.responsible_user")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  {`${ticket.assignee.firstName} ${ticket.assignee.lastName[0]}.`}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ px: 3, pt: 2 }}>
        {attachmentsList.map((item) => {
          return (
            <div>
              <CustomAttachmentField
                attachmentId={item.attachmentId}
                type={item.contentType}
              />
            </div>
          );
        })}
      </Box>

      <Box sx={{ px: 3, pt: 2 }}>
        {imagesList && imagesList.length > 0 && (
          <TicketImagesGallery imagesList={imagesList} />
        )}
      </Box>

      <TicketDetailSection>
        <Box>
          <b>{translate("app.helpdesk.comments_list")}</b>
        </Box>
        <CommentList ticketId={ticket?.id} />
      </TicketDetailSection>

      <TicketDetailSection>
        <Box>
          <b>{translate("app.helpdesk.ticket_history")}</b>
        </Box>
        <TicketHistory ticketId={ticket?.id} />
      </TicketDetailSection>
    </>
  );
};

export default TicketsShow;
