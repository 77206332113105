import React from "react";
import { DialogTitle } from "@mui/material";
import { useTranslate } from "react-admin";
import Dialog from "@mui/material/Dialog";
import { IRefundRequest } from "../../../../types/interfaces/admin-parking/refund-request.interface";
import { ITransaction } from "../../../../types/interfaces/admin-parking/transaction.interface";
import CreateRefundForm from "./CreateRefundForm";

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: (data: Partial<IRefundRequest>) => void;
  transaction: ITransaction | undefined;
};

const RefundDialog = (props: Props) => {
  const { isOpen, handleClose, handleSubmit, transaction, isLoading } = props;

  const translate = useTranslate();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{translate("app.parking.refund_creating")}</DialogTitle>
      <CreateRefundForm
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        amount={transaction?.amount}
        refundAmount={transaction?.refundAmount}
      />
    </Dialog>
  );
};

export default RefundDialog;
