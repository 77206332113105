import React, { useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { MobileButton, MobileLightButton } from "../../prepaid/styles";
import { PaymentProcessStep } from "../../../../types/enums/payment-process-step.enum";
import { useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";
import { ParkingContext } from "../../../../services/parking/parkingContext";
import { PaymentContext } from "../../../../services/payment/PaymentContext";

const Container = styled(Box)(() => ({
  width: "100%",
  marginBottom: "40px",
  textAlign: "center",
}));

const Przelewy24PaymentComponent = () => {
  const translate = useTranslate();
  const navigate = useNavigate();

  const { saveCurrentStep } = useContext(ParkingContext);
  const { redirectUrl } = useContext(PaymentContext);

  const stepBack = () => {
    saveCurrentStep(PaymentProcessStep.STEP_FORM);
    navigate(-1);
    return;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (redirectUrl) {
        window.location.assign(redirectUrl);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, [redirectUrl]);

  const handleRedirect = () => {
    if (redirectUrl) {
      window.location.assign(redirectUrl);
    }
  };

  return (
    <Container>
      <Box style={{ padding: "20px 0" }}>
        {translate("app.parking.prepaid.payment_redirect_message")}
      </Box>
      <Box>
        <MobileButton onClick={() => handleRedirect()}>
          {translate("app.parking.prepaid.go_to_payment")}
        </MobileButton>

        <MobileLightButton
          style={{ width: "100%", marginTop: "15px" }}
          onClick={() => stepBack()}
        >
          {translate("app.auth.back")}
        </MobileLightButton>
      </Box>
    </Container>
  );
};

export default Przelewy24PaymentComponent;
