import { resolveBrowserLocale } from "react-admin";
import i18nProvider from "../translations/i18n-provider";
const { translate } = i18nProvider;

type TranslatedFieldObjectType = {
  pl: string;
  en: string;
};

export const getTranslatedFieldValue = (
  translatedFieldObject: TranslatedFieldObjectType,
) => {
  const translation =
    translatedFieldObject[
      resolveBrowserLocale() as keyof TranslatedFieldObjectType
    ];

  if (translation) {
    return translation;
  }

  return translatedFieldObject[
    Object.keys(translatedFieldObject)[0] as keyof TranslatedFieldObjectType
  ];
};

export const getTranslatedFileInputPlaceholder = (size: number) => {
  return `${translate("app.helpdesk.tickets_attachment_drop_file")} ${size}MB`;
};
