import React, { useCallback, useState } from "react";
import { EditButton, TopToolbar, Button, useRecordContext } from "react-admin";
import { listTopFilter } from "../../../themes/styles";
import EmailIcon from "@mui/icons-material/Email";
import ResetPasswordDialog from "./ResetPasswordDialog";

const UsersShowActions = () => {
  const [resetPasswordDialog, setResetPasswordDialog] =
    useState<boolean>(false);
  const record = useRecordContext();

  const close = useCallback(() => setResetPasswordDialog(false), []);

  return (
    <TopToolbar sx={listTopFilter.topToolbar.rightFilters}>
      <Button
        color="primary"
        label="app.auth.password_reset"
        onClick={() => setResetPasswordDialog(true)}
      >
        <EmailIcon />
      </Button>

      <EditButton />

      <ResetPasswordDialog
        open={resetPasswordDialog}
        close={close}
        userId={record?.id}
      />
    </TopToolbar>
  );
};

export default UsersShowActions;
