import * as React from "react";

const ParkingIcon = ({ size = 18, color = "#606060", style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18.667 16"
      style={style}
    >
      <path
        id="icons8-garage"
        d="M10.323,2a.667.667,0,0,0-.24.049l-8.667,3.5A.667.667,0,0,0,1,6.167V18H2.333V6.617l8-3.232,8,3.232V18h1.333V6.167a.667.667,0,0,0-.417-.617l-8.667-3.5A.667.667,0,0,0,10.323,2ZM7.141,7.37a1.345,1.345,0,0,0-.893.357,1.915,1.915,0,0,0-.435.685L5.8,8.448l-.831,2.987a2.663,2.663,0,0,0-.44.185,2.159,2.159,0,0,0-.487.326,1.02,1.02,0,0,0-.372.771v4.617A.667.667,0,0,0,4.333,18H5V16.667H15.667V18h.667A.667.667,0,0,0,17,17.333V12.716a1.02,1.02,0,0,0-.372-.771,2.159,2.159,0,0,0-.487-.326,1.915,1.915,0,0,0-.273-.115l-1.086-3.1-.016-.034a2.207,2.207,0,0,0-.4-.612,1.33,1.33,0,0,0-.911-.393ZM7.161,8.7h6.25a1.421,1.421,0,0,1,.143.24l.839,2.391H6.378L7.055,8.9A1.128,1.128,0,0,1,7.161,8.7Zm-1.7,3.964H15.2a2.168,2.168,0,0,1,.323.135,1.026,1.026,0,0,1,.141.089v2.443H14.333V14H13v1.333H7.667V14H6.333v1.333H5V12.891a1.026,1.026,0,0,1,.141-.089A2.168,2.168,0,0,1,5.464,12.667Z"
        transform="translate(-1 -2)"
        fill={color}
      />
    </svg>
  );
};

export default ParkingIcon;
