import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ModulesList from "./ModulesList";
import ModulesEdit from "./ModulesEdit";
import ModulesCreate from "./ModulesCreate";

const modulesResource = {
  list: ModulesList,
  edit: ModulesEdit,
  create: ModulesCreate,
  icon: PhoneIphoneIcon,
};
export default modulesResource;
