import UsersList from "./UsersList";
import UsersShow from "./UsersShow";
import UsersCreate from "./UsersCreate";
import UsersEdit from "./UsersEdit";

const usersResource = {
  list: UsersList,
  show: UsersShow,
  create: UsersCreate,
  edit: UsersEdit,
};

export default usersResource;
