import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import React, { useCallback, useState } from "react";
import { useLogout, UserMenu, UserMenuProps } from "react-admin";
import ToolsMenu from "./ToolsMenu";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Theme, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { useStyles } from "../themes/styles";
import { standardAppBarStyle } from "../themes/styles";

const StandardUserMenu = (props: UserMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const logout = useLogout();

  const handleLogout = useCallback(() => {
    logout(null, "/login", true);
  }, [logout]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isSmall ? (
        <>
          <Button onClick={toggleDrawer}>
            <MoreIcon htmlColor={"#606060"} />
          </Button>
          <Drawer anchor={"right"} open={isOpen} onClose={toggleDrawer}>
            <ToolsMenu />
          </Drawer>
        </>
      ) : (
        <UserMenu {...props} label="userFullName" icon={""}>
          <div className={classes.menuUser}>
            <MenuItem className={classes.menuUserItem} onClick={handleLogout}>
              <ListItemText
                primary="Logout"
                sx={standardAppBarStyle.userMenuListItemText}
              />
            </MenuItem>
          </div>
        </UserMenu>
      )}
    </>
  );
};

export default StandardUserMenu;
