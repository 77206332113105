import React, { useContext } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ParkingContext } from "../../../../services/parking/parkingContext";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { Link, useParams } from "react-router-dom";

const ParkingNavbar = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "fixed",
  zIndex: 1,
  backgroundColor: "white",
  padding: "20px 100px",
  width: "100%",
  top: 0,

  [theme.breakpoints.down("lg")]: {
    padding: "10px 60px",
  },

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Navbar = () => {
  const { customerLogo } = useContext(ParkingContext);
  const { orgId } = useParams();

  return (
    <>
      <ParkingNavbar>
        {customerLogo && (
          <Link to={`/parking/prepaid/${orgId}/steps/1`}>
            <img src={customerLogo} alt="Logo" style={{ maxWidth: "120px" }} />
          </Link>
        )}
        <DesktopMenu />
      </ParkingNavbar>
      <MobileMenu />
    </>
  );
};

export default Navbar;
